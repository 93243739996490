import { Component,  TemplateRef } from '@angular/core';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { UserMasterResponse, User } from '../models/user';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/ngx-bootstrap-pagination';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { OrganisationResponse, Organisation } from '../models/organisation';
import { prize, prizeResponse } from '../models/prize';

@Component({
  selector: 'app-Prize',
  templateUrl: './Prize.component.html',
  styleUrls: ['./Prize.component.css']
})
export class PrizeComponent  {

  pagesUser:UserPage=new UserPage();
  organisationslist:prize[]=[];
  formval:prize=new prize();
  show:boolean=false;
  isOrganisationDisabled:boolean = false
  modalhead:string="Add Prize";
  dnm:boolean=false;
  showOrganisationList :any[] = []
  totaldata:number;
  isOrganisationEmpty: boolean = false;
  numberedit:string="";
  template:TemplateRef<PrizeComponent>;
  modalRef: BsModalRef;
  mobileReg:RegExp=/^[6-9]\d{9}$/;
  emailReg:RegExp=/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  isEdit:boolean=true;
  currentPage:number;
  buttonText:string="Save";
  submitForm: NgForm;
  prize: prize[];
  filtervalue: prize[];

  constructor(private toastr: ToastrService,private apiService:ApiService,private modalService: BsModalService) {
    this.pagesUser.Filter='FirstName';
  }

  ngOnInit() {
    this.getOrganisation(this.pagesUser);
    // this.formval.Active="true";
    // this.formval.OrganisationType=0
    if (this.submitForm.invalid )
    {
      this.toastr.warning('Please fill all valid details','Warning!',{
        timeOut:2000

       })
      return 
    }
  }

  onSubmit(f:NgForm)
  {debugger;
    if ( this.formval.TotalPoints == null || this.formval.TotalPoints == 0 ||
      // (this.formval.Points == null || this.formval.Points == 0 )||
      (this.formval.Option1 == null || this.formval.Option1 == "" )||
      (this.formval.Option2 == null || this.formval.Option2 == "" )
      // ||
      // (this.formval.Option3 == null || this.formval.Option3 == "" )||
      // (this.formval.Option4 == null || this.formval.Option4 == "" )
      )
    {
      this.toastr.warning('Please fill all required details','Warning!',{
        timeOut:2000

       });
      return
    }
    this.submitForm = f;
    this.show=true;
    if(this.buttonText=="Save")
    {
      debugger;
      this.saveUser(this.formval,"Organisation/SavePrize");
    }
    else
    {
      this.saveUser(this.formval,"Organisation/UpdatePrize")
      debugger;;
    }
  }

  saveUser(formval:prize,url:string)
  {
    
    this.apiService.PostApi(formval,url).subscribe((response: prizeResponse) => {
      if (response.ResponseCode == "200") {
        this.submitForm.resetForm();
        this.getOrganisation(this.pagesUser);
        this.resetmodal();
        this.show=false;

        this.toastr.success(response.ResponseMessage,'Success!',{
          timeOut:2000
         });
      }
      else
      {
        this.toastr.warning(response.ResponseMessage,'Warning!',{
          timeOut:2000

         });

    this.show=false;
      }
    }, err => {
      this.toastr.warning( err.error.ResponseMessage,'Warning!',{
        timeOut:2000

       });

    this.show=false;
    });


  }

  getOrganisation(user:UserPage): any {

    this.show=true;

    this.pagesUser.UserType=1;
    if(this.organisationslist!=null)
    {

      this.organisationslist=this.organisationslist.slice(0,0);
    }
    this.apiService.PostApi(this.pagesUser,"Organisation/SpGetPrizeList").subscribe((response: prizeResponse) => {
      debugger;
      if (response.ResponseCode == "200") {
        this.prize = response.ResponseData;
         this.totaldata=response.TotalCount;
         if(this.totaldata<parseInt(this.pagesUser.pageSize))
         {
           this.currentPage=1;
         }
      }
      else {
    this.organisationslist=null;
    this.show=false;
      }
    }, err => {

    this.organisationslist=null;

    this.show=false;
    });

    this.show=false;

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;




    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }





  alphaOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;




    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;

  }


  openModal(template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true,class: 'modal-lg' };
    this.isOrganisationDisabled = false;
    this.formval.IsActive = true;
    this.modalRef = this.modalService.show(template,config);

  }


  resetmodal()
  {
    debugger;
    this.modalRef.hide();
    this.formval.TotalPoints=0;
    this.formval.Points=0;
    this.formval.Option1='';
    this.formval.Option2 = '';
    this.formval.Option3='';
    this.buttonText="Save";
    this.formval.Option4='';
    this.formval.IsActive;
    this.modalhead="Add Prize";

    this.modalRef.hide();
  }
  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
      const startItem = (event.page - 1) * event.itemsPerPage;
      const endItem = event.page * event.itemsPerPage;
      this.pagesUser.page=event.page;
      this.getOrganisation(this.pagesUser);
     //  (this.users)
     this.currentPage=event.page+1;
    }

    onpagechange()
    {
      debugger;
      this.pagesUser.page=1;
      this.getOrganisation(this.pagesUser);
    }


    editorganisation(template: TemplateRef<any>,ID:prize)
    {
      debugger;

      this.filtervalue= this.prize.filter((x)=> x.ID ==ID)
      this.isEdit=false;
      //alert(this.user.firstName);
      this.modalhead="Edit Prize"

      this.numberedit="readonly";

      // this.formval={...user};
      this.formval.Option1=this.filtervalue[0].Option1
      this.formval.Option2=this.filtervalue[0].Option2
      this.formval.Option3=this.filtervalue[0].Option3
      this.formval.Option4=this.filtervalue[0].Option4
      this.formval.TotalPoints=this.filtervalue[0].TotalPoints
      // this.formval.Points=this.filtervalue[0].Points
      this.formval.ID=this.filtervalue[0].ID
      this.formval.IsActive=this.filtervalue[0].IsActive


      this.buttonText="Update";

      let config = { backdrop: true, ignoreBackdropClick: true,class: 'modal-lg' };


      this.modalRef = this.modalService.show(template, config);
      this.isOrganisationDisabled = true
    }

}
