export class ScanReport
{
    UserType: number;
    fromdate:string;
    todate:string;
    ScanStatus:string;
    HologramNo:string
    FullName:string;
    Mobile:string;
    pageNumber :number;
    ScanLocation:string;
    CreatedDate:string;
    PageSize :number;
   
    FilterBy:string;
    SearchValue:string
    Page :number;
    OrganisationName: string;
    FailedStatus:string;
    ProductCode:string;
    HOUserId:number;
    AccountNo:string;
    DealerId:any;
    AccFrmDDL:any;
    SiteDDL:string;
    IsActive:string;
  Scannedby: any;
  DealerCd: any;
  DealerNo: any;
  Dealerbilltocode: any;
  DealerNm: any;
  Points: any;
  Feedback: any;
  OrganisationID: string;
  ScanDate: any;
  EmpId: any;
  Date: any;
}

export class sblReport
{
    Page:number;
    PageSize:number;
    FilterBY:string;
    SearchValue:string;
    IsActive:string;
    DepotId:string;
    HOUserId:any;
    SblType:string;
    FromDate:string;
    ToDate:string;

}
export class DUMPReport
{
    pageNumber :number;
    pageSize:string;
    FilterBY:string;
    SearchValue:string;
    IsActive:string;
    DepotId:string;
    FromDate:string;
    ToDate:string;

}
export class ScanReportResponse
{

    TotalCount:number;
    ResponseCode:string;
    ResponseMessage:string;
    ResponseData:ScanReport[];

}

export class PassbookReport
{
    PageSize :number;
    Page :number;
    SearchValue:string;
    fromdate:string;
    todate:string;
    FullName:string;
    UsrLoc:string;
    Mobile:string;
    ScanDt:string;
    HologramNo:string;
    Points:string;
    HOUserId:number;
    IsActive:string;
    FilterBY:string;
    AccFrmDDL:string;
    AccountNo:string;
    SiteDDL:string;
    DealerId:number;
    UsrId:number;
}


export class  PassbookExcelData
{
    HoName:string;
    Mobile:string;
    Location:string;
    Date:string;
    Points:string;
    Scan_Number:string;
}

export class ScanLogExcelData
{
    HoName:string;
    Mobile:string;
    Status:string;
    Location:string;
    Date:string;
    ScanNumber:string;
}

export class TransferReportExcelData
{
    TransactionDate:string;
    FromOrganisation:string;
    ToOrganisation:string;
    Denomination:string
    FromNumber:string;
    ToNumber:string;
    Quantity:string;
}

export class TransferReport
{
    fromdate:string;
    todate:string;
    pageSize :number;
    pageNumber :number;
    searchValues:string;
    TransactionDate:string;
    FromOrganisation:string;
    ToOrganisation:string;
    Denomination:string
    FromNumber:string;
    ToNumber:string;
    Quantity:string;
    TransactionType:any;
}

export class TransferReportResponse
{

    TotalCount:number;
    ResponseCode:string;
    ResponseMessage:string;
    ResponseData:TransferReport[];
}


export class ReceiveReportExcelData
{
    TransactionDate:string;
    FromOrganisation:string;
    ToOrganisation:string;
    Denomination:string
    FromNumber:string;
    ToNumber:string;
    Quantity:string;
}

export class ReceiveReport
{
    fromdate:string;
    todate:string;
    pageSize :number;
    pageNumber :number;
    searchValues:string;
    TransactionDate:string;
    FromOrganisation:string;
    ToOrganisation:string;
    Denomination:string
    FromNumber:string;
    ToNumber:string;
    Quantity:string;
}

export class ReceiveReportResponse
{

    TotalCount:number;
    ResponseCode:string;
    ResponseMessage:string;
    ResponseData:TransferReport[];
}



export class PassbookReportResponse
{

    TotalCount:number;
    ResponseCode:string;
    ResponseMessage:string;
    ResponseData:PassbookReport[];

}

export class sblReportResponse
{

    HOUserId:number;
    // DealerId:string;
    DealerNm:string;
    FirmNm:string
    AccountNo:string;
    SiteDesc:any;
    FirstName:string;
    Mobile:string;
    EmpId:string;
    RowNum:number;
    ScanDt:any;
    Points:any;
    ProdDesc:any;
    OrganisationName:any;
    BatchNo:any;
    SblType:any;
    TotalCount:number;
    ScannedBy:any;
    HologramNo:any;
}
export class SblExcelData
{
    Mobile:string;
    DealerName:string;
    DealerNo:string;
    AssociatedProduct:any;
    BatchNumber:any;
    SblType:any;
    Dealer_Bill_To_Code:any;
    TokenValue:any;
    ProducedFrom:any;
    // DealerId:string;
    EmployeeCode:string;
    ScanDate:any;
    ScannedBy:any;
    TokenNo:any;
}
