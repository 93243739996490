export class Organisation
{
  OrganisationName:string;
    Address:string;
    State:string;
    City:string;
    ID:string;
    Country:string;
    Pincode:string;
    Active:string;
    InActiveReason:string;
    LastUpdatedBy:string;
    UpdatedTimeStamp:string;
    OrganisationType: number
}

export class OrganisationResponse
{

  ResponseCode: string;
  ResponseMessage: string;
  ResponseData: Organisation[];
  TotalCount:number;
}
