import { parse } from "cfb/types";

export class User
{

    ID:string='0';
    FirstName:string;
    DealerCode:string
    LastName: string;
    Email: string;
    Mobile: string;
    Address: string;
    cpassword:string;
    District: string;
    Pin: string;
    FactoryName:string;
    Organisation:string;
    OrganisationName:string;
    State: number;
    City: number;
    Password:string;
    Message:string;
    ShopName: string;
    AccountNo:string;
    DepotCode:number;
    HQCode: string;
    HQName: string;
    UserIdentityType: string;
    IdentityUrl:string;
    IsApproved: string;
    ApprovedBy: string;
    IsActive: boolean;
    Reason: string;
    CreatedBy: number;
    CreatedDate: string;
    UserType: number;
    UserTypeName: string;
    StateName:string;
    CityName: string;
    TotalEarn: number;
  UserType1: string;
  //IsActive: boolean;
  IsActive1: boolean;
  IsActive2: boolean;
}

export class DepotList
{
    BranchCode:string;
    BrandOffice:string;
}
export class DepotListReponse
{
  ResponseCode: string;
    ResponseMessage: string;
    ResponseData: DepotList[];

}
export class UserMasterResponse
{

    ResponseCode: string;
    ResponseMessage: string;
    ResponseData: UserData;
    TotalCount:number;

}


export class UserData
{
    UserData:User[];
    TotalCount:number;
    SuccessCount:string;
    SuccessList:User[];
    ErrorList:User[];
    ErrorCount:string;
}

export class MyExcelData
{
    ProductCode:string;
    Category:string;
    SubCategory:string;
    Description:string;
}

  let dd = new Date();
export class hoUser {
  ID: string;
  FirstName: string;
  LastName: string;
  UsrGroup: string;
  UsrDeptmnt: string;
  UsrDesigForRpt: string;
  DealerCode:string;
  Email: string;
  Mobile: string;
  fromdate:Date;
  cpassword: string;
  Password: string;
  EmpId:string;
  UsrDesig: string;
  Sales:any[];
  JoinDt: string = dd.toString();
  BusinessLine: string[];
  DepotsList: DepotsMst[];
  TerritoryList: TerritoryMst[];
  SalesList: SalesMst[];
  DealerList: DealercodeMst[];
  ContratorList : ContractorMst[];
  selectedSalesPerson: SalesMst[];
  IsActive: boolean;
  IsActive1: boolean;
  IsActive2: boolean;
  Reason: string;
  CreatedBy: number;
  CreatedDate: string;
  DeptId: any;
  DeptNm: any;
  selectedContractors: ContractorMst[];
  AdhaarCardPath: any;
  PANCardPath:any;
  AdhaarNo: any;
  PANCardNo: any;
  adhaarno: any;
  pancardno: any;
  DealerName: any;
  selectedDealercode: string;
  // Account: string;

  //Address: string;
  //District: string;
  //Pin: string;
  //Organisation: string;
  //OrganisationName: string;
  //State: number;
  //City: number;
  //Message: string;
  //ShopName: string;
  //AccountNo: string;
  //DepotCode: number;
  //HQCode: string;
  //HQName: string;
  //UserIdentityType: string;
  //IdentityUrl: string;
  //IsApproved: string;
  //ApprovedBy: string;
  //UserType: number;
  //StateName: string;
  //CityName: string;
  //TotalEarn: number;
}
//DepotId, DepotCd, DepotNm
//TrtryId, DepotId, TrtryCd, trtryNm

export class DepotsMst {
  DepotId: number;
  DepotCd: string;
  DepotNm: string;
  selected: boolean = false;
}
export class TerritoryMst {
  TrtryId: number;
  DepotId: number;
  TrtryCd: string;
  trtryNm: string;
  selected: boolean = false;
}

export class DepotsResp {

  ResponseCode: string;
  ResponseMessage: string;
  ResponseData: DepotsMst[];
  TotalCount: number;
}
export class DealerContractorList{
  ContractorId: any;
  SalesId: any;
  selected: boolean = false;
  DealerCode: any;
}
export class ContractorMst {
  //GroupId, GroupCd, GroupNm
  ContractorId: any;
  ContractorName: string;
  SalesId: any;
  SalesPersonName: string;
  selected: boolean = false;
}

export class SalesMst {
  //GroupId, GroupCd, GroupNm
  UsrId: number;
  ContractorId:number;
  SalesId:number;
  FirstName: string;
  selected: boolean = false;
}
export class DealercodeMst {
  //GroupId, GroupCd, GroupNm
  ID:number;
  DealerCode: string;
  DealerName: string;
  selected: boolean = false;
}

export class GroupMst {
  //GroupId, GroupCd, GroupNm
  GroupId: number;
  GroupCd: string;
  GroupNm: string;
}
export class DeptmntMst {
  //DeptId, DeptCd, DeptNm
  DeptId: number;
  DeptCd: string;
  DeptNm: string;
}
export class DealerCodeMst {
  //DeptId, DeptCd, DeptNm
  // DeptId: number;
  DealerCode: any;
  DealerName: string;
  selected: boolean;
}
export class DesigMst {
  //DesigId, DesigCd, DesigNm
  DesigId: number;
  DesigCd: string;
  DesigNm: string;
}

export class UserExcelData
{
  FirstName:string;
  LastName: string;
  Email: string;
  Mobile: string;
  UserTypeName: string;
  FactoryName:string;
  OrganisationName:string;
  OrganisationTypeName: string
  Password:string;
  IsActive: boolean;
}
export class HoExcelData
{
   FirstName:string
   LastName: string
   Email: string
   Password: string
   Mobile: string
   BuisnessName: string
   DepotName: string
   TeritoryName:string
}