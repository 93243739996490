import { Component,  TemplateRef } from '@angular/core';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { UserMasterResponse, User } from '../models/user';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/ngx-bootstrap-pagination';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { OrganisationResponse, Organisation } from '../models/organisation';
import { Product, ProductResponse } from '../models/product';
import { DenominationSetting, DenominationSettingResponse } from '../models/setting';
import { Denomination, DenominationResponse } from '../models/denomination';
import { PackSizeResponse, PackSize } from '../models/packsize';
import { d } from '@angular/core/src/render3';
@Component({
  selector: 'app-denominationsetting',
  templateUrl: './denominationsetting.component.html',
  styleUrls: ['./denominationsetting.component.css']
})
export class DenominationsettingComponent  {
  pagesUser:UserPage=new UserPage();
  settingslist:DenominationSetting[]=[];
  formval:DenominationSetting=new DenominationSetting();
  show:boolean=false;
  modalhead:string="Add Denomination Setting";
  dnm:boolean=false;
  totaldata:number;
  pagesUserProduct:UserPage=new UserPage();
  pagesUserStock:UserPage=new UserPage();
  numberedit:string="";
  template:TemplateRef<DenominationsettingComponent>;
  modalRef: BsModalRef;
  modalRef3: BsModalRef;
  modalRef2: BsModalRef;
  mobileReg:RegExp=/^[6-9]\d{9}$/;
  emailReg:RegExp=/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  isEdit:boolean=true;
  currentPage:number;
  buttonText:string="Save";
  submitForm: NgForm;
  packsizelist:PackSize[]=[];
  products:Product[]=[];
  denominationlist:Denomination[]=[];
  DenominationName:string;
  productlist:Product[]=[];
  producttotaldata:number;
  EditDenomination:boolean=false;
  constructor(private toastr: ToastrService,private apiService:ApiService,private modalService: BsModalService) {
    this.pagesUser.Filter='FirstName';
  }
  ngOnInit() {
    this.formval.Organisation=localStorage.getItem("organisation");
    this.pagesUser.Organisation=parseInt(localStorage.getItem("organisation"));
    this.getOrganisation(this.pagesUser);
    this.getDenominationList();
    this.getPackSizeList();
    this.formval.Denomination=0;
    this.formval.IsActive="true";
  }
  getPackSizeList()
  {
    this.apiService.PostApi(this.pagesUserStock,"Other/GetPackSize").subscribe((response: PackSizeResponse) => {
      if(response.ResponseCode=="200")
      {
        this.packsizelist=response.ResponseData;
      }
    })
  }
  getDenominationList()
  {
    
    this.apiService.GetApi(this.pagesUserStock,"Other/GetDenomiations").subscribe((response: any) => {
      debugger;
      if(response.ResponseCode=="200")
      {
        this.denominationlist=response.ResponseData;
      }
    })
  }
  ReceiveStock()
  {
    let temp;
    this.formval.check='NO';
    this.onSubmit(this.formval,temp);
  }
  onSubmit(f,temp)
  {debugger;
    if (
      // (this.formval.DenoRem == null || this.formval.DenoRem == '' || this.formval.DenoRem == undefined)||
      (this.formval.Denomination == null || this.formval.Denomination == undefined)||
        (this.formval.ProductCode == null || this.formval.ProductCode == "" || this.formval.ProductCode == undefined)||
      (this.formval.PackSize == null || this.formval.PackSize == 0 || this.formval.PackSize==undefined)||
      (this.formval.Remark == null || this.formval.Remark == "" || this.formval.Remark == undefined)
      )
    
    //   // (this.formval.Option4 == null || this.formval.Option4 == "" ))
    {
      this.toastr.warning('Please fill all required details','Warning!',{
        timeOut:2000

       });
    }
    else{

    this.submitForm = f;
    this.show=true;
    this.formval.CreatedBy=localStorage.getItem("userID");
    if(this.buttonText=="Update")
    {
      this.saveUser(this.formval,"Setting/EditProductDenominationSetting",temp);
    }
    else
    {
      this.saveUser(this.formval,"Setting/SaveProductDenominationSetting",temp);
    }
  }
}
  ontypechangeProduct()
  {
    this.pagesUserProduct.page=1;
    this.getProductslist();
  }
  pageChanged3(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.pagesUserProduct.page=event.page;
    this.getProductslist();
  }
  openModal3(template: TemplateRef<any>) {
    this.formval.check='YES';
    let config = { backdrop: true, ignoreBackdropClick: true,class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
      this.modalRef3 = this.modalService.show(template, config);
     this.getProductslist();
  }
  getProductslist()
  {
    debugger;
    this.pagesUserProduct.IsActive="true";
    this.apiService.PostApi(this.pagesUserProduct,"Setting/GetAllProductforsetting").subscribe((response: ProductResponse) => {
      this.productlist=response.ResponseData.ProductData;
      this.producttotaldata=response.ResponseData.TotalCount;
    })
  }
  closemodal3()
  {
    this.modalRef3.hide();
  }
  selectproduct(formval:Product)
  {
    this.modalRef3.hide();
    this.formval.ProductCode=formval.ProductCode;
    this.formval.ProductID=formval.ID;
  }
  saveUser(formval:DenominationSetting,url:string,temp)
  {
    this.apiService.PostApi(formval,url).subscribe((response: any) => {
      if (response.ResponseCode == "200") {
        if(response.RespCd==2)
        {
          this.saveDenomination(temp);
          // this.toastr.warning(response.ResponseMessage,'Warning!',{
          //   timeOut:3000
          //  });
           this.show=false;
           return
        }
        if(this.formval.check=='NO')
        {
          this.modalRef2.hide();
        }
        this.getOrganisation(this.pagesUser);
        this.resetmodal();
        this.show=false;
        this.toastr.success(response.ResponseMessage,'Success!',{
          timeOut:3000
         });
      }
      else
      {
        this.toastr.warning(response.ResponseMessage,'Warning!',{
          timeOut:3000
         });
    this.show=false;
      }
    }, err => {
      this.toastr.warning( err.error.ResponseMessage,'Warning!',{
        timeOut:3000
       });
    this.show=false;
    });
  }
  saveDenomination(template:TemplateRef<any>)
  {
      let config = { backdrop: true, ignoreBackdropClick: true };
      this.modalRef2 = this.modalService.show(template, config);
  }
  getOrganisation(user:UserPage): any {
    this.show=true;
    this.pagesUser.UserType=1;
    if(this.settingslist!=null)
    {
      this.settingslist=this.settingslist.slice(0,0);
    }
    this.apiService.PostApi(this.pagesUser,"Setting/GetAllSettings").subscribe((response:any) => {
      debugger;
      if (response.ResponseCode == "200") {
       //  (response)
        this.settingslist = response.ResponseData;
         this.totaldata=response.TotalCount;
         if(this.totaldata<parseInt(this.pagesUser.pageSize))
         {
           this.currentPage=1;
         }
      }
      else {
    this.settingslist=null;
    this.show=false;
      }
    }, err => {
    this.settingslist=null;
    this.show=false;
    });
    this.show=false;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  alphaOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }
  openModal(template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true,class: 'modal-lg' };
    this.modalRef = this.modalService.show(template,config);
  }
  resetmodal()
  {
    this.EditDenomination=false;
    this.modalRef.hide();
    this.formval.Denomination=0;
    this.formval.PackSize=0;
    this.formval.ProductID=0;
    this.formval.ProductCode='';
    this.formval.Remark='';
    this.formval.CreatedBy='';
    this.formval.check='YES';
    this.formval.IsActive='true';
    this.buttonText="Save";
   this.modalhead="Add Denomination Setting";
    this.modalRef.hide();
  }
  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
      const startItem = (event.page - 1) * event.itemsPerPage;
      const endItem = event.page * event.itemsPerPage;
      this.pagesUser.page=event.page;
      this.getOrganisation(this.pagesUser);
     //  (this.users)
     this.currentPage=event.page+1;
    }
    onpagechange()
    {
      this.pagesUser.page=1;
      this.getOrganisation(this.pagesUser);
    }
    editsetting(template: TemplateRef<any>,user:DenominationSetting)
    {
      debugger;
      this.isEdit=false;
      this.EditDenomination=true;
      //alert(this.user.firstName);
      this.modalhead="Edit Denomination Setting"
      this.formval={...user};
      this.formval.check='YES';
       (this.formval)
      this.formval.HologramID=this.formval.Denomination;
      this.buttonText="Update";
      let config = { backdrop: true, ignoreBackdropClick: true,class: 'modal-lg' };
      this.modalRef = this.modalService.show(template, config);
    }
  }
