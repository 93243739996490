import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../Services/api-service';
import { StockResponse, Stock } from '../models/stock';
import { UserPage } from '../models/login';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
@Component({
  selector: 'app-stockreceive',
  templateUrl: './stockreceive.component.html',
  styleUrls: ['./stockreceive.component.css']
})
export class StockreceiveComponent implements OnInit {
  pagesUser:UserPage=new UserPage();
  stocklist:Stock[]=[];
  totaldata:number;
  modalRef2: BsModalRef;
  deleteId:string;
  show:boolean=false;
  constructor(private toastr: ToastrService,private modalService: BsModalService,private apiService:ApiService) { }
  ngOnInit() {
    this.GetReceivedStock();
  }
  receivestock(template: TemplateRef<any>,summary:Stock)
  {
    this.deleteId={...summary}.ID;
    let config = { backdrop: true, ignoreBackdropClick: true };
      this.modalRef2 = this.modalService.show(template, config);
  }
  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.pagesUser.page=event.page;
    this.GetReceivedStock();
  }
  ontypechange()
  {
    this.pagesUser.page=1
    this.GetReceivedStock();
  }
  ReceiveStock()
  {
    this.show=true;
    this.apiService.PostApi(null,"Stock/ReceiveStock?Id="+this.deleteId).subscribe((response: StockResponse) => {
      if(response.ResponseCode=="200")
      {
        this.toastr.success(response.ResponseMessage,"Success!");
        this.GetReceivedStock();
        this.show=false;
      }
      else
      {
        this.show=false;
        this.toastr.warning(response.ResponseMessage,"Warning!")
      }
    },err=>
    {
      this.show=false;
      this.toastr.warning(err.error.ResponseMessage,"Warning!")
    })
    this.modalRef2.hide();
    this.GetReceivedStock();
  }
  mypadding(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }
  GetReceivedStock()
  {
    this.show=true;
    this.pagesUser.HologramID=0;
    this.pagesUser.IsReceived="0";
    this.pagesUser.IsTransferred="1";
    this.pagesUser.Organisation=parseInt(localStorage.getItem("organisation"));
    this.stocklist=this.stocklist.slice(0,0);
    this.apiService.PostApi(this.pagesUser,"Stock/GetStockReceive").subscribe((response: StockResponse) => {
      this.stocklist=response.ResponseData;
      if(this.stocklist.length>0)
      {
        for(var i=0;i<this.stocklist.length;i++)
        {
          this.stocklist[i].FromNumber=this.stocklist[i].Prefix+this.mypadding(parseInt(this.stocklist[i].FromNumber),9);
          this.stocklist[i].ToNumber=this.stocklist[i].Prefix+this.mypadding(parseInt(this.stocklist[i].ToNumber),9);
        }
      }
      this.totaldata=response.TotalCount;
      this.show=false;
    },err=>
    {
      this.show=false;
    })
  }
}
