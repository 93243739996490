  import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
  import { Mapping, MappingResponse, AvailResponse } from '../models/mapping';
  import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
  import { UserPage } from '../models/login';
  import { ApiService } from '../Services/api-service';
  import { ToastrService } from 'ngx-toastr';
  import { Product, ProductResponse } from '../models/product';
  import { PackSize, PackSizeResponse } from '../models/packsize';
  import { Denomination, DenominationResponse } from '../models/denomination';
  import { StockResponse, Stock, StockFromNumberResponse } from '../models/stock';
  import { NgForm } from '@angular/forms';
  import * as moment from 'moment';
  import { ScanReport, ScanReportResponse, PassbookReport, PassbookReportResponse, PassbookExcelData, TransferReportExcelData, TransferReport, TransferReportResponse } from '../models/report';
  import { ExcelService } from '../excel.service';
  import { MyExcelData } from '../models/user';
  @Component({
    selector: 'app-transferreport',
    templateUrl: './transferreport.component.html',
    styleUrls: ['./transferreport.component.css']
  })
  export class TransferreportComponent implements OnInit {
    show: boolean = false;
    show1:boolean=false;
    formval: TransferReport = new TransferReport();
    modalHead: string = "Add Stock Mapping";
    modalRef: BsModalRef;
    modalRef2: BsModalRef;
    modalRef3: BsModalRef;
    mappings: Mapping[] = [];
    avails: Stock[] = [];
    SearchPrefix:any;
    SearchHolo:any;
    isOrganisationDisabled:boolean = false
    products: Product[] = [];
    reportExcelPassbook: any[] = [];
    productlist: Product[] = [];
    availlist: Mapping[] = [];
    scanreportlist: any[] = [];
    SearchedList: any[] = [];
    TotalRecordsSearched:number;
    scanreportlistexcel: any[] = [];
    totaldata: number;
    availtotaldata: number;
    producttotaldata: number;
    onEdit: boolean = false;
    pagesUser: UserPage = new UserPage();
    pagesUserStock: UserPage = new UserPage();
    pagesUserProduct: UserPage = new UserPage();
    packsizelist: PackSize[] = [];
    denominationlist: Denomination[] = [];
    DenominationName: string;
    deleteId: string;
    submitForm: NgForm;
    constructor(private excelService: ExcelService, private toastr: ToastrService, private modalService: BsModalService, private apiService: ApiService) {
      this.formval.TransactionType=0;
    }
    ngOnInit() {
      this.setDates();
      this.formval.pageNumber = 1;
      this.formval.pageSize = 5;
      this.getscanreport();
    }
    onutypechange() {
      this.getscanreport();
    }
    setDates(): any {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
      var currentDay = date.getDate();
      this.formval.fromdate = moment(firstDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
      this.formval.todate = moment(currentDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    }
    exportAsXLSX(myreport: any): void {
      this.excelService.exportAsExcelFile(this.reportExcelPassbook, 'TransferReport_' + moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY") + '_to_' + moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY"));
      this.formval.pageSize = 5;
    }
    DownloadScanReport() {
      this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
      this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
      if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {
        this.toastr.warning( 'From Date should not be greater than To Date!','Warning!', {
          timeOut: 3000
        });
      }
      else {
        this.show = true;
        this.formval.pageSize = 100000;
        this.formval.pageNumber = 1;
        this.show = true;
        this.apiService.PostApi(this.formval, "Report/GetTransferReport").subscribe((response: TransferReportResponse) => {
          this.scanreportlistexcel = response.ResponseData;
          if(this.scanreportlist.length>0)
          {
            for(var i=0;i<this.scanreportlistexcel.length;i++)
            {
              this.scanreportlistexcel[i].FromNumber=this.scanreportlistexcel[i].Prefix + this.mypadding(parseInt(this.scanreportlistexcel[i].FromNumber), 9)
              this.scanreportlistexcel[i].ToNumber=this.scanreportlistexcel[i].Prefix + this.mypadding(parseInt(this.scanreportlistexcel[i].ToNumber), 9)
            }
          }
          this.reportExcelPassbook = this.reportExcelPassbook.slice(0, 0);
          this.scanreportlistexcel.forEach(o => {
            this.reportExcelPassbook.push(
              { TransactionDate: o.TransactionDate, FromOrganisation: o.FromOrganisation, ToOrganisation: o.ToOrganisation, Denomination: 'Rs.'+o.Denomination, FromNumber: o.FromNumber, ToNumber: o.ToNumber, Quantity:o.Quantity,Status:o.Status });
          });
          this.exportAsXLSX(this.reportExcelPassbook);
          this.show = false;
        },
          err => {
            this.show = false;
            this.totaldata = 0;
          })
        this.show = false;
      }
    }
    openModal(template: TemplateRef<any>){
      let config = { backdrop: true, ignoreBackdropClick: true,class: 'modal-lg' };
      this.isOrganisationDisabled = false
      this.modalRef = this.modalService.show(template,config)
      this.SearchedList=[];
      this.TotalRecordsSearched=0;
      this.SearchPrefix='';
      this.SearchHolo=null;
    }
    resetmodal()
    {
  
      this.modalRef.hide();
    }
    getTransactionType()
    {
      this.getscanreport();
    }
    getscanreport() {
        ;
      this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
      this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
      if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {
        this.toastr.warning( 'From Date should not be greater than To Date!','Warning!', {
          timeOut: 3000
        });
      }
      else {
        this.scanreportlist = this.scanreportlist.slice(0, 0);
        this.show = true;
        this.apiService.PostApi(this.formval, "Report/GetTransferReport").subscribe((response: any) => {
          this.scanreportlist = response.ResponseData;
          if(this.scanreportlist.length>0)
          {
            for(var i=0;i<this.scanreportlist.length;i++)
            {
              this.scanreportlist[i].FromNumber=this.scanreportlist[i].Prefix + this.mypadding(parseInt(this.scanreportlist[i].FromNumber), 9)
              this.scanreportlist[i].ToNumber=this.scanreportlist[i].Prefix + this.mypadding(parseInt(this.scanreportlist[i].ToNumber), 9)
            }
          }
          this.totaldata = response.TotalCount;
          this.show = false;
        },
          err => {
            this.show = false;
            this.totaldata = 0;
          })
        this.show = false;
      }
    }
    mypadding(num: number, size: number): string {
      let s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    }
    numberOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }
    pageChanged(event: PageChangedEvent): void {
      const startItem = (event.page - 1) * event.itemsPerPage;
      const endItem = event.page * event.itemsPerPage;
      this.formval.pageNumber = event.page;
      this.getscanreport();
    }
    ontypechange() {
      this.pagesUser.page = 1
      this.getscanreport();
    }
    GetSeachedData(){
      debugger;
      this.SearchedList= this.SearchedList.slice(0, 0);
      this.show1 = true;
      const obj = {
       Prefix:this.SearchPrefix,
       HologramNumber:this.SearchHolo
      };
      this.apiService.PostApi(obj, "Report/GetTransferReportHologramWise").subscribe((response: any) => {
        this.SearchedList = response.ResponseData;
        debugger;
        if(this.SearchedList.length>0)
        {
          for(var i=0;i<this.SearchedList.length;i++)
          {
            this.SearchedList[i].FromNumber=this.SearchedList[i].Prefix + this.mypadding(parseInt(this.SearchedList[i].FromNumber), 9)
            this.SearchedList[i].ToNumber=this.SearchedList[i].Prefix + this.mypadding(parseInt(this.SearchedList[i].ToNumber), 9)
          }
          this.TotalRecordsSearched = response.TotalCount;
          this.show1 = false;
        }
        else{
          this.SearchedList=[];
          this.TotalRecordsSearched =0;
          this.toastr.warning( 'Invalid Entry!','Warning!', {
            timeOut: 3000
          });
        }
        
      },
        err => {
          this.show1 = false;
          this.TotalRecordsSearched = 0;
          this.toastr.warning( 'Invalid Entry!','Warning!', {
            timeOut: 3000
          });
        })
      this.show1 = false;
    }
  }
