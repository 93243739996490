import { Component, OnInit, TemplateRef } from '@angular/core';
import { Mapping, MappingResponse, AvailResponse } from '../models/mapping';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DUMPReport } from '../models/report';
import { StockResponse, Stock, StockFromNumberResponse } from '../models/stock';
import { NgForm } from '@angular/forms';
import { ExcelService } from '../excel.service';
@Component({
  selector: 'app-dump-report',
  templateUrl: './dump-report.component.html',
  styleUrls: ['./dump-report.component.css']
})
export class DUMPReportComponent implements OnInit {
  pagesUser: UserPage = new UserPage();
  formval2: Stock = new Stock();
  formval:DUMPReport=new DUMPReport()
  cnList:any;
  cnExcelList:any[]=[];
  cnExcelListReport:any[]=[];
  Depotlist:any[]=[];
  show: boolean = false;
  url: string;
  availableStockExcel:any[]=[];
  availableStockExcelReport:any[]=[];
  totaldata: number;
  // formval: Stock = new Stock();
  submitForm: NgForm;
  checkFromNumber: string;
  checkToNumber: string;
  constructor(private excelService: ExcelService,private toastr: ToastrService, private modalService: BsModalService, private api: ApiService) {
    this.formval.pageNumber=1;
    this.formval.pageSize='5';
    this.pagesUser.Filter='';
    this.formval.DepotId="0";
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var currentDay = date.getDate();
    this.formval.FromDate = moment(firstDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.ToDate = moment(currentDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
  }

  ngOnInit() {
    this.getDepot();
    this.getDUMPreportData();
  }
  getDUMPreportData()
  {
    let obj ={
      pageNumber:this.formval.pageNumber,
      pageSize:this.formval.pageSize,
      FilterBy:'',
      searchValues:'',
      IsActive:'',
      FromDate:this.formval.FromDate,
      ToDate:this.formval.ToDate,
      DepotId:this.formval.DepotId,
      HoUserID:localStorage.getItem("userID")
    }
    this.formval.FromDate = moment(this.formval.FromDate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.ToDate = moment(this.formval.ToDate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    if (moment(this.formval.FromDate).isAfter(this.formval.ToDate)) {
      this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
        timeOut: 3000
      });
    }
    this.api.PostApi(obj,`Report/GetDUMPreport`).subscribe((resp:any)=>{
      if(resp.ResponseCode==200)
      {
        this.cnList=resp.ResponseData;
        this.totaldata=resp.ResponseData[0].TotalCount;
      }
      else{
        this.cnList=[];
        this.totaldata=0;
      }
    })
  }
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.formval.pageNumber = event.page;
    this.getDUMPreportData();
  }
  getDepot()
  {
    this.api.GetApi('0', `HOUser/GetAllDepot`).subscribe((resp: any) => {
      if (resp.ResponseCode == 200) {
        this.Depotlist = resp.ResponseData;
      }
    })
  }
  ontypechange() {
    this.pagesUser.page = 1
    this.pagesUser.pageSize=this.formval.pageSize;
    this.getDUMPreportData();
  }
  onDepotChange()
  {
    let obj = {
      DepotId:this.formval.DepotId
    }
    this.api.GetApi('0',`HOUser/HoUserByDepot?DepotId=${obj.DepotId}`).subscribe((resp:any)=>{
      if(resp.ResponseCode==200)
      {
        this.getDUMPreportData()
      }
    })
  }
  DownloadCNReport()
  {
    this.cnExcelListReport=[];
    this.cnExcelList=[];
    let obj={
      pageNumber:1,
      pageSize:this.totaldata,
      FilterBy:'',
      searchValues:'',
      IsActive:'',
      FromDate:this.formval.FromDate,
      ToDate:this.formval.ToDate,
      DepotId:this.formval.DepotId,
      HoUserID:localStorage.getItem("userID")
    }
    this.formval.FromDate = moment(this.formval.FromDate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.ToDate = moment(this.formval.ToDate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    if (moment(this.formval.FromDate).isAfter(this.formval.ToDate)) {
      this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
        timeOut: 3000
      });
    }
    this.api.PostApi(obj,`Report/GetDUMPreport`).subscribe((resp:any)=>{
      if(resp.ResponseCode==200)
      {
        this.cnExcelList=resp.ResponseData;
        this.cnExcelList.forEach(o=>{
          this.cnExcelListReport.push({
            ScannedDate:o.Date, SequentialNo:o.HologramNo, BatchNo:o.BatchNumber,
            ScanningOfficerCode:o.EmpId,ScanningOfficerName: o.FirstName,ScanningOfficerMobileNo:o.Mobile,
            SBL:o.SblType,DepotCode:o.DepotCd,DepotName:o.DepotNm,BILL_TO_CODE:o.BillToCode,BILL_TO:o.Bill_To,Customer_Code:o.AccountNo,Firm_Name:o.FirmNm,
            Contact_Person:o.DealerNm,SKU_Code:o.SKU_Code,SKU_Name:o.SKU_Name,PackSize:o.PackSize,Location:o.Location,Sum_of_Denomination_Amount:o.points,
            Service_Charge_10Percent:o.ServiceCharge10per,CN_Amount:o.CNAmount
          })
        })
        this.exportAsXLSX(this.cnExcelListReport);
        this.show = false;
      }
    })
  }
  exportAsXLSX(myreport: any): void {
    this.excelService.exportAsExcelFile(this.cnExcelListReport, 'DUMP Report');
  }

}
