import { Injectable } from '@angular/core';
import { CanActivate, Router, Routes, RoutesRecognized } from '@angular/router';
import {LoginComponent} from './login/login.component'
import { Route } from '@angular/compiler/src/core';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    login:LoginComponent;

     j:number=0;

    routes:Routes;
    pathname:string;
    
  constructor(private router: Router) { }
  canActivate() {

    if (sessionStorage.getItem("log")!=null) {
      return true;
    }
    
    sessionStorage.clear();
    this.router.navigateByUrl("/login");
    return false;
  }
}
