import { Component, OnInit, TemplateRef } from '@angular/core';
import { Mapping, MappingResponse, AvailResponse } from '../models/mapping';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { ToastrService } from 'ngx-toastr';
import { Product, ProductResponse } from '../models/product';
import { PackSize, PackSizeResponse } from '../models/packsize';
import { Denomination, DenominationResponse } from '../models/denomination';
import { StockResponse, Stock, StockFromNumberResponse } from '../models/stock';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { ScanReport, ScanReportResponse, PassbookExcelData, ScanLogExcelData } from '../models/report';
import { ExcelService } from '../excel.service';
@Component({
  selector: 'app-scanlogreport',
  templateUrl: './scanlogreport.component.html',
  styleUrls: ['./scanlogreport.component.css']
})
export class ScanlogreportComponent implements OnInit {


//   show: boolean = false;
//   formval: ScanReport = new ScanReport();
//   modalHead: string = "Add Stock Mapping";
//   modalRef: BsModalRef;
//   modalRef2: BsModalRef;
//   modalRef3: BsModalRef;
//   mappings: Mapping[] = [];
//   reportExcelPassbook: ScanLogExcelData[] = [];
//   avails: Stock[] = [];
//   products: Product[] = [];
//   productlist: Product[] = [];
//   availlist: Mapping[] = [];
//   scanreportlist: ScanReport[] = [];
//   scanreportlistexcel: ScanReport[] = [];
//   totaldata: number;
//   availtotaldata: number;
//   producttotaldata: number;
//   onEdit: boolean = false;
//   pagesUser: UserPage = new UserPage();
//   pagesUserStock: UserPage = new UserPage();
//   pagesUserProduct: UserPage = new UserPage();
//   packsizelist: PackSize[] = [];
//   denominationlist: Denomination[] = [];
//   DenominationName: string;
//   deleteId: string;
//   currentPage: number;
//   submitForm: NgForm;


  constructor(private excelService: ExcelService, private toastr: ToastrService, private modalService: BsModalService, private apiService: ApiService) {


  }

  ngOnInit() {
    // this.setDates();
    // //this.formval.failedstatustype = "ALL";
    // this.formval.UserType = 0;
    // //this.formval.pageNumber = 1;
    // //this.formval.pageSize = 5;
    // this.getscanreport();
  }
//   onutypechange() {

//     this.getscanreport();
//   }

//   setDates(): any {
//     var date = new Date();

//     var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
//     var currentDay = date.getDate();

//     this.formval.fromdate = moment(firstDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
//     this.formval.todate = moment(currentDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
//   }


//   exportAsXLSX(myreport: any): void {



//     this.excelService.exportAsExcelFile(this.reportExcelPassbook, 'ScanLogReport_' + this.formval.ScanStatus + '_' + moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY") + '_to_' + moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY"));

//     //this.formval.pageSize = 5;
//   }

//   DownloadScanReport() {

//     this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
//     this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");

//     if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {


//       this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
//         timeOut: 3000
//       });


//     }
//     else {
//       this.show = true;

//       // this.formval.pageSize = 100000;
//       // this.formval.pageNumber = 1;
//       this.apiService.PostApi(this.formval, "Report/ScanLogReport").subscribe((response: ScanReportResponse) => {

//         this.scanreportlistexcel = response.ResponseData;


//         this.reportExcelPassbook = this.reportExcelPassbook.slice(0, 0);
//         this.scanreportlistexcel.forEach(o => {
//           this.reportExcelPassbook.push(
//             { Name: o.FullName, Mobile: o.Mobile, Location: o.ScanLocation, Date: o.CreatedDate, Status: o.FailedStatus, ScanNumber: o.HologramNo });
//         });

//         this.exportAsXLSX(this.reportExcelPassbook);

//       },
//         err => {

//           this.show = false;

//           this.totaldata = 0;
//         }

//       )

//       this.show = false;


//     }

//   }

//   onpagechange() {

//     // this.formval.pageNumber = 1;
//     this.getscanreport();
//   }

//   getscanreport() {
//     this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
//     this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");

//     if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {


//       this.toastr.warning('From Date Cannot be greater than To Date!', 'Warning!', {
//         timeOut: 3000
//       });


//     }
//     else {


//       this.scanreportlist = this.scanreportlist.slice(0, 0);
//       this.show = true;

//       this.apiService.PostApi(this.formval, "Report/ScanLogReport").subscribe((response: any) => {
//         if (response.ResponseData.length! - 0) {

//            (response)

//           this.scanreportlist = response.ResponseData;

//           this.totaldata = response.ResponseData[0].TotalCount;

//           this.show = false;
//         }
//         else {
//           this.scanreportlist = [];
//           this.totaldata = 0;
//         }
//       },
//         err => {
//           this.scanreportlist = []
//           this.totaldata = 0;

//           this.show = false;
//         })

//       this.show = false;

//     }
//   }



//   numberOnly(event): boolean {
//     const charCode = (event.which) ? event.which : event.keyCode;




//     if (charCode > 31 && (charCode < 48 || charCode > 57)) {
//       return false;
//     }
//     return true;

//   }
//   pageChanged(event: PageChangedEvent): void {
//     //  alert(event.page)
//     const startItem = (event.page - 1) * event.itemsPerPage;
//     const endItem = event.page * event.itemsPerPage;
//     this.pagesUser.page = event.page;
//     this.getscanreport();
//     //  (this.users)
//     this.currentPage = event.page + 1;
//   }



//   ontypechange() {
//     this.pagesUser.page = 1;
//     this.getscanreport();

//   }


}
