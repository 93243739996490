import { Component, OnInit } from '@angular/core';
import { ChangePassword } from '../models/changepassword';
import { NgForm } from '@angular/forms';
import { ApiService } from '../Services/api-service';
import { StockResponse } from '../models/stock';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})

export class ChangepasswordComponent implements OnInit {

formval:ChangePassword=new ChangePassword();
show:boolean=false;
  submitForm: NgForm;



  constructor(private router:Router,private toastr: ToastrService,private apiService:ApiService) { }

  ngOnInit() {
  }


  onSubmit(f:NgForm)
  {

    this.submitForm = f;
    this.show=true;

    this.formval.Mobile=localStorage.getItem("Mobile");
     (this.formval)
    if(this.formval.NewPassword!=this.formval.ConfirmPassword)
    {

      this.toastr.warning("Confirm Password does not match","Warning")

      this.show=false;
    }
    else if(this.formval.NewPassword==this.formval.OldPassword)
    {
      this.toastr.warning("New Password matches the Old password","Warning");
      this.show=false;
    }
    else
    {
      this.apiService.PostApi(this.formval,"User/ChangePassWord").subscribe((response: StockResponse) => {

        if(response.ResponseCode=="200")
        {

          sessionStorage.clear();
          localStorage.clear();
          this.router.navigateByUrl('/home');
          this.toastr.success("Password Changed Successfully","Success");


          this.show=false;
        }},err=>
        {
          this.toastr.warning(err.error.ResponseMessage,"Warning");

      this.show=false;
      })

    }


  }

}
