export class Mapping
{

    ID:number;
    ProductID:number;
    HologramID:number;
    Prefix:string;
    FromNumber:string;
    ToNumber:string;
    BatchNo:string;
    OrganisationID:string;
    Organisation:string;
    Quantity:string;
    ProductCode: string;
    CreatedBy:number;
    CreatedDate:string;
    PackSize:number;
    IsActive:boolean;
    Name:string;
    Remarks:any;
    DenoRem:any;
    abcd:any;
  UserType: number=0;
  Point: number;
  FromDate: any;
  ToDate: any;
}

export class MappingResponse
{

    ResponseCode: string;
    ResponseMessage: string;
    ResponseData:MappingData;
}

export class MappingData
{

    MappingData:Mapping[];
    TotalCount:number;


}

export class AvailResponse
{
    ResponseCode: string;
    ResponseMessage: string;
    ResponseData:AvailData;
}

export class AvailData
{
    AvailData:Mapping[];
    TotalCount:number;

}