import { Component, OnInit, TemplateRef } from '@angular/core';
import { Mapping, MappingResponse, AvailResponse } from '../models/mapping';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { ToastrService } from 'ngx-toastr';
import { Product, ProductResponse } from '../models/product';
import { PackSize, PackSizeResponse } from '../models/packsize';
import { Denomination, DenominationResponse } from '../models/denomination';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-demapping',
  templateUrl: './demapping.component.html',
  styleUrls: ['./demapping.component.css']
})
export class DemappingComponent implements OnInit {
  show:boolean=false;
  formval:Mapping=new Mapping();
  modalHead:string="Add Stock Mapping";
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  from:any;
  to:any;
  mappings:Mapping[]=[];
  avails:Mapping[]=[];
  products:Product[]=[];
  productlist:Product[]=[];
  availlist:Mapping[]=[];
  mappinglist:Mapping[]=[];
  totaldata:number;
  availtotaldata:number;
  producttotaldata:number;
  minFromNo:number;
  maxToNo:number;
  pagesUser:UserPage=new UserPage();
  pagesUserStock:UserPage=new UserPage();
  pagesUserProduct:UserPage=new UserPage();
  packsizelist:PackSize[]=[];
  denominationlist:any[]=[];
  DenominationName:string;
  submitForm: NgForm;
  constructor(private toastr: ToastrService,private modalService: BsModalService,private apiService:ApiService)
  {
    this.pagesUser.Filter="FromNumber";
   }
  ngOnInit() {
    this.formval.OrganisationID = localStorage.getItem("organisation");
    this.getmappinglistapi();
    this.getProductslist();
    this.getPackSizeList();
    this.getDenominationList();
    this.formval.HologramID=0;
    this.formval.PackSize=0;
    this.formval.abcd
  }
  calc2(val:number)
  {
    debugger;
    if(this.formval.Quantity==''||parseInt(this.formval.FromNumber)==0||this.formval.FromNumber==''
    ||this.formval.FromNumber==undefined||parseInt(this.formval.Quantity)==0||this.formval.Quantity==undefined)
    {
      this.formval.ToNumber='';
    }
    else{
    for(var i=0;i<this.denominationlist.length;i++)
    {
      if(this.formval.HologramID==this.denominationlist[i].ID)
      {
        this.formval.Prefix=this.denominationlist[i].Prefix;
      }
    }
    var len=this.formval.FromNumber.length;
    if(len==15){
    this.formval.FromNumber=this.formval.FromNumber.substring(6,len);
    }
    else if(len==16){
      this.formval.FromNumber=this.formval.FromNumber.substring(7,len);
      }
    if(parseInt(this.formval.FromNumber)==0|| this.formval.FromNumber==''|| this.formval.FromNumber=='NaN' || this.formval.FromNumber=='undefined')
    {
      this.formval.FromNumber='';
      this.formval.ToNumber='';
    }
    else if(parseInt(this.formval.Quantity)==0)
    {
      this.formval.ToNumber='';
      this.formval.Quantity='';
      this.formval.FromNumber=this.formval.Prefix+this.formval.FromNumber;
    }
    else
    {
      var txtfromNo = parseInt(this.formval.FromNumber);
      var txtqtyNo=parseInt(this.formval.Quantity);
      var txttoNo=txtfromNo+txtqtyNo-1;
      this.formval.ToNumber=txttoNo.toString();
      this.formval.FromNumber=this.formval.Prefix+this.mypadding(parseInt(this.formval.FromNumber),9);
      this.formval.ToNumber=this.formval.Prefix+this.mypadding(parseInt(this.formval.ToNumber),9);
    }
  }
  }
  demapping(template: TemplateRef<any>,summary:Mapping)
  {debugger
    this.formval={...summary};
    this.from=this.formval.FromNumber;
    this.to=this.formval.ToNumber;
    var flen = this.formval.FromNumber.length;
    var tlen = this.formval.ToNumber.length;
    if(flen==15){
    // this.formval.FromNumber = this.formval.FromNumber.substring(7, flen);
    // this.formval.ToNumber = this.formval.ToNumber.substring(7, tlen);
    this.maxToNo = parseInt(this.formval.ToNumber.substring(6, tlen));
    this.minFromNo = parseInt(this.formval.FromNumber.substring(6, flen));
    }
    else if(flen==16){
      // this.formval.FromNumber = this.formval.FromNumber.substring(8, flen);
      // this.formval.ToNumber = this.formval.ToNumber.substring(8, tlen);
      this.maxToNo = parseInt(this.formval.ToNumber.substring(7, tlen));
      this.minFromNo = parseInt(this.formval.FromNumber.substring(7, flen));
      }

    let config = { backdrop: true, ignoreBackdropClick: true,class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
      this.modalRef = this.modalService.show(template, config);
  }
  onChangeDenomination(value:any)
  {
    //alert(eventdenomname)
    var textvaluepair=value.split(",");
     this.DenominationName=textvaluepair[1];
    this.getAvailableStockApi(parseInt(textvaluepair[0]));
  }
  openModal(template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true,class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
      this.modalRef = this.modalService.show(template, config);
  }
  openModal2(template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true,class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
      this.modalRef2 = this.modalService.show(template, config);
  }
  onutypechange()
  {
    this.getmappinglistapi();
  }
  calc(val:number)
  {
    if(parseInt(this.formval.FromNumber)==0)
    {
      this.formval.FromNumber='';
      this.formval.ToNumber='';
    }
    else if(parseInt(this.formval.Quantity)==0)
    {
      this.formval.ToNumber='';
      this.formval.Quantity='';
    }
    else
    {
      var txtfromNo = parseInt(this.formval.FromNumber);
      var txtqtyNo=parseInt(this.formval.Quantity);
      var txttoNo=txtfromNo+txtqtyNo-1;
      this.formval.ToNumber=txttoNo.toString();
    }
  }
  removeQty()
  {
    this.formval.Quantity='';
    this.formval.ToNumber='';
  }
  openModal3(template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true,class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
      this.modalRef3 = this.modalService.show(template, config);
     this.getProductslist();
  }
  mypadding(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }
  getmappinglistapi()
  {
    this.pagesUser.Organisation=parseInt(localStorage.getItem("organisation"));
    this.apiService.PostApi(this.pagesUser,"Mapping/AllMapping").subscribe((response: MappingResponse) => {
       (response)
      this.mappinglist=response.ResponseData.MappingData;
      if(this.mappinglist.length>0)
      {
        for(var i=0;i<this.mappinglist.length;i++)
        {
          this.mappinglist[i].FromNumber=this.mappinglist[i].Prefix+this.mypadding(parseInt(this.mappinglist[i].FromNumber),9);
          this.mappinglist[i].ToNumber=this.mappinglist[i].Prefix+this.mypadding(parseInt(this.mappinglist[i].ToNumber),9);
        }
      }
      this.totaldata=response.ResponseData.TotalCount;
    })
  }
  onSubmit(f:NgForm)
  {
    debugger;
    this.submitForm = f;
    if ((this.formval.FromNumber == null || this.formval.FromNumber == undefined || this.formval.FromNumber == '')||
    (this.formval.ToNumber == null || this.formval.ToNumber == undefined || this.formval.ToNumber == '')||
    ( this.formval.Quantity == null || this.formval.Quantity == undefined || this.formval.Quantity == '')
    || 
    (this.formval.HologramID == null || this.formval.HologramID==0) ||
    (this.formval.BatchNo == null || this.formval.BatchNo=='')
  )
    {
      this.toastr.warning('Please fill all required details','Warning!',{
        timeOut:3000

       });
      return
    }
    else{
    this.savemapping();
  }}
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  removenumber()
  {
    if(this.formval.Quantity=='')
    {
      this.formval.ToNumber='';
    }
  }
  savemapping()
  {
    debugger;
    var len = this.formval.FromNumber.length;
    if(len==15){
     this.formval.FromNumber=this.formval.FromNumber.substring(6,len);
     this.formval.ToNumber=this.formval.ToNumber.substring(6,len);
    }
    else if(len==16){
      this.formval.FromNumber=this.formval.FromNumber.substring(7,len);
      this.formval.ToNumber=this.formval.ToNumber.substring(7,len);
     }
     this.show=true;
    if(parseInt(this.formval.FromNumber) < this.minFromNo || parseInt(this.formval.ToNumber) > this.maxToNo)
    {
      this.toastr.warning("Number out of range",'Warning!',{
        timeOut:3000
       });
       this.show=false;
    }
    else
    {
  ;
      this.formval.CreatedBy=parseInt(localStorage.getItem("userID"));
      this.formval.OrganisationID = localStorage.getItem("organisation");
      this.formval.FromNumber=parseInt(this.formval.FromNumber).toString();
      this.formval.ToNumber=parseInt(this.formval.ToNumber).toString();
      this.apiService.PostApi(this.formval,"Mapping/DeMapping").subscribe((response: MappingResponse) => {
        if (response.ResponseCode == "200") {
          this.submitForm.resetForm();
          this.show=false;
       this.toastr.success("Token De-allocated Successfully","Success!")
        this.getmappinglistapi();
        this.resetmodal();
        }
        else {
          this.toastr.warning(response.ResponseMessage,"Warning!");
          this.resetmodal();
          this.formval.FromNumber=this.formval.Prefix+this.formval.FromNumber;
          this.formval.ToNumber=this.formval.Prefix+this.formval.ToNumber;
          this.show=false;
        }}, err => {
          this.toastr.warning(err.error.ResponseMessage,"Warning!")
          this.formval.FromNumber=this.formval.Prefix+this.formval.FromNumber;
          this.formval.ToNumber=this.formval.Prefix+this.formval.ToNumber;
          this.resetmodal();
          this.show=false;
        })
        this.show=false;
    }
  }
  resetmodal()
  {
    this.modalRef.hide();
    this.formval.FromNumber='';
    this.formval.ToNumber='';
    this.formval.Quantity='';
    this.formval.ProductID=null;
    this.formval.ProductCode=null;
    this.maxToNo=0;
    this.minFromNo=0;
    this.formval.HologramID=0;
    this.formval.PackSize=0;
  }
  closemodal2()
  {
    this.modalRef2.hide();
  }
  closemodal3()
  {
    this.modalRef3.hide();
  }
  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.pagesUser.page=event.page;
    this.getmappinglistapi();
  }
  pageChanged2(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.pagesUserStock.page=event.page;
    this.getAvailableStockApi(this.formval.HologramID);
  }
  pageChanged3(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.pagesUserProduct.page=event.page;
    this.getProductslist();
  }
  getAvailableStockApi(value:number)
  {
    this.apiService.PostApi(this.pagesUserStock,"Mapping/AvailableHologram?pageSize="+this.pagesUserStock.pageSize+"&pageNumber="+this.pagesUserStock.page+"&HologramID="+value.toString()+"&quickSearch="+this.pagesUserStock.searchValue).subscribe((response: AvailResponse) => {
     //  (response.ResponseData);
      this.availlist=response.ResponseData.AvailData;
      this.availtotaldata=response.ResponseData.TotalCount;
    })
  }
  getDenominationList()
  {
    this.apiService.GetApi(this.pagesUserStock,"Other/GetDenomiations").subscribe((response: any) => {
      if(response.ResponseCode=="200")
      {
        this.denominationlist=response.ResponseData;
      }
    })
  }
  getPackSizeList()
  {
    this.apiService.PostApi(this.pagesUserStock,"Other/GetPackSize").subscribe((response: PackSizeResponse) => {
      if(response.ResponseCode=="200")
      {
        this.packsizelist=response.ResponseData;
      }
    })
  }
  ontypechange()
  {
    this.pagesUser.page=1
    this.getmappinglistapi();
  }
  ontypechangeProduct()
  {
    this.pagesUserProduct.page=1;
    this.getProductslist();
  }
  ontypechangeStock()
  {
    this.pagesUserStock.page=1;
    this.getAvailableStockApi(this.formval.HologramID);
  }
  selectstock(formval:Mapping)
  {
    this.modalRef2.hide();
    this.formval.FromNumber=formval.FromNumber;
    this.formval.ToNumber=formval.ToNumber;
    this.formval.Quantity=formval.Quantity;
    this.formval.Prefix=formval.Prefix;
  }
  selectproduct(formval:Product)
  {
    this.modalRef3.hide();
    this.formval.ProductCode=formval.ProductCode;
    this.formval.ProductID=formval.ID;
  }
  getProductslist()
  {
    this.pagesUserProduct.IsActive="true";
    this.apiService.PostApi(this.pagesUserProduct,"Product/GetAllProduct").subscribe((response: ProductResponse) => {
      this.productlist=response.ResponseData.ProductData;
      this.producttotaldata=response.ResponseData.TotalCount;
    })
  }
}
// selectstock(formval: Mapping) {
//   debugger;
//     ;
//   this.modalRef2.hide();
//   this.formval.FromNumber = formval.FromNumber;
//   this.formval.ToNumber = formval.ToNumber;
//   this.formval.Quantity = formval.Quantity;
//   this.formval.Prefix = formval.Prefix;
//   var flen = this.formval.FromNumber.length;
//   var tlen = this.formval.ToNumber.length;
//   if(flen==15){
//   // this.formval.FromNumber = this.formval.FromNumber.substring(7, flen);
//   // this.formval.ToNumber = this.formval.ToNumber.substring(7, tlen);
//   this.maxToNo = parseInt(this.formval.ToNumber.substring(7, tlen));
//   this.minFromNo = parseInt(this.formval.FromNumber.substring(7, flen));
//   }
//   else if(flen==16){
//     // this.formval.FromNumber = this.formval.FromNumber.substring(8, flen);
//     // this.formval.ToNumber = this.formval.ToNumber.substring(8, tlen);
//     this.maxToNo = parseInt(this.formval.ToNumber.substring(8, tlen));
//     this.minFromNo = parseInt(this.formval.FromNumber.substring(8, flen));
//     }
 
//   this.from = this.formval.FromNumber;
//   this.to = this.formval.ToNumber;
// }
