import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'British Paints';

constructor(public router:Router,location: PlatformLocation){
  location.onPopState(() => {
    sessionStorage.clear();
 }); 

}

}
