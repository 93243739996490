import { Component, TemplateRef, OnInit } from '@angular/core';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { UserMasterResponse, User, MyExcelData } from '../models/user';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/ngx-bootstrap-pagination';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from '../excel.service'
import { States, StateResponse } from '../models/State';
import { CityResponse, City } from '../models/city';
import * as XLSX from 'xlsx';
import { Dealer, DealerMasterResponse } from '../models/dealer';
import { NgForm } from '@angular/forms';
type AOA = any[][];
@Component({
  selector: 'app-dealemasternew',
  templateUrl: './dealemasternew.component.html',
  styleUrls: ['./dealemasternew.component.css']
})
export class DealemasternewComponent implements OnInit {
  pagesUser: UserPage = new UserPage();
  users: Dealer[] = [];
  formval: Dealer = new Dealer();
  show: boolean = false;
  modalhead: string = "Upload Dealer Details";
  dnm: boolean = false;
  result: string = "0"
  totaldata: number;
  activate: string = "done"
  numberedit: string = "";
  data1: any = [];
  data: AOA = [];
  templateDealer: MyExcelData[] = [];
  template: TemplateRef<DealemasternewComponent>;
  modalRef: BsModalRef;
  errorList: User[] = [];
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  modalRef4: BsModalRef;
  states: States[] = [];
  mobileReg: RegExp = /^[6-9]\d{9}$/;
  emailReg: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  isEdit: boolean = true;
  cities: City[] = [];
  SuccessCount: string = "0"
  ErrorCount: string = "0";
  currentPage: number;
  dealerlist: Dealer[] = [];
  buttonText: string = "Save";
  DeleteId: number;
  IsActive: boolean;
  DealerId: number;
  constructor(private excelService: ExcelService, private toastr: ToastrService, private apiService: ApiService, private modalService: BsModalService) {
    this.pagesUser.Filter = '';
  }
  ngOnInit() {
    //this.getUsers(this.pagesUser);
    this.getDealerList();
    this.EditDealer();
    this.formval.IsActive = true;
  }
  getDealerList() {
    debugger;
    this.dealerlist = null;
    this.apiService.PostApi(this.pagesUser, "Dealer/GetDealerList").subscribe((response: any) => {
      debugger;
      (response)
      if (response.ResponseCode == 200) {
        debugger
        this.dealerlist = response.ResponseData;
        console.log(this.dealerlist)
        this.totaldata = response.ResponseData[0].TotalCount;
        (this.dealerlist + 'DealerList');
      }
      else {
      }
    })
  }
  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.templateDealer, 'DealerTemplate');
  }
  onSubmit(f: NgForm) {
    debugger;
    if (this.formval == null || this.formval == undefined ||
      //(this.formval.ProductCategory == null || this.formval.ProductCategory == "")||
      (this.formval.DealerCode == null || this.formval.DealerCode == undefined || this.formval.DealerCode == '') ||
      (this.formval.DealerName == null || this.formval.DealerName == undefined || this.formval.DealerName == '') ||
      (this.formval.SalesmanName == null || this.formval.SalesmanName == undefined || this.formval.SalesmanName == "")
    ) {
      this.toastr.warning('Please fill all required details', 'Warning!', {
        timeOut: 2000

      });
      return false
    }
    else {
      this.saveUser(this.formval, "Dealer/EditDealer");
      debugger
    }
  }
  EditDealer() {
    this.apiService.PostApi(null, "Other/GetState").subscribe((response: StateResponse) => {
      this.states = response.ResponseData;
    })
  }
  onChange(newValue: number) {
    this.getCities(newValue);
  }
  getCities(state: number) {
    this.apiService.PostApi(null, "Other/GetCity?StateID=" + state).subscribe((response: CityResponse) => {
      this.cities = response.ResponseData;
    })
  }
  edituser(template: TemplateRef<any>, summary: Dealer) {
    debugger;
    this.isEdit = false;
    //alert(this.user.firstName);
    this.modalhead = "Edit Dealer"
    this.numberedit = "";
    this.IsActive = true;
    //this.EditDealer();
    this.formval = { ...summary };
    this.formval.DealerId = { ...summary }.DealerId;
    this.buttonText = "Update";
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    this.modalRef2 = this.modalService.show(template, config);
  }
  deleteUser(template: TemplateRef<any>, summary: Dealer) {
    debugger;
    let config = { backdrop: true, ignoreBackdropClick: true };
    this.modalRef4 = this.modalService.show(template, config);
    this.DeleteId = { ...summary }.DealerId
  }
  DeleteDealer() {
    this.show = true;
    this.apiService.GetApi(null, "Dealer/DeleteDealerNew?DealerId=" + this.DeleteId).subscribe((response: UserMasterResponse) => {
      debugger;
      if (response.ResponseCode == "200") {

        this.show = false;
        this.onpagechange();
        this.toastr.success("Dealer deleted successfully", 'Success!', {
          timeOut: 3000
        });
      } else {
        this.toastr.warning(response.ResponseMessage, 'Error!', {
          timeOut: 3000
        });
        this.show = false;
      }
    }, err => {
      this.toastr.warning(err.error.ResponseMessage, 'Error!', {
        timeOut: 3000
      });
      this.show = false;
    });
    this.modalRef4.hide();
  }
  UploadCSVToServer(template: TemplateRef<any>) {
    debugger;
    if (this.activate == "done") {
    }
    else {
      ;
      let config = { backdrop: true, ignoreBackdropClick: true };
      this.show = true;
      const postData = { data: this.data };
      (JSON.stringify(postData))
      this.apiService.PostApi(postData, "Dealer/UploadDealer").subscribe((response: any) => {
        (response.ResponseData)
        if (response.ResponseCode == "200") {
          this.result = "1";
          //this.getUsers(this.pagesUser);
          this.errorList = response.ResponseData.ErrorList;
          if (response.ResponseData.SuccessCount != "") {
            this.SuccessCount = response.ResponseData.SuccessCount;
          }
          if (response.ResponseData.ErrorCount != "") {
            this.ErrorCount = response.ResponseData.ErrorCount;
          }
          this.getDealerList();
          this.show = false;
        }
        else {
          this.toastr.warning(response.ResponseMessage, 'Error!', {
            timeOut: 3000
          });
          this.show = false;
        }
      }, err => {
        this.toastr.warning('Something Went Wrong!', 'Error!', {
          timeOut: 3000
        });
        this.show = false;
      });
    }
  }
  ShowErrorList(template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    this.modalRef3 = this.modalService.show(template, config);
  }
  myUploader(event) {
    ;
    this.activate = "done";
    if (event.files[0].name.includes('.csv')) {
      const target: DataTransfer = <DataTransfer>(event);
      //  (target);
      if (target.files.length !== 1) {
        this.toastr.warning("You can upload multiple files", "Warning!")
      }
      ;
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        /* save data */
        this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, defval: '' }));
        (this.data);
        this.toastr.success('Success', 'File uploaded successfully', {
          timeOut: 2000,
        });
        this.activate = "";
      };
      reader.readAsBinaryString(target.files[0]);
    } else {
      this.toastr.error("You can only upload CSV file", "Warning!", {
        timeOut: 2000,
      });
    }
    // event.files == files to upload
  }
  saveUser(formval: Dealer, url: string) {
    debugger;
    this.apiService.PostApi(formval, url).subscribe((response: UserMasterResponse) => {
      if (response.ResponseCode == "200") {
        this.getDealerList();
        // this.resetmodal();
        this.modalRef2.hide();
        this.show = false;
        this.toastr.success(response.ResponseMessage, 'Success!', {
          timeOut: 3000
        });
      }
      else {
        this.toastr.warning(response.ResponseMessage, 'Error!', {
          timeOut: 3000
        });
        this.show = false;
      }
    }, err => {
      this.toastr.warning(err.error.ResponseMessage, 'Error!', {
        timeOut: 3000
      });
      this.show = false;
    });
  }
  // getUsers(user: UserPage): any {
  //   this.show = true;
  //   this.pagesUser.UserType = 3;
  //   this.apiService.PostApi(this.pagesUser, "User/GetAllUser").subscribe((response: UserMasterResponse) => {
  //     if (response.ResponseCode == "200") {
  //       this.users = response.ResponseData.UserData;
  //        (response);
  //       this.totaldata = response.ResponseData.TotalCount;
  //       if (this.totaldata < parseInt(this.pagesUser.pageSize)) {
  //         this.currentPage = 1;
  //       }
  //     }
  //     else {
  //       this.users = null;
  //       this.show = false;
  //     }
  //   }, err => {
  //     this.users = null;
  //     this.show = false;
  //   });
  //   this.show = false;
  // }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  alphaOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }
  openModal(template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    (template);
    this.modalRef = this.modalService.show(template, config);
  }
  resetmodal() {
    debugger
    this.dnm = false;
    this.modalhead = "Add Dealer";
    this.isEdit = true;
    this.formval.DealerName = '';
    this.formval.DealerCode = '';
    this.formval.SalesmanName = '';
    this.numberedit = "";
    this.activate = "done";
    this.buttonText = "Save";
    this.modalRef.hide();
    this.SuccessCount = "0";
    this.ErrorCount = "0";
    this.data = null;
    this.result = "0";
    (this.data)
    // this.formval.Email = '';
    // this.formval.Mobile = '';
    // this.formval.Distributor = '';
    // this.formval.Adhaar = '';
    
    // this.formval.FirmName = '';
    // this.formval.SiteNo = '';
    // this.formval.SiteDesc = '';
    

  }
  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.pagesUser.page = event.page;
    this.getDealerList();
    //  (this.users)
    this.currentPage = event.page + 1;
  }
  onpagechange() {
    this.pagesUser.page = 1;
    this.getDealerList();
  }

}
