import * as internal from "assert";

export class Dealer {
    DealerId: number;
    DealerName: string;
    DealerCode: string;
    SalesmanName: string;
    IsActive: boolean = true;
    TotalCount: number;


    // Email: string;
     AccountNo: string;
    // FirmName: string;
    // Address: string;
    // District: string;
    // City: string;
    // State: string;
    // Mobile: string;
    // Distributor: string;
    // Adhaar: string;
    // SiteNo: string;
    // SiteDesc: string;
    // ID: number;



}


export class DealerMasterResponse {

    ResponseCode: string;
    ResponseMessage: string;
    ResponseData: Dealer[];
    TotalCount: number;

}