import { Component, OnInit, TemplateRef } from '@angular/core';
import { Mapping, MappingResponse, AvailResponse } from '../models/mapping';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { ToastrService } from 'ngx-toastr';
import { Product, ProductResponse } from '../models/product';
import { PackSize, PackSizeResponse } from '../models/packsize';
import { Denomination, DenominationResponse } from '../models/denomination';
import { StockResponse, Stock, StockFromNumberResponse } from '../models/stock';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.css']
})
export class StockComponent implements OnInit {
  show: boolean = false;
  formval: Stock = new Stock();
  formvalForSave: Stock = new Stock();
  modalHead: string = "Add Stock Mapping";
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  url: string;
  mappings: Mapping[] = [];
  avails: Stock[] = [];
  products: Product[] = [];
  productlist: Product[] = [];
  availlist: Mapping[] = [];
  mappinglist: Stock[] = [];
  totaldata: number;
  availtotaldata: number;
  producttotaldata: number;
  onEdit: boolean = false;
  usertype: string;
  pagesUser: UserPage = new UserPage();
  pagesUserStock: UserPage = new UserPage();
  pagesUserProduct: UserPage = new UserPage();
  packsizelist: PackSize[] = [];
  denominationlist: Denomination[] = [];
  DenominationName: string;
  submitForm: NgForm;
  checkFromNumber: string;
  checkToNumber: string;
  showDenominationlist: any;
  DenominationId: boolean = false;
  showDenominationTemp: any;
  UserType: number;
  constructor(private toastr: ToastrService, private modalService: BsModalService, private apiService: ApiService) {
    this.pagesUser.Filter = "FromNumber";
    this.formval.HologramID = 0;
  }
  ngOnInit() {
    debugger;
    this.usertype = localStorage.getItem("usertype");
    this.pagesUser.HologramID = 0;
    this.pagesUser.Organisation = parseInt(localStorage.getItem("organisation"));
    this.getstocklistapi();
    this.getDenominationList();
    this.onEdit = false;
  }
  onUserTypeSelect() {
    debugger;
    console.log(this.formval.UserType)

    this.showDenominationTemp = JSON.parse(JSON.stringify(this.denominationlist));
    //console.log(this.denominationlist);
    if (this.formval.UserType == 1) {
      debugger;
      this.showDenominationlist =
        this.getFromNumberByDenomination();
      //this.showDenominationlist = this.denominationlist  
      // this.getDenominationList();
      this.showDenominationlist = this.showDenominationTemp.filter((x) => x.ID != 8)
      this.DenominationId = true;


    }
    else if (this.formval.UserType == 2) {
      this.DenominationId = false;
      this.formval.HologramID = 8;
      this.getFromNumberByDenomination();


      //this.showDenominationlist = this.showDenominationlist.filter((x)=> x.denominationlist == "null")
    }
    //console.log(this.showDenominationlist)
  }
  openModal(template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
    this.modalRef = this.modalService.show(template, config);
  }
  openModal2(template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
    this.modalRef2 = this.modalService.show(template, config);
  }
  removeQty() {
    this.formval.Quantity = '';
  }
  onutypechange() {
    this.getstocklistapi();
  }
  calc2(val: number) {
    debugger;
    if (this.formval.Quantity == '' || parseInt(this.formval.Quantity) == 0) {
      this.toastr.warning('Please fill quantity.', 'Warning'),{
        timeOut:1000
      }
      this.formval.ToNumber = '';
    }
    else {
      for (var i = 0; i < this.denominationlist.length; i++) {
        if (this.formval.HologramID == this.denominationlist[i].ID) {
          this.formval.Prefix = this.denominationlist[i].Prefix;
        }
      }
      var len = this.formval.FromNumber.length;
      if (len == 15) {

        this.formval.FromNumber = this.formval.FromNumber.substring(6, len);
      }
      else if (len == 16) {

        this.formval.FromNumber = this.formval.FromNumber.substring(7, len);
      }

      if (parseInt(this.formval.FromNumber) == 0) {
        this.formval.FromNumber = '';
        this.formval.ToNumber = '';
      }
      else if (parseInt(this.formval.Quantity) == 0) {
        this.formval.ToNumber = '';
        this.formval.Quantity = '';
        this.formval.FromNumber = this.formval.Prefix + this.formval.FromNumber;
      }
      else {
        var txtfromNo = parseInt(this.formval.FromNumber);
        var txtqtyNo = parseInt(this.formval.Quantity);
        var txttoNo = txtfromNo + txtqtyNo - 1;
        this.formval.ToNumber = txttoNo.toString();
        this.formval.FromNumber = this.formval.Prefix + this.mypadding(parseInt(this.formval.FromNumber), 9);
        this.formval.ToNumber = this.formval.Prefix + this.mypadding(parseInt(this.formval.ToNumber), 9);
      }
    }
  }
  calc(val: number) {
    for (var i = 0; i < this.denominationlist.length; i++) {
      if (this.formval.HologramID == this.denominationlist[i].ID) {
        this.formval.Prefix = this.denominationlist[i].Prefix;
      }
    }
    // var len=this.formval.FromNumber.length;
    // this.formval.FromNumber=this.formval.FromNumber.substring(1,len-1);
    if (parseInt(this.formval.FromNumber) == 0) {
      this.formval.FromNumber = '';
      this.formval.ToNumber = '';
    }
    else if (parseInt(this.formval.Quantity) == 0) {
      this.formval.ToNumber = '';
      this.formval.Quantity = '';
      this.formval.FromNumber = this.formval.Prefix + this.mypadding(parseInt(this.formval.FromNumber), 9);
    }
    else {
      var txtfromNo = parseInt(this.formval.FromNumber);
      var txtqtyNo = parseInt(this.formval.Quantity);
      this.formval.FromNumber = this.formval.Prefix + this.mypadding(parseInt(this.formval.FromNumber), 9);
    }
  }
  getFromNumberByDenomination() {
    debugger;
    this.formval.ToNumber = '';
    this.formval.FromNumber = '';
    this.formval.Quantity = '';
    if (this.formval.HologramID == 0) {
      this.formval.Quantity = '';
      this.formval.ToNumber = '';
      this.formval.FromNumber = '';
      this.formval.HologramID = 0;
    }
    else {
      for (var i = 0; i < this.denominationlist.length; i++) {
        if (this.formval.HologramID == this.denominationlist[i].ID) {
          this.formval.Prefix = this.denominationlist[i].Prefix;
        }
      }

      this.formval.FromNumber = "1";
      this.apiService.PostApi(this.pagesUser, "Stock/GetFromNumber?HologramID=" + this.formval.HologramID).subscribe((response: StockFromNumberResponse) => {
        debugger;
        this.formval.FromNumber = response.ResponseData
        this.calc(parseInt(this.formval.FromNumber));
      }, err => {
        this.formval.FromNumber = this.formval.Prefix + '000000001';
      })
      if (this.formval.HologramID == 0) {
        this.formval.FromNumber = "";
        this.formval.ToNumber = "";
        this.formval.Quantity = "";
      }
    }
  }
  mypadding(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  getstocklistapi() {
    debugger;
    (this.pagesUser)
    this.url = "Stock/GetStock"
    // if(this.usertype=='0')
    {
      debugger;
      this.pagesUser.IsReceived = "0";
      this.pagesUser.IsTransferred = "0";
    }
    // else
    // {
    //   this.pagesUser.IsReceived="1";
    //   this.pagesUser.IsTransferred="1";
    // }
    this.show = true;
    this.mappinglist = this.mappinglist.slice(0, 0);
    this.apiService.PostApi(this.pagesUser, this.url).subscribe((response: any) => {
      (response)
      this.mappinglist = response.ResponseData;
      if (this.mappinglist.length > 0) {
        for (var i = 0; i < this.mappinglist.length; i++) {
          this.mappinglist[i].FromNumber = this.mappinglist[i].Prefix + this.mypadding(parseInt(this.mappinglist[i].FromNumber), 9);
          this.mappinglist[i].ToNumber = this.mappinglist[i].Prefix + this.mypadding(parseInt(this.mappinglist[i].ToNumber), 9);
        }
      }
      (this.mappinglist)
      this.totaldata = response.ResponseData[0].TotalCount;
      this.show = false;
    })
    this.show = false;
  }
  onSubmit(f: NgForm) {

    debugger;
    if ((this.formval.FromNumber == null || this.formval.FromNumber == undefined || this.formval.FromNumber == '')||
      (this.formval.ToNumber == null || this.formval.ToNumber == undefined || this.formval.ToNumber == '')||
      ( this.formval.Quantity == null || this.formval.Quantity == undefined || this.formval.Quantity == '')
      || (this.formval.UserType == null || this.formval.UserType == 0 )|| 
      (this.formval.HologramID == null || this.formval.HologramID==0)
    ) {
      this.toastr.warning('Please fill all required details', 'Warning!', {
        timeOut: 2000

      });
      return false
    }
    this.submitForm = f;
    this.show = true;
    this.formvalForSave = this.formval;
    var flen = this.formvalForSave.FromNumber.length;
    var tlen = this.formvalForSave.ToNumber.length;
    if (flen == 15) {
      this.formvalForSave.FromNumber = this.formval.FromNumber.substring(6, flen);
      this.formvalForSave.ToNumber = this.formval.ToNumber.substring(6, tlen);
    }
    else if (flen == 16) {
      this.formvalForSave.FromNumber = this.formval.FromNumber.substring(7, flen);
      this.formvalForSave.ToNumber = this.formval.ToNumber.substring(7, tlen);
    }
    this.saveStock();
  }
  formErrors = {
    Quantity: 'Quantity must be greater than zero'
    //'Quantity must be greater than zero',
  };
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  removenumber() {
    if (this.formval.Quantity == '') {
      this.formval.ToNumber = '';
    }
  }
  saveStock() {
    debugger;
    this.formvalForSave.Organisation = parseInt(localStorage.getItem("organisation"));
    this.formvalForSave.CreatedBy = localStorage.getItem("userID");
    if (localStorage.getItem("usertype") == "0") {
      this.formvalForSave.Organisation = 1;
    }
    if (parseInt(this.formvalForSave.FromNumber) <= 0 || parseInt(this.formvalForSave.ToNumber) <= 0 || parseInt(this.formvalForSave.Quantity) <= 0) {
      this.toastr.warning("Negative Values are not allowed", "Warning!")
      this.show = false;
    }
    else if (parseInt(this.formvalForSave.Quantity) == 0 || parseInt(this.formvalForSave.Quantity) == undefined) {
      this.toastr.warning("Quantity cannot be null", "Warning!")
      this.show = false;
    }
    else {
      if (this.onEdit == true) {
        this.apiService.PostApi(this.formvalForSave, "Stock/EditStock").subscribe((response: StockResponse) => {
          (response)
          if (response.ResponseCode == "200") {
            this.submitForm.resetForm();
            this.show = false;
            this.toastr.success("Stock Updated Successfully", "Success!")
            ,{
              timeOut:1000
            }
            this.getstocklistapi();
            this.resetmodal();
          }
          else {
            this.toastr.warning(response.ResponseMessage, "Warning!")
            this.show = false;
          }
        }, err => {
          this.toastr.warning(err.error.ResponseMessage, "Warning!")
          this.show = false;
        })
      }
      else {
        this.apiService.PostApi(this.formval, "Stock/SaveStock").subscribe((response: StockResponse) => {
          (response)
          if (response.ResponseCode == "200") {
            this.submitForm.resetForm();
            this.show = false;
            this.toastr.success("Stock Added Successfully", "Success!")
            ,{
              timeOut:1000
            }
            this.getstocklistapi();
            this.resetmodal();
          }
          else {
            this.toastr.warning(response.ResponseMessage, "Warning!")
            this.formval.FromNumber = this.formval.Prefix + this.formval.FromNumber;
            this.formval.ToNumber = this.formval.Prefix + this.formval.ToNumber;
            this.show = false;
          }
        }, err => {
          this.toastr.warning(err.error.ResponseMessage, "Warning!")
          this.formval.FromNumber = this.formval.Prefix + this.formval.FromNumber;
          this.formval.ToNumber = this.formval.Prefix + this.formval.ToNumber;
          this.show = false;
        })
      }
    }
  }
  editstock(template: TemplateRef<any>, summary: Stock) {
    debugger;
    //var a = new Array(summary);
    var ut = Object.values(summary)[0];
    this.formval = { ...summary };
    //console.log(Object.keys(summary)[0],Object.values(summary)[0]);
    if (Object.values(summary)[0] == 'Painter') {
      this.formval.UserType = 1
    }
    else if (Object.values(summary)[0] == 'Contractor') {
      this.formval.UserType = 2
    }
    else {
      this.formval.UserType = 0
    }



    var flen = this.formval.FromNumber.length;
    var tlen = this.formval.ToNumber.length;
    this.checkFromNumber = this.formval.FromNumber.substring(1, flen);
    this.checkToNumber = this.formval.ToNumber.substring(1, tlen);
    for (var i = 0; i < this.denominationlist.length; i++) {
      if (this.formval.HologramID == this.denominationlist[i].ID) {
        this.formval.Prefix = this.denominationlist[i].Prefix;
      }
    }
    this.onEdit = true;
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
    this.modalRef = this.modalService.show(template, config);
  }
  resetmodal() {
    this.modalRef.hide();
    this.onEdit = false;
    this.formval.UserType = 0;
    this.formval.FromNumber = '';
    this.formval.HologramID = 0;
    this.formval.ToNumber = '';
    this.formval.Quantity = '';
    this.formvalForSave = this.formval;
  }
  closemodal2() {
    this.modalRef2.hide();
  }
  closemodal3() {
    this.modalRef3.hide();
  }
  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.pagesUser.page = event.page;
    this.getstocklistapi();
  }
  ontypechange() {
    this.pagesUser.page = 1
    this.getstocklistapi();
  }
  getDenominationList() {
    this.apiService.GetApi(this.pagesUserStock, "Other/GetDenomiations").subscribe((response: any) => {

      if (response.ResponseCode == "200") {
        this.denominationlist = response.ResponseData;
        this.formval.Prefix = response.ResponseData[0].Prefix;
      }
    })
  }
}
