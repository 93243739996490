import { Component,  TemplateRef } from '@angular/core';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { UserMasterResponse, User } from '../models/user';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/ngx-bootstrap-pagination';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { OrganisationResponse, Organisation } from '../models/organisation';
import { DenominationSetting } from '../models/setting';
import { Product, ProductResponse } from '../models/product';
import { PackSize, PackSizeResponse } from '../models/packsize';
import { Denomination } from '../models/denomination';
import { DenominationsettingComponent } from '../denominationsetting/denominationsetting.component';
// import { PageRequest } from '../models/scheme';

@Component({
  selector: 'app-CouponConfiguration',
  templateUrl: './CouponConfiguration.component.html',
  styleUrls: ['./CouponConfiguration.component.css']
})
export class CouponconfigurationComponent  {
  sales: any[] = [];
  cols: any[] = [];
  cols2:any[]=[]
  FromDate: Date;
  minDate = new Date();
  ToDate:Date;
  EditDenomination:boolean=false;
  selectedData: any[] = [];
  selectedColumns2:any[]=[];
  displayProductsList:boolean=false;
  selectedColumns: any[] = [];
  totalRecords: number
  categoryList: any[] = []
  SchemeHeader: string
  imageToShow: string = ''
  isImageAvailable: boolean = false
  typeList: any[] = []
  selectedFile:any;
  selectedManual:any;
  ManualUrl:string;
  totalRecords2:any;
  FileUrl: string;
  packsizelist:any[]=[];
  ImageBase64: any
  AudioBase64: any
  pagedetails: any
  productlist:any[]=[];
  stateList: any[] = []
  speciesList: any[] = []
  submitted: boolean = false;
  display: boolean = false;
  schemeMasterForm: FormGroup;
  prmGrid2:any;
  fDate: any;
  tDate:any;

  constructor( private fb: FormBuilder, private api: ApiService, private tstr: ToastrService) {

  }
  get u() {
    return this.schemeMasterForm.controls;
  }
  createScheme(){
    this.schemeMasterForm = this.fb.group({ 
      SchemeName:['',Validators.required],
        SchemeID:[''], 
        PackSize:['',Validators.required],
        ProductID:[''],
        ProductName:['',Validators.required],
        Point:[0,Validators.required],
        FromDate:[''],
        ToDate:[''],
        CreatedBy:[''],
        IsActive:[true]

    })
  }
 
  openProductdialog() {
  this.displayProductsList=true;

const obj = this.defaultvalue();
this.getProductList(obj);

  }
  defaultvalue(){
    const obj = {
      page: 1,
      searchValue: '',
      pageSize: 5,
      filter: "",
      isActive:true
    };
    return obj;

  }
  onChange(){
    debugger;
    if(this.schemeMasterForm.value.FromDate==null||this.schemeMasterForm.value.FromDate=='' && this.schemeMasterForm.value.ToDate != null || this.schemeMasterForm.value.ToDate != ''){
      this.tstr.warning('Please Select a Valid From Date');
      return false;
    }
    if (this.schemeMasterForm.value.FromDate>this.schemeMasterForm.value.ToDate ){
      this.tstr.warning('To Date Cannot be Smaller than From Date ');
      return false;
    }
    if(this.schemeMasterForm.value.ToDate==null||this.schemeMasterForm.value.ToDate=='' && this.schemeMasterForm.value.FromDate != null || this.schemeMasterForm.value.FromDate != '')
    {
      this.tstr.warning('Please Select a Valid To Date');
      return false;
    }
  }
  adddialog(){
    debugger
    this.display=true
    this.FromDate=null;
    this.ToDate=null;
    this.fDate=null;
    this.tDate =null;
    this.schemeMasterForm.reset();
    this.createScheme();
    this.schemeMasterForm.controls.IsActive.setValue(true);
    this.SchemeHeader="Add Scheme";
   
  }


  Updatedialog(rowData:any){
    debugger;
    
     this.fDate=new Date(rowData.FromDate).toString()
   this.tDate= new Date(rowData.ToDate).toString()
   if(this.fDate.includes('Jan 01 1970'))
   {
    this.fDate=null;
   } 
   else if(this.fDate.includes('Dec 30 1969'))
   {
    this.fDate=null;
   } 
   else
   {
     this.fDate=new Date(rowData.FromDate)
   }
   if(this.tDate.includes('Jan 01 1970'))
   {
    this.tDate=null;
   } 
   else if(this.tDate.includes('Dec 30 1969'))
   {
    this.tDate=null;
   }  
   else
   {
     this.tDate=new Date(rowData.ToDate)
   }
    let packSize=this.packsizelist.find(x=>x.ID==rowData.PackID)
    this.display=true
    this.schemeMasterForm.reset();
    this.SchemeHeader="Update Scheme";
    this.schemeMasterForm.patchValue({
      SchemeName:rowData.SchemeName,
      SchemeID:rowData.SchemeID,
      PackSize:packSize,
      ProductID:rowData.ProductID,
      ProductName:rowData.ProductCode,
      Point:rowData.Point,
      FromDate:this.fDate,
      ToDate:this.tDate,
      CreatedBy:rowData.CreatedBy,
      IsActive:rowData.IsActive,
    });

  }
selectProduct(event:any){
 
  this.schemeMasterForm.patchValue({
    ProductName:event.ProductCode,
    ProductID:event.ID
  });
  this.schemeMasterForm.value.ProductID=event
  
  this.displayProductsList=false;


}

MDateCheck(){}
savescheme(){
  debugger;
  if(this.schemeMasterForm.value.Point == 0 || this.schemeMasterForm.value.Point < 0)
  {
    this.tstr.warning('Please Enter valid Point');
    return;
  }
  if(this.schemeMasterForm.value.FromDate=="" &&  this.schemeMasterForm.value.ToDate !="")
  {
    this.tstr.warning('Please Select From Date');
    return;
  }
  else if(this.schemeMasterForm.value.FromDate==null &&  this.schemeMasterForm.value.ToDate !=null)
  {
    this.tstr.warning('Please Select From Date');
    return;
  }
  else if(this.schemeMasterForm.value.FromDate!="" &&  this.schemeMasterForm.value.ToDate =="")
  {
    this.tstr.warning('Please Select To Date');
    return;
  }
  else if(this.schemeMasterForm.value.FromDate!=null &&  this.schemeMasterForm.value.ToDate ==null)
  {
    this.tstr.warning('Please Select To Date');
    return;
  }
  else 
  {
  if(this.schemeMasterForm.invalid){
    this.tstr.warning('Please Fill All Required Details');
    return;
  }
  else if(this.schemeMasterForm.value.SchemeName==''|| this.schemeMasterForm.value.SchemeName==null){

    this.tstr.warning('Scheme Name is Required');
    return;
  }
  let obj={
    schemeName:this.schemeMasterForm.value.SchemeName,
    productID:this.schemeMasterForm.value.ProductID,
    point:this.schemeMasterForm.value.Point,
    fromDate:this.schemeMasterForm.value.FromDate,
    toDate:this.schemeMasterForm.value.ToDate,
    createdBy:parseInt(localStorage.getItem("userID")),
    isActive:this.schemeMasterForm.value.IsActive,
    packSize:this.schemeMasterForm.value.PackSize.ID, 
  }
  // if(this.schemeMasterForm.value.FromDate==null||this.schemeMasterForm.value.FromDate==''){
  //   this.tstr.warning('Please Select a Valid From Date');
  // }
  if (this.schemeMasterForm.value.FromDate>this.schemeMasterForm.value.ToDate){
    this.tstr.warning('To Date Cannot be Smaller than From Date ');
    return false;
  }
  else{
  this.api.PostApi( obj,'Scheme/SaveScheme').subscribe((res: any) => {
    debugger
    if (res.ResponseCode == 200) {

      this.tstr.success(res.ResponseMessage);
      this.display=false;
      const obj = this.getPageInfo(this.pagedetails);
      this.getSchemeList(obj);
    } else {
      this.tstr.warning(res.ResponseMessage);
    }
  },error=>{
    this.sales = []
    this.totalRecords = 0
  });

}}}
UpdateScheme(){
  debugger;
let obj={
  SchemeID:this.schemeMasterForm.value.SchemeID,
  schemeName:this.schemeMasterForm.value.SchemeName,
  productID:this.schemeMasterForm.value.ProductID,
  point:this.schemeMasterForm.value.Point,
  fromDate:this.schemeMasterForm.value.FromDate,
  toDate:this.schemeMasterForm.value.ToDate,
  createdBy:parseInt(localStorage.getItem("userID")),
  isActive:this.schemeMasterForm.value.IsActive,
  packSize:this.schemeMasterForm.value.PackSize.ID,
}
if(this.schemeMasterForm.value.Point == 0 || this.schemeMasterForm.value.Point < 0 )
  {
    this.tstr.warning('Please Enter valid Point');
    return;
  }
this.api.PostApi( obj,'Scheme/UpdateScheme').subscribe((res: any) => {
  debugger
  if (res.ResponseCode == 200) {

    this.tstr.success(res.ResponseMessage);
    this.schemeMasterForm.reset();
    
    this.display=false;
    const obj = this.getPageInfo(this.pagedetails);
    this.getSchemeList(obj);
  } else {
    this.tstr.warning(res.ResponseMessage);
  }
},error=>{
  this.sales = []
  this.totalRecords = 0
});

}

  submitScheme(){
    debugger
    if( this.SchemeHeader=="Add Scheme"){
      this.savescheme();
    }
    else{
      this.UpdateScheme();
    }

  }


  
  public getSchemeList(event: any): any {
  debugger
    this.api.PostApi( event,'Scheme/GetAllScheme').subscribe((res: any) => {
      debugger
      if (res.ResponseCode == 200) {

        this.totalRecords = res.ResponseData[0].TotalCount
        this.sales = res.ResponseData 
      } else {
        this.sales = []
        this.totalRecords = 0
      }
    },error=>{
      this.sales = []
      this.totalRecords = 0
    });
  }
  public lazyLoad(event: any) {
debugger
    //this method is called on page load to fetch data on the page
    this.pagedetails = event;
    const obj = this.getPageInfo(this.pagedetails);
     this.getSchemeList(obj);
  }
  getPageInfo(event: any) {
    //this method is used to get page information


    const requestFilter = {
      page: event.first == 0 ? 1 : (event.first / event.rows) + 1,
      pageSize: event.rows,
      searchValue: event.globalFilter ? event.globalFilter : '',
      sortBy: event.sortField == undefined ? '' : event.sortField,
      sortOrder: event.sortOrder === 1 ? 'ASC' : 'DESC',
      isActive:true
    }
    return requestFilter;
  }
  getPageInfoforProduct(event: any) {
    //this method is used to get page information


    const requestFilter = {
      page: event.first == 0 ? 1 : (event.first / event.rows) + 1,
      pageSize: event.rows,
      searchValue: event.globalFilter ? event.globalFilter : '',
      filter: event.sortField == undefined ? '' : event.sortField,
      isActive:true
    }
    return requestFilter;
  }
  getsearchforProduct(product: any) {
    debugger;
    


    const requestFilter = {
      page: 1,
      pageSize: 10,
      searchValue: product.value,
      filter: '',
      isActive:true
    }
    // return requestFilter;
    this.getProductList(requestFilter)
  }
 

  
  ngOnInit() {
    this.cols = [
      { field: 'SchemeName', header: 'Scheme Name' },
      { field: 'Point', header: 'Point' },
      { field: 'ProductCode', header: 'ProductCode' },
      { field: 'PackSize', header: 'PackSize' },
      { field: 'Category', header: 'Category' },
      { field: 'Subcategory', header: 'Sub-Category' },
      { field: 'Description', header: 'Description' },
      
    ];
    this.cols2 = [
     
      { field: 'ProductCode', header: 'Product Code' },
      { field: 'Category', header: 'Category' },
      { field: 'SubCategory', header: 'Sub Category' },
      { field: 'Description', header: 'Description' },
    

    ];
    this.selectedColumns = this.cols;
    this.selectedColumns2=this.cols2;
    this.sales = [];
   this.createScheme();
   this.getPackSizeList();
  
  
  }
  
 
  getPackSizeList()
  {let obj={};
    this.api.PostApi(obj,"Other/GetPackSize").subscribe((response: PackSizeResponse) => {
      if(response.ResponseCode=="200")
      {
        this.packsizelist=response.ResponseData;
      }
    })
  }
  loadLazy2(event: any) {
    debugger;
    this.prmGrid2 = event;
    const obj=this.getPageInfoforProduct(event);
    this.getProductList(obj);
  }
  getProductList(event:any) {
    debugger;
    this.api.PostApi(event, "Setting/GetAllProductforsetting").subscribe((response: any) => {
      debugger;
       (response)
      if (response.ResponseCode == "200") {
        this.productlist = response.ResponseData.ProductData;
       this.totalRecords2 = response.ResponseData.TotalCount;
      }
      else {
        this.productlist = null;
        //this.totalRecords2 = 0;
      }
    })
  }
  close(){}
  
  


  
 


  //////////////////////////////// Export to Excel Code Start

  
  
  //////// Get Product List for Excel

  


}