import { Component, OnInit, TemplateRef } from '@angular/core';
import { UserPage } from '../models/login';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../Services/api-service';
import { User, UserMasterResponse, DepotList, DepotListReponse } from '../models/user';
import { StateResponse, States } from '../models/State';
import { City, CityResponse } from '../models/city';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-paintermaster',
  templateUrl: './paintermaster.component.html',
  styleUrls: ['./paintermaster.component.css']
})
export class PaintermasterComponent implements OnInit {

  pagesUser:UserPage=new UserPage();
  users:User[]=[];
  formval:User=new User();
  show:boolean=false;
  modalhead:string="Add Painter";
  dnm:boolean=false;
  totaldata:number;
  cities:City[]=[];
  depotlist:DepotList[]=[];
  states:States[]=[];
  numberedit:string="";
  template:TemplateRef<PaintermasterComponent>;
  modalRef: BsModalRef;
  mobileReg:RegExp=/^[6-9]\d{9}$/;
  emailReg:RegExp=/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  isEdit:boolean=true;
  buttonText:string="Save";
  submitForm: NgForm;


  constructor(private toastr: ToastrService,private apiService:ApiService,private modalService: BsModalService) {

    this.pagesUser.Filter='FirstName';

    this.pagesUser.UserType=4;
  }

  ngOnInit() {
    if(localStorage.getItem("usertype")!="0")
    {
      this.pagesUser.Organisation=parseInt(localStorage.getItem("organisation"));
    }

    this.getStatesApi();

    this.formval.State=0;
    this.formval.IsActive=false;

    this.getUsers(this.pagesUser);

    this.getDepotList();
    this.formval.DepotCode=0;
  }

  getDepotList()
  {

    this.apiService.PostApi(null,"Other/GetDepotList").subscribe((response: DepotListReponse) => {


       (response)
      this.depotlist=response.ResponseData;

    })
  }

  alphaOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;




    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;

  }

  onChange(newValue:number)
  {

    this.getCities(newValue);
    this.formval.City=0;


//    alert(newValue)
  }

  getCities(state:number)
  {
    this.apiService.PostApi(null,"Other/GetCity?StateID="+state).subscribe((response: CityResponse) => {


      this.cities=response.ResponseData;

    })

  }

  getStatesApi()
  {
    this.apiService.PostApi(null,"Other/GetState").subscribe((response: StateResponse) => {

      this.states=response.ResponseData;


    })

  }

  onSubmit(f:NgForm)
  {

    this.submitForm = f;
    this.show=true;

    if(this.modalhead=="Add Painter")
    {
      if(this.formval.Password==this.formval.cpassword)
      {
      this.formval.UserType=4;
      this.formval.CreatedBy=0;
      this.saveUser(this.formval,"User/SaveUser");

      }
      else
      {
        this.dnm=true;
        this.show=false;
      }
    }
    else
    {

      this.formval.UserType=4;
      this.formval.CreatedBy=0;
      this.saveUser(this.formval,"User/EditUser");


    }


  }

  saveUser(formval:User,url:string)
  {


    this.apiService.PostApi(formval,url).subscribe((response: UserMasterResponse) => {

      if (response.ResponseCode == "200") {

        this.getUsers(this.pagesUser);
        this.resetmodal();

    this.show=false;
        this.toastr.success( response.ResponseMessage,'Success!',{
          timeOut:3000

         });


      }
      else
      {
        this.toastr.warning( response.ResponseMessage,'Error!',{
          timeOut:3000

         });

    this.show=false;
      }
    }, err => {
      this.toastr.warning( err.error.ResponseMessage,'Error!',{
        timeOut:3000

       });

    this.show=false;
    });


  }

  getUsers(user:UserPage): any {

    this.show=true;


    this.apiService.PostApi(this.pagesUser,"User/GetAllUser").subscribe((response: UserMasterResponse) => {
      if (response.ResponseCode == "200") {

        this.users = response.ResponseData.UserData;
         this.totaldata=response.ResponseData.TotalCount;
       this.show=false;
      }
      else {

    this.users=null;
      }

    }, err => {

    this.users=null;

    });

    this.show=false;

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;




    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  openModal(template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true,class: 'modal-lg' };

    this.modalRef = this.modalService.show(template, config);

  }


  resetmodal()
  {
    this.modalRef.hide();
    this.formval.FirstName='';
    this.formval.LastName='';
    this.formval.Email='';
    this.formval.Mobile='';
    this.formval.Password='';
    this.formval.cpassword='';
    this.dnm=false;
    this.formval.Address='';
    this.formval.District='';
    this.formval.Pin='';
    this.modalhead="Add Painter";
    this.isEdit=true;
    this.formval.State=0;
    this.formval.City=0;
    this.cities=null;
    this.numberedit="";
    this.formval.ShopName='';
    this.buttonText="Save";
    this.formval.HQName='';
    this.modalRef.hide();
  }
  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
      const startItem = (event.page - 1) * event.itemsPerPage;
      const endItem = event.page * event.itemsPerPage;
      this.pagesUser.page=event.page;
      this.getUsers(this.pagesUser);

     //  (this.users)
    }

  onpagechange  ()
    {
      this.pagesUser.page=1;
      this.getUsers(this.pagesUser);

    }


    edituser(template: TemplateRef<any>,user:User)
    {

      this.isEdit=false;
      //alert(this.user.firstName);
      this.modalhead="Edit Painter"

      this.numberedit="readonly";

      this.formval={...user};
      this.formval.cpassword=this.formval.Password;
      this.getCities(this.formval.State);
      this.buttonText="Update";

      let config = { backdrop: true, ignoreBackdropClick: true,class: 'modal-lg' };

      this.modalRef = this.modalService.show(template, config);
    }




}
