import { Component, OnInit, TemplateRef } from '@angular/core';
import { Mapping, MappingResponse, AvailResponse } from '../models/mapping';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { ToastrService } from 'ngx-toastr';
import { Product, ProductResponse } from '../models/product';
import { PackSize, PackSizeResponse } from '../models/packsize';
import { Denomination, DenominationResponse } from '../models/denomination';
import { StockResponse, Stock, StockFromNumberResponse } from '../models/stock';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { ScanReport, ScanReportResponse, PassbookReport, PassbookReportResponse, PassbookExcelData, ReceiveReport, ReceiveReportResponse, ReceiveReportExcelData } from '../models/report';
import { ExcelService } from '../excel.service';
import { MyExcelData } from '../models/user';
@Component({
  selector: 'app-receive-report',
  templateUrl: './receive-report.component.html',
  styleUrls: ['./receive-report.component.css']
})
export class ReceiveReportComponent implements OnInit {
  show: boolean = false;
  formval: ReceiveReport = new ReceiveReport();
  modalHead: string = "Add Stock Mapping";
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  mappings: Mapping[] = [];
  avails: Stock[] = [];
  products: Product[] = [];
  reportExcelPassbook: ReceiveReportExcelData[] = [];
  productlist: Product[] = [];
  availlist: Mapping[] = [];
  scanreportlist: any[] = [];
  scanreportlistexcel: ReceiveReport[] = [];
  totaldata: number;
  availtotaldata: number;
  producttotaldata: number;
  onEdit: boolean = false;
  pagesUser: UserPage = new UserPage();
  pagesUserStock: UserPage = new UserPage();
  pagesUserProduct: UserPage = new UserPage();
  packsizelist: PackSize[] = [];
  denominationlist: Denomination[] = [];
  DenominationName: string;
  deleteId: string;
  submitForm: NgForm;
  constructor(private excelService: ExcelService, private toastr: ToastrService, private modalService: BsModalService, private apiService: ApiService) {
  }
  ngOnInit() {
    this.setDates();
    this.formval.pageNumber = 1;
    this.formval.pageSize = 5;
    this.getscanreport();
  }
  onutypechange() {
    this.getscanreport();
  }
  setDates(): any {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var currentDay = date.getDate();
    this.formval.fromdate = moment(firstDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(currentDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
  }
  exportAsXLSX(myreport: any): void {
    this.excelService.exportAsExcelFile(this.reportExcelPassbook, 'ReceiveReport_' + moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY") + '_to_' + moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY"));
    this.formval.pageSize = 5;
  }
  DownloadScanReport() {
    this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {
      this.toastr.warning( 'From Date should not be greater than To Date!','Warning!', {
        timeOut: 3000
      });
    }
    else {
      this.show = true;
      this.formval.pageSize = 100000;
      this.formval.pageNumber = 1;
      this.show = true;
      this.apiService.PostApi(this.formval, "Report/GetReceiveReport").subscribe((response: ReceiveReportResponse) => {
        this.scanreportlistexcel = response.ResponseData;
        this.reportExcelPassbook = this.reportExcelPassbook.slice(0, 0);
        this.scanreportlistexcel.forEach(o => {
          this.reportExcelPassbook.push(
            { TransactionDate: o.TransactionDate, FromOrganisation: o.FromOrganisation, ToOrganisation: o.ToOrganisation, Denomination: 'Rs.'+o.Denomination, FromNumber: o.FromNumber, ToNumber: o.ToNumber, Quantity:o.Quantity });
          });
        this.exportAsXLSX(this.reportExcelPassbook);
        this.show = false;
      },
        err => {
          this.show = false;
          this.totaldata = 0;
        })
      this.show = false;
    }
  }
  getscanreport() {
    this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {
      this.toastr.warning( 'From Date should not be greater than To Date!','Warning!', {
        timeOut: 3000
      });
    }
    else {
      this.scanreportlist = this.scanreportlist.slice(0, 0);
      this.show = true;
      this.apiService.PostApi(this.formval, "Report/GetReceiveReport").subscribe((response: any) => {
        this.scanreportlist = response.ResponseData;
        if(this.scanreportlist.length>0)
          {
            for(var i=0;i<this.scanreportlist.length;i++)
            {
              this.scanreportlist[i].FromNumber=this.scanreportlist[i].Prefix + this.mypadding(parseInt(this.scanreportlist[i].FromNumber), 9)
              this.scanreportlist[i].ToNumber=this.scanreportlist[i].Prefix + this.mypadding(parseInt(this.scanreportlist[i].ToNumber), 9)
            }
          }
        this.totaldata = response.TotalCount;
        this.show = false;
      },
        err => {
          this.show = false;
          this.totaldata = 0;
        })
      this.show = false;
    }
  }
  mypadding(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.formval.pageNumber = event.page;
    this.getscanreport();
  }
  ontypechange() {
    this.pagesUser.page = 1
    this.getscanreport();
  }
}
