import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from './shared-service.service';
import {environment} from '../../environments/environment';
@Injectable({
    providedIn: 'root'

})

export class ApiService {

    token:any;
    url:any;
    //data:LoginResponse;

    constructor(private httpClient: HttpClient,private sharedService:SharedService) {
        this.url=environment.url;
        this.token=sessionStorage.getItem("token");
    }
    postApi(user:any,val:string) {


        // alert(JSON.stringify(user))
          return this.httpClient.post(`${this.url}`+val,user,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.token}) });
  
      }
  
    PostApi(user:any,val:string) {
        debugger;


      // alert(JSON.stringify(user))
        return this.httpClient.post(`${this.url}`+val,user,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.token}) });

    }


    PostApiLogin(Email:any,val:string) {
debugger;
      // alert(JSON.stringify(user))
        return this.httpClient.post(`${this.url}`+val,Email );

    }



    GetApi(user:any,val:string)
    {
        return this.httpClient.get(`${this.url}`+val);

    }
    getApi(val:string)
    {
        return this.httpClient.get(`${this.url}`+ val);

    }


}

