import { Component, OnInit, TemplateRef } from '@angular/core';
import { Mapping, MappingResponse, AvailResponse } from '../models/mapping';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { ToastrService } from 'ngx-toastr';
import { Product, ProductResponse,tokenStockExcel,tokenStockExcelReport} from '../models/product';
import { PackSize, PackSizeResponse } from '../models/packsize';
import { Denomination, DenominationResponse } from '../models/denomination';
import * as moment from 'moment';
import { StockResponse, Stock, StockFromNumberResponse } from '../models/stock';
import { NgForm } from '@angular/forms';
import { ExcelService } from '../excel.service';
@Component({
  selector: 'app-Productwisereport',
  templateUrl: './Productwisereport.component.html',
  styleUrls: ['./Productwisereport.component.css']
})
export class ProductwisereportComponent implements OnInit {
  pagesUser: UserPage = new UserPage();
  formval: Stock = new Stock();
  cnList:any;
  cnExcelList:any[]=[];
  cnExcelListReport:any[]=[];
  show: boolean = false;
  url: string;
  availableStockExcel:any[]=[];
  availableStockExcelReport:any[]=[];
  totaldata: number;
  // formval: Stock = new Stock();
  submitForm: NgForm;
  checkFromNumber: string;
  checkToNumber: string;
  organisation: string;
  
  constructor(private excelService: ExcelService,private toastr: ToastrService, private modalService: BsModalService, private api: ApiService) {
    this.formval.pageNumber=1;
    this.formval.pageSize='5';
    this.pagesUser.Filter='';
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var currentDay = date.getDate();
    this.formval.fromdate = moment(firstDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(currentDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
  }

  ngOnInit() {
    this.getCNreportData(),
    this.cnExcelList=null
  }
  getCNreportData()
  {
    debugger;
    let obj ={
      pageNumber:this.formval.pageNumber,
      pageSize:this.formval.pageSize,
      FilterBy:'',
      searchValues:'',
      IsActive:'',
      HoUserID:localStorage.getItem("userID"),
      FromDate: this.formval.fromdate,
      ToDate: this.formval.todate
    }
    this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {
      this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
        timeOut: 3000
      });
    }
    this.api.PostApi(obj,`Report/ProductWiseReport`).subscribe((resp:any)=>{
      debugger;
      if(resp.ResponseCode==200)
      {
        this.cnList=resp.ResponseData;
        this.totaldata=resp.ResponseData[0].TotalCount;
      }
      else{
        this.cnList=[];
        this.totaldata=0;
        
        // this.toastr.warning(resp.ResponseMessage, 'Warning!', {
        //   timeOut: 1000
        // });
      }
    })
  }
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.formval.pageNumber = event.page;
    this.getCNreportData();
  }
  ontypechange() {
    this.pagesUser.page = 1
    this.pagesUser.pageSize=this.formval.pageSize;
    this.getCNreportData();
  }
  DownloadCNReport()
  {debugger;
    this.cnExcelListReport=[];
    this.cnExcelList=[];
    let obj={
      pageNumber:this.formval.pageNumber,
      pageSize:this.totaldata,
      FilterBy:'',
      searchValues:'',
      IsActive:'',
      FromDate: this.formval.fromdate,
      ToDate: this.formval.todate,
      HoUserID:localStorage.getItem("userID"),
      Organisation:this.organisation = localStorage.getItem("organisation"),
    }
    this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {
      this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
        timeOut: 3000
      });
    }
    this.api.PostApi(obj,`Report/ProductWiseReport`).subscribe((resp:any)=>{
      if(resp.ResponseCode==200)
      {debugger;
        this.cnExcelList=resp.ResponseData;
        this.cnExcelList.forEach(o=>{
          this.cnExcelListReport.push({
            Product_Code:o.ProductCode,
            Product_Denomination:o.DenoRem,
            UOM_Category:o.UOM_Category,
            Sub_Category:o.SubCategory,
            Discription:o.Description,
            Created_Date:o.CreatedDate
          })
        })
        this.exportAsXLSX(this.cnExcelListReport);
        this.show = false;
      }
      else{
        this.toastr.warning('There is no data to download', 'Warning!', {
          timeOut: 1000
        });
    }},)
  }
  exportAsXLSX(myreport: any): void {
    this.excelService.exportAsExcelFile(this.cnExcelListReport, 'ProductWise Report');
    //this.cnExcelListReport = null;
  }

}
