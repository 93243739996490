export class StockTransfer
{
    FromNumber:string;
    ToNumber:string;
    Quantity:string;
    TotalCount:number
    isEdit:true;
    Denomination:string;;
    ID:string;
    HologramID:number;
    FromOrganisation:number;
    ToOrganisation:number;
    IsActive:boolean=true;
    Prefix:string;
    CreatedBy:string;    
    IsEditable:boolean;
}

export class StockTransferResponse
{
    ResponseCode:string;
    ResponseData:StockTransfer[];
    ResponseMessage:string;
}
