import { Component,  TemplateRef } from '@angular/core';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { UserMasterResponse, User } from '../models/user';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/ngx-bootstrap-pagination';
import { ToastrService } from 'ngx-toastr';
import { States, StateResponse } from '../models/State';
import { CityResponse, City } from '../models/city';
import { NgForm } from '@angular/forms';



@Component({
  selector: 'app-retailermaster',
  templateUrl: './salesmaster.component.html',
  styleUrls: ['./salesmaster.component.css']
})
export class SalesMasterComponent {

  pagesUser:UserPage=new UserPage();
  users:User[]=[];
  formval:User=new User();
  show:boolean=false;
  modalhead:string="Add User";
  dnm:boolean=false;
  totaldata:number;
  numberedit:string="";
  template:TemplateRef<SalesMasterComponent>;
  modalRef: BsModalRef;
  states:States[]=[];
  mobileReg:RegExp=/^[6-9]\d{9}$/;
  emailReg:RegExp=/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  isEdit:boolean=true;
  cities:City[]=[];
  currentPage:number;
  buttonText:string="Save";
  submitForm: NgForm;

  constructor(private toastr: ToastrService,private apiService:ApiService,private modalService: BsModalService) {

    this.pagesUser.Filter='FirstName';

  }

  ngOnInit() {

    this.getUsers(this.pagesUser);
    this.getStatesApi();

    this.formval.IsActive=true;
  }


  getStatesApi()
  {
    this.apiService.PostApi(null,"Other/GetState").subscribe((response: StateResponse) => {

      this.states=response.ResponseData;


    })

  }

  onChange(newValue:number)
  {

    this.getCities(newValue);

  }


  getCities(state:number)
  {
    this.apiService.PostApi(null,"Other/GetCity?StateID="+state).subscribe((response: CityResponse) => {


      this.cities=response.ResponseData;

    })

  }
  onSubmit(f:NgForm)
  {

    this.submitForm = f;
    this.show=true;

    if(this.modalhead=="Add User")
    {
      if(this.formval.Password==this.formval.cpassword)
      {
      this.formval.UserType=3;
      this.formval.CreatedBy=0;
      this.saveUser(this.formval,"User/SaveUser");
      }
      else
      {
        this.dnm=true;
        this.show=false;
      }
    }
    else
    {
      if(this.formval.Password==this.formval.cpassword)
      {
        alert(this.formval.State)
        alert(this.formval.AccountNo)
      this.saveUser(this.formval,"User/EditUser");
      }
      else
      {
        this.dnm=true;
        this.show=false;
      }

    }


  }

  saveUser(formval:User,url:string)
  {


    this.apiService.PostApi(formval,url).subscribe((response: UserMasterResponse) => {

      if (response.ResponseCode == "200") {

        this.submitForm.resetForm();
        this.getUsers(this.pagesUser);
        this.resetmodal();

    this.show=false;
        this.toastr.success(response.ResponseMessage,'Success!',{
          timeOut:3000

         });


      }
      else
      {
        this.toastr.warning( response.ResponseMessage,'Error!',{
          timeOut:3000

         });

    this.show=false;
      }
    }, err => {
      this.toastr.warning( err.error.ResponseMessage,'Error!',{
        timeOut:3000

       });

    this.show=false;
    });


  }

  getUsers(user:UserPage): any {

    this.show=true;

    this.pagesUser.UserType=3;

    this.apiService.PostApi(this.pagesUser,"User/GetAllUser").subscribe((response: UserMasterResponse) => {
      if (response.ResponseCode == "200") {



        this.users = response.ResponseData.UserData;
         this.totaldata=response.ResponseData.TotalCount;


         if(this.totaldata<parseInt(this.pagesUser.pageSize))
         {
           this.currentPage=1;
         }


      }
      else {

    this.users=null;

    this.show=false;
      }

    }, err => {

    this.users=null;

    this.show=false;
    });

    this.show=false;

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;




    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  alphaOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;




    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;

  }


  openModal(template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true,class: 'modal-lg' };

    this.modalRef = this.modalService.show(template,config);

  }


  resetmodal()
  {
    this.modalRef.hide();
    this.formval.FirstName='';
    this.formval.LastName='';
    this.formval.Email='';
    this.formval.Mobile='';
    this.formval.Password='';
    this.formval.cpassword='';
    this.dnm=false;
    this.modalhead="Add User";
    this.isEdit=true;
    this.numberedit="";
    this.formval.ShopName='';
    this.formval.FactoryName='';
    this.formval.HQName='';
    this.buttonText="Save";
    this.modalRef.hide();
  }
  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
      const startItem = (event.page - 1) * event.itemsPerPage;
      const endItem = event.page * event.itemsPerPage;
      this.pagesUser.page=event.page;
      this.getUsers(this.pagesUser);
     //  (this.users)
     this.currentPage=event.page+1;
    }

    onpagechange()
    {
      this.pagesUser.page=1;
      this.getUsers(this.pagesUser);
    }


    edituser(template: TemplateRef<any>,user:User)
    {

      this.isEdit=false;
      //alert(this.user.firstName);
      this.modalhead="Edit User"

      this.numberedit="readonly";

      this.getStatesApi();
      this.formval={...user};
      this.getCities(this.formval.State);
      this.formval.State={...user}.State;
      this.formval.City={...user}.City;
      this.formval.cpassword=this.formval.Password;
      this.buttonText="Update";

      let config = { backdrop: true, ignoreBackdropClick: true,class: 'modal-lg' };


      this.modalRef = this.modalService.show(template, config);
    }

}
