export class DenominationSetting {
  debugger;

  HologramID: number = 0;
  ProductID: number;
  ProductCode: string;
  PackSize: number = 0;
  ID: string;
  Remark: string;
  IsActive: string;
  Organisation:string;
  CreatedBy: string;
  Denomination: number;
  Description: string;
  DenominationName: string;
  Category: string;
  SubCategory: string;
  check:string;
  DenoRem:string;
  FromDate: any;
  Point: number;
  ToDate: any;
  SchemeID:number;
}

export class DenominationSettingResponse {

  ResponseCode: string;
  ResponseMessage: string;
  ResponseData: DenominationSetting[];
  TotalCount: number;

}