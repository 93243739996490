import { Component, TemplateRef } from '@angular/core';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { UserMasterResponse, User, UserExcelData } from '../models/user';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/ngx-bootstrap-pagination';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Organisation, OrganisationResponse } from '../models/organisation';
import { ExcelService } from '../excel.service';
@Component({
  selector: 'app-usermaster',
  templateUrl: './usermaster.component.html',
  styleUrls: ['./usermaster.component.css']
})
export class UsermasterComponent {
  pagesUser: UserPage = new UserPage();
  users: User[] = [];
  formval: User = new User();
  show: boolean = false;
  pagesuserorganisation: UserPage = new UserPage();
  modalhead: string = "Add User";
  showOrganisationList:any;
  isMobileDisabled:boolean = false;
  isEmailDisabled:boolean=false;
  dnm: boolean = false;
  organisationslist: Organisation[] = [];
  totaldata: number;
  numberedit: string = "";
  template: TemplateRef<UsermasterComponent>;
  modalRef: BsModalRef;
  mobileReg: RegExp = /^[6-9]\d{9}$/;
  emailReg: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  isEdit: boolean = true;
  currentPage: number;
  isDisabled: string = "false";
  buttonText: string = "Save";
  submitForm: NgForm;
  Userreportlistexcel: any[] = [];
  reportExcelPassbook: any[] = [];

  constructor(private excelService: ExcelService,private toastr: ToastrService, private apiService: ApiService, private modalService: BsModalService) {
    this.pagesUser.Filter = '';
    this.pagesUser.pageSize = "5"
  }
  ngOnInit() {
    if (localStorage.getItem("usertype") != "0") {
      this.pagesUser.Organisation = parseInt(localStorage.getItem("organisation"));
    }
    this.pagesUser.UserType = 4;
    this.formval.Organisation = "0";
    this.formval.UserType = 0;
    this.pagesUser.page = 1;
    this.pagesUser.pageSize = "5";
    this.getOrganisation(this.pagesuserorganisation);
    this.getUsers(this.pagesUser);
    this.formval.IsActive = true;
    this.pagesUser.pageSize="5"
  }
  getOrganisation(user: UserPage): any {
    this.show = true;
    this.pagesUser.UserType = 4;
    this.pagesUser.pageSize="5"
    if (this.organisationslist != null) {
      this.organisationslist = this.organisationslist.slice(0, 0);
    }
    debugger
    this.apiService.PostApi(this.pagesUser, "Organisation/GetAllOrganisation").subscribe((response: OrganisationResponse) => {
      if (response.ResponseCode == "200") {
         console.log(response)
        this.organisationslist = response.ResponseData;
        this.totaldata = response.TotalCount;
        if (this.totaldata < parseInt(this.pagesUser.pageSize)) {
          this.currentPage = 1;
        }
      }
      else {
        this.organisationslist = null;
        this.show = false;
      }
    }, err => {
      this.organisationslist = null;
      this.show = false;
    });
    this.show = false;
  }
  onSubmit(f: NgForm) {
    this.submitForm = f;
    this.show = true;
    if (this.modalhead == "Add User") {
      if (this.formval.Password == this.formval.cpassword) {
        this.formval.CreatedBy = 0;
        this.saveUser(this.formval, "User/SaveUser");
      }
      else {
        this.dnm = true;
        this.show = false;
      }
    }
    else {
      debugger;
      if (this.formval.Password == this.formval.cpassword) {
        this.saveUser(this.formval, "User/EditUser");
      }
      else {
        this.dnm = true;
        this.show = false;
      }
    }
  }
  onUserTypeSelect(){
    console.log(this.formval.UserType)
    this.showOrganisationList = JSON.parse(JSON.stringify(this.organisationslist));
    console.log(this.organisationslist);
    if(this.formval.UserType == 11 ){
      this.showOrganisationList = this.showOrganisationList.filter((x)=> x.OrganisationType==2)
    }else{
      this.showOrganisationList = this.showOrganisationList.filter((x)=> x.OrganisationType==1)
    }
    console.log(this.showOrganisationList)
  }
  saveUser(formval: User, url: string) {
    this.apiService.PostApi(formval, url).subscribe((response: UserMasterResponse) => {
      if (response.ResponseCode == "200") {
        this.submitForm.resetForm();
        this.getUsers(this.pagesUser);
        this.resetmodal();
        this.show = false;
        this.toastr.success(response.ResponseMessage, 'Success!', {
          timeOut: 3000
        });
      }
      else {
        this.toastr.warning(response.ResponseMessage, 'Error!', {
          timeOut: 3000,
        });
        this.show = false;
      }
    }, err => {
      this.toastr.warning(err.error.ResponseMessage, 'Error!', {
        timeOut: 3000
      });
      this.show = false;
    });
  }
  getUsers(user: UserPage): any {
    debugger
    this.show = true;
    this.apiService.PostApi(this.pagesUser, "User/GetAllUser").subscribe((response: UserMasterResponse) => {
      if (response.ResponseCode == "200") {
        this.users = response.ResponseData.UserData;
        console.log(this.users)
        this.totaldata = response.ResponseData.TotalCount;
        if (this.totaldata < parseInt(this.pagesUser.pageSize)) {
          this.currentPage = 1;
        }
      }
      else {
        this.users = null;
        this.show = false;
      }
    }, err => {
      this.users = null;
      this.show = false;
    });
    this.show = false;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  alphaOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }
  openModal(template: TemplateRef<any>) {
    this.formval.IsActive=true
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    this.modalRef = this.modalService.show(template, config);
    this.showOrganisationList = []
    this.isMobileDisabled = false;
    this.isEmailDisabled=false;

  }
  resetmodal() {
    this.modalRef.hide();
    this.formval.FirstName = '';
    this.formval.LastName = '';
    this.formval.Organisation = "0";
    this.formval.Email = '';
    this.formval.Mobile = '';
    this.formval.UserType = 0
    this.formval.Password = '';
    this.formval.cpassword = '';
    this.dnm = false;
    this.modalhead = "Add User";
    this.isEdit = true;
    this.numberedit = "";
    this.isDisabled = "false";
    this.formval.ShopName = '';
    this.formval.FactoryName = '';
    this.formval.HQName = '';
    this.buttonText = "Save";
    this.modalRef.hide();
  }
  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.pagesUser.page = event.page;
    this.getUsers(this.pagesUser);
    //  (this.users)
    this.currentPage = event.page + 1;
  }
  onpagechange() {
    this.pagesUser.page = 1;
    this.getUsers(this.pagesUser);
  }
  edituser(template: TemplateRef<any>, user: User) {
    debugger;
    console.log(user)
    this.showOrganisationList = this.organisationslist.filter((x)=> x.ID == user.Organisation)
    this.isEdit = false;
    //alert(this.user.firstName);
    this.modalhead = "Edit User"
    this.numberedit = "";
    this.isDisabled = "true";
    this.formval = { ...user };
    this.formval.cpassword = this.formval.Password;
    this.formval.City = 0;
    this.formval.State = 0;
    this.isMobileDisabled =true;
    this.isEmailDisabled=true;
    //this.formval.Organisation=this.organisationslist[]
    this.buttonText = "Update";
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    this.modalRef = this.modalService.show(template, config);
  }
  exportAsXLSX(myreport: any): void {
    this.excelService.exportAsExcelFile(this.reportExcelPassbook, 'User Master Report');
  }

  DownloadUserReport()
  {debugger;
    this.show = true;
    this.apiService.PostApi(this.pagesUser, "User/GetAllUser").subscribe((response: any) => {
      debugger;
       this.Userreportlistexcel = response.ResponseData.UserData;
       console.log(this.Userreportlistexcel)
       this.reportExcelPassbook = this.reportExcelPassbook.slice(0, 0);
       this.Userreportlistexcel.forEach(o => {
        this.reportExcelPassbook.push(
          { FirstName: o.FirstName,
            LastName: o.LastName,
            Mobile: o.Mobile,
            Email: o.Email, 
            UserType: o.UserTypeName,
            OrganizationName: o.OrganisationName,
            OrganizationType:o.NameType,
            FactoryName: o.FactoryName,
           Status:  o.IsActive});
     });
      this.exportAsXLSX(this.Userreportlistexcel);
     },
       err => {
         this.show = false;
         this.totaldata = 0;
      }
   )
     this.show = false;
   }

}

