//import { Component, OnInit } from '@angular/core';
import { Component, TemplateRef, OnInit } from '@angular/core';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { UserMasterResponse, User, hoUser, DepotsMst, TerritoryMst, GroupMst, DeptmntMst, DesigMst, ContractorMst, SalesMst, DealerContractorList, DealerCodeMst, DealercodeMst } from '../models/user';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/ngx-bootstrap-pagination';
import { ToastrService } from 'ngx-toastr';
import { NgForm, NgModel } from '@angular/forms';
import { Organisation, OrganisationResponse } from '../models/organisation';
import { DatePipe } from '@angular/common';
import { ExcelService } from '../excel.service';
import { NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import { ChipsModule } from 'primeng/chips';
import { splitAtColon } from '@angular/compiler/src/util';
@Component({
  selector: 'app-ho-user',
  templateUrl: './ho-user.component.html',
  styleUrls: ['./ho-user.component.css']
})
export class HoUserComponent implements OnInit {
  pagesUser: UserPage = new UserPage();
  users: any[] = [];
  DealerList:any[] = [];
  currentPage: any;
  pagesuserorganisation: UserPage = new UserPage();
  modalhead: string = "Add User";
  modalRef: BsModalRef;
  mobileReg: RegExp = /^[6-9]\d{9}$/;
  emailReg: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  isEdit: boolean = true;
  HOuserReportlistexcel: any[] = []
  reportHoExcel: any[] = []
  totaldata: any;
  isUsrGroupError: boolean = false
  isDepartmentError: boolean = false
  showTerritory: boolean = false;
  isDesignationReportError: boolean = false
  isDesignationError: boolean = false
  isMobileError: boolean = false
  isEmailError: boolean = false
  isJoinDateError: boolean = false
  formval: hoUser = new hoUser();
  ReqDt: hoUser = new hoUser();
  newJoinDate: Date;
  DesigsList: DesigMst[] = [];
  DeptmntsList: DeptmntMst[] = [];
  DealerCodeList: DealerCodeMst[] = [];
  GroupsList: GroupMst[] = [];
  ContractorList: ContractorMst[] = [];
  SalesList: SalesMst[] = [];
  DealerNameList: DealerCodeMst[] = [];
  DepotsList: DepotsMst[] = [];
  isCheckedYes: boolean = false;
  isCheckedNo: boolean = false;
  TerritoryList: TerritoryMst[] = [];
  selectedAll: boolean = false;
  isEditpassword: boolean = false;
  isNoBussinessLine: boolean = false;
  isNoDepot: boolean = false;
  isNoTerritory: boolean = false;
  selectedAllT: boolean = false;
  buttonText: string = "Save";
  submitForm: NgForm;
  dnm: boolean = false;
  bussinessCheck: any;
  show: boolean = false;
  BusinessLine: any = [{ BussinessId: 1, BusinessNm: 'Retail', BusinessCd: 'R', selected: false },
   { BussinessId: 2, BusinessNm: 'Industrial', BusinessCd: 'I', selected: false },
  { BussinessId: 3, BusinessNm: 'Projects', BusinessCd: 'P', selected: false }];
  showContractorTemp: any;
  salespersonlist: any;
  isSalesPersonId: boolean=false;
  isContractorId: boolean=false;
  isDealerId : boolean=false;
  selectedContractors: DealerContractorList[]=[] ;
  selectedSalesPerson: any[]=[];
  selectedDealercode: any[]=[];
  isUserSales:boolean=false;
  userType:number=0
  isterritoryData:boolean=false;
  DealerContractorSalespersonList:DealerContractorList[]=[];
 values2:any[]=[{key:'1', value:'one'}] ;
 key: string;
  value: string;
 isDisable:boolean=true;
 ContractorSelectedList:ContractorMst[]=[];
 issalesFieldDisabled:boolean=false;
  isContractorError: boolean=false;
  isSalesError:boolean=false;
  AdhaarCardPath:any;
  PANCardPath:any;
  isImageAvailable:boolean=true;
  showimage: any;
  ImageBase64: any
  url: any;
  showimage1: any;
  url1: any;
  IsActive: boolean;
  IsActive1: boolean;
  IsActive2: boolean;
  DealerCode: any;
  DealerName2: any;
  isDealerCodeDisabled: boolean = false;
  multivalue: boolean = false;
  ContractorSellerList:any;


  constructor(private excelService: ExcelService, private toastr: ToastrService, private apiService: ApiService, private modalService: BsModalService, private dt: DatePipe) {
    this.pagesUser.Filter = 'FirstName';
  }

  ngOnInit() {
    debugger;
    //this.formval.UsrGroup="0"
    this.getDepots();
    this.getDepartment(0);
    this.getGroup(0);
    this.getDesig(0);
    // this.getDealerCode(0);
    // this.getdealercodename(0)
    this.getDealerCodeUser(this.pagesUser);
    this.getContractor(0);
    this.getSales(0);
    this.getUsers(this.pagesUser);
    this.getDealercode1(this.pagesUser)
  }

  onUserGroupSelect(event:any) {
  debugger;
    console.log(this.formval.UsrGroup)
    console.log(this.GroupsList)
    //this.showContractorTemp = JSON.parse(JSON.stringify(this.ContractorList));
    if (this.formval.UsrGroup == "1") {

      this.isUserSales=true;
      this.SalesList = null;
      this.isDealerId=false;
      this.isContractorId=false;
      this.isSalesPersonId=false;
      this.formval.ContratorList=null;
      this.formval.SalesList=null;
      this.formval.selectedSalesPerson=null;
      this.selectedContractors=null;
      this.isterritoryData=true
      this.isDisable=false;
      this.isSalesError = false;
    }
    else if (this.formval.UsrGroup == "2") {
      this.userType=2;
      this.isContractorId=true;
      this.isDealerId=false;
      this.formval.ContratorList=null;
      this.formval.SalesList=null;
      this.isUserSales=false;
      this.isNoTerritory=false;
      this.selectedSalesPerson=null;
      this.formval.selectedSalesPerson=null;
      this.isterritoryData=false;
      this.getSales(0);
      this.isDisable=true;
      this.issalesFieldDisabled=false;
      this.isDepartmentError = false;
    }
    else if(this.formval.UsrGroup == "3"){
      this.userType=3;
      this.SalesList = null;
      this.isContractorId=true;
      this.isDealerId=true;
      this.isSalesPersonId=true;
      this.formval.SalesList=null;
      this.formval.ContratorList;
      this.isUserSales=false;
      this.formval.selectedSalesPerson=null;
      this.selectedSalesPerson=null;
      this.selectedContractors=null;
      this.isterritoryData=false;
      this.isDisable=true;
      this.issalesFieldDisabled=true;
      this.isSalesError = false;
      this.isDepartmentError = false;
      this.getSales(0);
      this.selectedContractors[0].ContractorId=0;
    }
  }

  toDataURL = async (AdhaarCardPath) => {
    debugger
    console.log("Downloading image...");
    var res = await fetch(AdhaarCardPath);
    var blob = await res.blob();

    const result = await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })

    return result
  };
  //AdhaarCardPath = await this.toDataURL(imageSrc)

  base64textString = [];

onUploadChange(evt: any) {
  debugger;
  const file = evt.target.files[0];

  if (file) {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      this.ImageBase64 = fileReader.result;
      this.showimage = this.ImageBase64
      //this.isImageSelected = true

    };
  }
    // reader.onload = this.handleReaderLoaded.bind(this);
    // reader.readAsBinaryString(file);

}

handleReaderLoaded(e) {
  debugger
  this.showimage = ('data:image/png;base64,' + btoa(e.target.result));


}
  onAdhaarUpload(event: any) {
    debugger;
    var fileReader = new FileReader();
    var mimeType = event.target.files[0].type;
    var path=(event.target.result)
    if (mimeType.match(/image\/*/) == null) {
      debugger
      this.formval.AdhaarCardPath;
      //  this.ngForm.patchValue({
      //   AdhaarCardPath: ''
      // })
      this.toastr.warning('Only images are supported.');
      this.ImageBase64='';
      this.formval.AdhaarCardPath=undefined;
      return;
    }
    else if (event.target.files[0].size > 2500000) {
      this.formval.AdhaarCardPath;
      // this.formval.patchValue({
      //   AdhaarCardPath: ''
      // })
      this.toastr.error('', 'Image size is more than 2.5 MB Please reduce or select other');
      // this.tstr.error('', 'imagen mayor a 2.5MB por favor reducir resolucion ')
      this.ImageBase64='';
      this.formval.AdhaarCardPath=undefined;
      return;
    }
    else {
      fileReader.readAsDataURL(event.target.files[0]);
      // console.log(event.target.files[0])
      // console.log(fileReader.result)
       fileReader.onload = () => {
        this.ImageBase64 = fileReader.result;
        this.showimage = this.ImageBase64 ;
        this.url=this.ImageBase64.split(',')[1].trim();
        //var result= this.ImageBase64.split(',')[1].trim();
console.log(this.url);
        // this.isImageSelected = true

      };
    }
  }
  onPANUpload(event: any) {
    debugger;
    var fileReader = new FileReader();
    var mimeType = event.target.files[0].type;
    var path=(event.target.result)
    if (mimeType.match(/image\/*/) == null) {
      debugger
      this.formval.PANCardPath;
      //  this.ngForm.patchValue({
      //   AdhaarCardPath: ''
      // })
      this.toastr.warning('Only images are supported.');
      this.ImageBase64='';
      this.formval.PANCardPath=undefined;
      return;
    }
    else if (event.target.files[0].size > 2500000) {
      this.formval.PANCardPath;
      // this.formval.patchValue({
      //   AdhaarCardPath: ''
      // })
      this.toastr.error('', 'Image size is more than 2.5 MB Please reduce or select other');
      // this.tstr.error('', 'imagen mayor a 2.5MB por favor reducir resolucion ')
      this.ImageBase64='';
      this.formval.PANCardPath=undefined;
      return;
    }
    else {
      fileReader.readAsDataURL(event.target.files[0]);
      // console.log(event.target.files[0])
      // console.log(fileReader.result)
       fileReader.onload = () => {
        this.ImageBase64 = fileReader.result;
        this.showimage1 = this.ImageBase64 ;
        this.url1=this.ImageBase64.split(',')[1].trim();
        //var result= this.ImageBase64.split(',')[1].trim();
console.log(this.url1);
        // this.isImageSelected = true

      };
    }
  }
  // handleDocumentFileInput(event: any) {
  //   debugger
  //   var reader = new FileReader();
  //   if (event.target.files[0].size > 2500000) {
  //     this.PANCardPath = null
  //     this.toastr.error('', 'Image size is more than 2.5 MB Please reduce or select other');
  //    this.toastr.error('', 'File size is more than 2.5 MB Please reduce or select other')
  //     return;
  //   }
  //   else {
  //     this.PANCardPath = event.target.files[0];
  //     reader.readAsDataURL(event.target.files[0]);
  //     reader.onload = (event: any) => {
  //       this.DocumentFileUrl = event.target.result;
  //     }
  //   }
  // }
  /////  CR-02 BP-111 (Issue Key) 25-March-2021 Done by Ayush
  DownloadHOUserReport() {
    debugger
    this.show=true;
    if (this.users != null) {
      this.pagesUser.UserType = 10;
      this.apiService.PostApi(this.pagesUser, "HOUser/GetAllUser").subscribe((res: any) => {
        debugger
        this.HOuserReportlistexcel = res.ResponseData;
        console.log(this.HOuserReportlistexcel)
       this.reportHoExcel = this.reportHoExcel.slice(0, 0);
        this.HOuserReportlistexcel.forEach(o => {
          this.reportHoExcel.push(
            {
              Employee_ID: o.EmpId,
              FirstName: o.FirstName,
              LastName: o.LastName,
              Mobile: o.Mobile,
              UserGroup: o.GroupNm,
              EmailID: o.Email,
              AdhaarNumber:o.AdhaarNo,
              PANCardNumber:o.PANCardNo,
              Status: o.IsActive,
              UniqueCode: o.UniqueCode,
              DealerName: o.DealerName
            }
          )
        });
        this.exportAsXLSX(this.HOuserReportlistexcel)
      },
      err => {
        this.show = false;
        this.totaldata = 0;
     }
      )
    }
    this.show = false;
  }
  exportAsXLSX(HOuserReportlistexcel:any):void {
    this.excelService.exportAsExcelFile(this.reportHoExcel, 'Ho User Report')
  }
onSelect(){

}

  onSubmit(f: NgForm) {
  debugger

    var dt = f.value;
    if (dt.GroupCd=="0" || dt.GroupCd==undefined || dt.GroupCd==" " )
     {
      dt.isUsrGroupError=true
      this.toastr.warning('Please fill all required details', 'Warning!', {
        timeOut: 1000,
      });
      return;
    }
    if (dt.cpassword != dt.password) {
      this.dnm = true;
      return;
    }
    else {
      this.dnm = false;
    }
    // if (this.formval.IsActive1 || this.formval.IsActive2 || this.formval.IsActive) {
      if (this.formval.IsActive1 == true) {
        dt.IsActive = true;
      }
      else if (this.formval.IsActive2 == true) {
        dt.IsActive = false;
      }

    else {
      dt.IsActive = this.formval.IsActive;
    }
    // if((dt.firstname == '' || dt.firstname == undefined || dt.firstname == null))

    if(dt.GroupCd == "1"){
      if (dt.GroupCd == '' || dt.GroupCd == null || dt.GroupCd == undefined) {
        this.isUsrGroupError = true
      }
      // if (dt.DeptId == '' || dt.DeptId == null || dt.DeptId == undefined) {
      //   this.isDepartmentError = true
      // }
      // if (dt.GroupCd == '' || dt.GroupCd == null || dt.GroupCd == undefined) {
      //   this.isUsrGroupError = true
      // } if (dt.UsrDesigRpt == '' || dt.UsrDesigRpt == null || dt.UsrDesigRpt == undefined) {
      //   this.isDesignationReportError = true
      // }
      // if (dt.DesigCd == '' || dt.DesigCd == null || dt.DesigCd == undefined) {
      //   this.isDesignationError = true
      // }
      if (dt.fromdate == '' || dt.fromdate == null || dt.fromdate == undefined) {
        this.isJoinDateError = true
      }
      // if (dt.Email == '' || dt.Email == null || dt.Email == undefined) {
      //   this.isEmailError = true
      // }
      if(dt.firstname == "" || dt.firstname==undefined || dt.Email=="" || dt.Email==undefined ||
      dt.fromdate=="" || dt.fromdate==undefined ||
      dt.AdhaarNo == "" || dt.AdhaarNo == undefined || dt.AdhaarNo ==null ||
      dt.PANCardNo == "" || dt.PANCardNo == undefined || dt.PANCardNo ==null
      ||
      dt.AdhaarCardPath == undefined ||
       dt.PANCardPath == undefined
      ){
        this.toastr.warning('Please fill all required details', 'Warning!', {
          timeOut: 1000,
        });
        return;
      }
      // this.toastr.warning('Please fill all required details', 'Warning!', {
      //   timeOut: 1000,
      // });

    }
    else if (dt.GroupCd == "2" ){
      debugger
      if(dt.firstname == "" || dt.firstname==undefined || dt.firstname == null ||
     dt.Email=="" || dt.Email==undefined || dt.Email==null ||
     dt.Mobile=="" || dt.Mobile==undefined|| dt.Mobile==null ||
     dt.fromdate=="" || dt.fromdate==undefined || dt.fromdate==null ||
     dt.AdhaarNo == "" || dt.AdhaarNo == undefined || dt.AdhaarNo ==null ||
     dt.PANCardNo == "" || dt.PANCardNo == undefined || dt.PANCardNo ==null
     ||
      dt.AdhaarCardPath == undefined ||
       dt.PANCardPath == undefined
     //|| dt.selectedSalesPerson=="" || dt.selectedSalesPerson==null || dt.selectedSalesPerson=="0"
     ) {
      this.isSalesError = true;
      this.toastr.warning('Please fill all required details', 'Warning!', {
        timeOut: 1000,
      });
      return;
     }
      if(dt.selectedSalesPerson=="" || dt.selectedSalesPerson==null || dt.selectedSalesPerson=="0")
      {
          this.toastr.warning('Please select Sales Person', 'Warning!', {
            timeOut: 3000,

          });
          this.isSalesError = true;
          return;
      } }



    else if ( dt.GroupCd == "3"){
      debugger
      if(dt.firstname == "" || dt.firstname==undefined || dt.firstname == null ||
     dt.Email=="" || dt.Email==undefined || dt.Email==null ||
     dt.Mobile=="" || dt.Mobile==undefined|| dt.Mobile==null ||
     dt.fromdate=="" || dt.fromdate==undefined ||dt.fromdate==null ||
     dt.AdhaarNo == "" || dt.AdhaarNo == undefined || dt.AdhaarNo ==null ||
     dt.PANCardNo == "" || dt.PANCardNo == undefined || dt.PANCardNo ==null
     ||
      dt.AdhaarCardPath == undefined ||
       dt.PANCardPath == undefined || this.formval.selectedDealercode == ""|| this.formval.selectedDealercode == undefined || this.formval.selectedDealercode == null||
       this.formval.DealerName ==""||this.formval.DealerName == undefined || this.formval.DealerName == null
     //||reqDt.DealerContractorSalespersonList == null
     ) {
      // this.isContractorError = true;
      this.toastr.warning('Please fill all required details', 'Warning!', {
        timeOut: 1000,
      });
      return;
     }
     if ( this.formval.selectedSalesPerson==null || this.formval.selectedSalesPerson== undefined) {
      // this.isContractorError = true;
      {this.toastr.warning('Please select SalesPerson', 'Error!', {
          timeOut: 3000,
        });
        return;
    }}

      else {
        // this.isContractorError = false;
      }
     }
//const abc = JSON.parse( this.selectedSalesPerson[0])
debugger;
var reqDt = {
      ID: (dt.ID == undefined ? this.formval.ID : dt.ID),
      FirstName: dt.firstname,
      LastName: dt.lastname,
      UsrGroup: dt.GroupCd,
      UsrDeptmnt: dt.DeptId,
      DealerCode: this.formval.DealerCode,
      DealerName: this.formval.DealerName,
      UsrDesigForRpt: dt.UsrDesigRpt,
      EmpId: dt.employeeID,
      Email: dt.Email,
      Mobile: dt.Mobile,
      Password: (dt.password == undefined ? 'EDIT' : dt.password),
      UsrDesig: dt.DesigCd,
      JoinDt: dt.fromdate,
      IsActive: dt.IsActive,
      CreatedBy: '1',
      AdhaarNo: dt.AdhaarNo,
      PANCardNo:dt.PANCardNo,
      AdhaarCardPath:this.url,
      PANCardPath:this.url1,
      // Account:dt.Account,
      BusinessList: this.BusinessLine.filter(x => x.selected === true),
      DepotsList: this.DepotsList.filter(x => x.selected === true),
      TerritoryList: (this.TerritoryList == null ? null : this.TerritoryList.filter(x => x.selected === true)),
      //SalesList: this.SalesList.filter(x => x.selected === true),
      
      ContractorSellerList: (this.formval.selectedSalesPerson != null && this.formval.UsrGroup == "2"?  this.formval.selectedSalesPerson : null),
      DealerContractorSalespersonList:(this.selectedContractors== null||this.selectedContractors[0].ContractorId == 0 && this.formval.UsrGroup == "3"? this.formval.selectedSalesPerson : this.selectedContractors),
      
      // DealerContractorSalespersonList:this.formval.selectedSalesPerson,
      //(this.DealerContractorSalespersonList == null ? null : this.DealerContractorSalespersonList.filter(x => x.selected === true))

    }
   if (dt.GroupCd == "1"){

    var checkDisabled = false
    if (this.isUsrGroupError == true || this.isDepartmentError == true || this.isDesignationReportError == true || this.isDesignationError == true ||
      this.isJoinDateError == true) {
      checkDisabled = true
    }
    if (reqDt.BusinessList.length < 1) {
      this.isNoBussinessLine = true;
      return;
    }
    else {
      this.isNoBussinessLine = false;
    }
    if (reqDt.DepotsList.length < 1) {
      this.isNoDepot = true;
      return;
    }
    else {
      this.isNoDepot = false;
    }
    if (reqDt.TerritoryList.length < 1 || checkDisabled == true) {
      this.isNoTerritory = true;
      return;
    }
    else {
      this.isNoTerritory = false;
    }
   }

   if(dt.GroupCd == "2")
   {
    if (reqDt.ContractorSellerList.length < 1) {
      this.isSalesPersonId = false;
      this.toastr.warning('Please select Sales Person', 'Warning!', {
        timeOut: 3000,
      });

    }
    else {
      this.isSalesPersonId = true;
    }
   }

   if(dt.GroupCd == "3"){
    debugger
    // if (reqDt.DealerContractorSalespersonList == null) {
    //   this.isContractorError = true;
    //   {this.toastr.warning('Please select Contractor', 'Warning!', {
    //       timeOut: 3000,
    //     });
    //     return;
    // }}
    // else {
    //   this.isContractorError = false;
    // }
   }
    this.show = true;
    debugger;
    this.apiService.PostApi(reqDt, "HOUser/SaveHOUser").subscribe((response: UserMasterResponse) => {
    debugger;
      if (response.ResponseCode == "200") {
        this.getUsers(this.pagesUser);
        this.resetmodal();
        this.show = false;

        this.toastr.success(response.ResponseMessage, 'Success!', {
          timeOut: 2000
        });
      }
      else {
        this.toastr.warning(response.ResponseMessage, 'Warning!', {
          timeOut: 2000,
        });

        this.show = false;
      }
    }, err => {
      this.toastr.warning(err.error.ResponseMessage, 'Error!', {
        timeOut: 2000
      });

      this.show = false;
    });
  }
  radioChange(value) {
    debugger
    if (value == 'Yes') {
      this.formval.IsActive1 = true;
      this.formval.IsActive2 = false
    }
    else if (value == 'No') {
      this.formval.IsActive2 = true;
      this.formval.IsActive1 = false
    }
  }
  onpagechange() {
    debugger;
    this.pagesUser.page = 1;
    this.getUsers(this.pagesUser);
  }
  getUsers(user: UserPage): any {
    debugger;

    this.show = true;
    this.pagesUser.UserType = 10;
    this.pagesUser.IsActive = '%';
    this.pagesUser.FilterBY = '';
    this.apiService.PostApi(this.pagesUser, "HOUser/GetAllUser").subscribe((response: any) => {
    debugger
      if (response.ResponseCode == "200") {
        this.users = response.ResponseData;
        this.totaldata = response.ResponseData[0].TotalCount;
        if (this.totaldata < parseInt(this.pagesUser.pageSize)) {
          this.currentPage = 1;
        }
      }
      else {
        this.users = null;
        this.show = false;
      }
    }, err => {
      //this.users = null;
      this.show = false;
    });
    this.show = false;
  }
  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.pagesUser.page = event.page;
    this.getUsers(this.pagesUser);
    this.currentPage = event.page + 1;
  }
  getDepots(): any {

    this.show = true;

    this.pagesUser.UserType = 1;
    if (this.DepotsList != null) {

      this.DepotsList = this.DepotsList.slice(0, 0);
    }

    this.apiService.GetApi(this.pagesUser, "HOUser/GetAllDepot").subscribe((response: any) => {
      if (response.ResponseCode == "200") {
        this.DepotsList = response.ResponseData;
      }
      else {
        this.DepotsList = null;

        this.show = false;
      }
    }, err => {
      this.DepotsList = null;

      this.show = false;
    });

    this.show = false;
  }
  //  fixUTCDate(date): any {
  //   if (date) {
  //     date.setTime(new Date(new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000).toUTCString()));
  //     return date;
  //   }
  //   return null;
  // }
  getTerritory(): any {
    debugger;
    this.show = true;
    if (this.DepotsList == null)
      return;
    this.apiService.PostApi(this.DepotsList.filter(x => x.selected === true), "HOUser/GetAllTerritory").subscribe((response: any) => {
      if (response.ResponseCode == "200") {
        this.TerritoryList = response.ResponseData;
        this.showTerritory = true
      }
      else {
        this.TerritoryList = null;
        this.show = false;
        this.showTerritory = false
      }
    }, err => {
      this.TerritoryList = null;
      this.showTerritory = false
      this.show = false;
    });

    this.show = false;
  }
  getSales(UserId: number): any {
  debugger;
    this.show = true;

    this.apiService.GetApi(UserId, "HOUser/GetSalespersonForDdl?UserId=" + UserId).subscribe((response: any) => {
      if (response.ResponseCode == "200") {
        this.SalesList = response.ResponseData;
      }
      else {
        this.SalesList = null;

        this.show = false;
      }
    }, err => {
      this.SalesList = null;

      this.show = false;
    });

    this.show = false;
  }

  getSalesByContractor(ContractorId: any): any {
    debugger;
    // const arr =[];
    // let list:string[]=[];
    // this.values2=["Todo1","Todo2","Todo3"];

    // ContractorId.forEach(o => {
    //   list.push(o.SalesPersonName)
    // });
    // this.values2=list;
    var obj = new ContractorMst();
    obj.ContractorId=this.selectedContractors.map(x => x.ContractorId).join(',');
    this.userType=0;
    this.show = true;
    this.apiService.PostApi(obj, `HOUser/GetSalesByContr`).subscribe((response: any) => {
      debugger;
    // this.apiService.getApi( "HOUser/GetSalesByContractId?ContractorId=" + ContractorId).subscribe((response: any) => {

      if (response.ResponseCode == 200) {
        this.formval.selectedSalesPerson = response.ResponseData;
        this.multivalue =true;
        //this.selectedSalesPerson[0].SalesPersonName
      }
      else {
        this.formval.selectedSalesPerson= null;
        this.multivalue =false;
        this.getSales(0);


        this.show = false;
      }
    }, err => {
      this.SalesList = null;

      this.show = false;
    });

    this.show = false;
  }

// get(ContractorId){
//   this.apiService.PostApi(ContractorId, `HOUser/GetHoUserForExcel`).subscribe((res: any) => {
//     this.HOuserReportlistexcel = res.ResponseData
//     debugger
//   })
// }

getDealernameoncode(DealerCode: any): any {
  debugger;
  // var obj = DealerCode.value
  var obj = DealerCode.value.DealerCode    //for p-dropdown
  this.apiService.GetApi(obj, "HOUser/GetDealernameweb?dealercode=" + obj).subscribe((response: any) => {
    debugger;
    if (response.ResponseCode == "200") {
      this.DealerNameList = response.ResponseData;
      this.formval.DealerName = response.ResponseData[0].DealerName;
      this.formval.DealerCode = response.ResponseData[0].DealerCode;
    }
    else {
      this.DealerNameList = null;
    }
  }, err => {
    this.DealerNameList = null;
  });
}

// getDealernameoncode(DealerCode: any): any {
//   debugger;
//   var obj = new DealerCodeMst();
//     obj.DealerCode=this.DealerList.filter(x => x.DealerCode).join(',');
//     this.show = true;
//   this.apiService.PostApi(obj, `HOUser/GetDealername`).subscribe((response: any) => {
//     debugger;
//     if (response.ResponseCode == "200") {
//       this.DealerNameList = response.ResponseData;
//     }
//     else {
//       this.DealerNameList = null;

//       this.show = false;
//     }
//   }, err => {
//     this.DealerNameList = null;

//     this.show = false;
//   });

//   this.show = false;
// }

  getContractor(UserId: number): any {

    this.show = true;

    this.apiService.GetApi(UserId, "HOUser/GetContractorForDdl?UserId=" + UserId).subscribe((response: any) => {
      debugger;
      if (response.ResponseCode == "200") {
        this.ContractorList = response.ResponseData;
      }
      else {
        this.ContractorList = null;

        this.show = false;
      }
    }, err => {
      this.ContractorList = null;

      this.show = false;
    });

    this.show = false;
  }
  getDealerCodeUser(UserId)
  {
   debugger;
      this.apiService.getApi("HOUser/GetDealerCodeUser?userid=" + UserId).subscribe((response: any) => {
        debugger;
        if (response.ResponseCode == "200") {
          this.DealerCodeList=response.ResponseData;
          this.DealerCode = response.ResponseData.DealerCode
        }
        else {
          this.DealerCodeList = null;

          this.show = false;
        }
      },
       err => {
        // this.ContractorList = null;

        // this.show = false;
      });
    }
    getDealercode1(user: UserPage): any {
      debugger;

      this.show = true;
      this.pagesUser.searchValue = '';
      this.apiService.PostApi(this.pagesUser, "HOUser/GetDealerCodeonweb").subscribe((response: any) => {
      debugger
        if (response.ResponseCode == "200") {
          this.DealerList = response.ResponseData;
        }
        else {
          this.DealerList = null;
          this.show = false;
        }
      }, err => {
        //this.users = null;
        this.show = false;
      });
      this.show = false;
    }
  getGroup(GroupId: number): any {
    this.show = true;
debugger
    this.apiService.getApi( "Other/GetAllGroup?GroupId=" + GroupId).subscribe((response: any) => {
      if (response.ResponseCode == "200") {
        this.GroupsList = response.ResponseData;
      }
      else {
        this.GroupsList = null;

        this.show = false;
      }
    }, err => {
      this.GroupsList = null;

      this.show = false;
    });

    this.show = false;
  }
  getDepartment(DeptId: number): any {
    this.apiService.GetApi(DeptId, "Other/GetAllDepart?DeptId=" + DeptId).subscribe((response: any) => {
      if (response.ResponseCode == "200") {
        this.DeptmntsList = response.ResponseData;
      }
      else {
        this.DeptmntsList = null;
      }
    }, err => {
      this.DeptmntsList = null;
    });
  }
  getDealerCode(DealerCodeId: number): any {
    debugger;
    this.apiService.GetApi(DealerCodeId, "HOUser/GetDealerCodeweb?DealerCodeId=" +DealerCodeId).subscribe((response: any) => {
      if (response.ResponseCode == "200") {
        this.DealerCodeList = response.ResponseData;
      }
      else {
        this.DealerCodeList = null;
      }
    }, err => {
      this.DealerCodeList = null;
    });
  }
  getDesig(DesigId: number): any {
    debugger;
    this.apiService.GetApi(DesigId, "Other/GetAllDesig?DesigId=" + DesigId).subscribe((response: any) => {
      if (response.ResponseCode == "200") {
        this.DesigsList = response.ResponseData;
      }
      else {
        this.DesigsList = null;
      }
    }, err => {
      this.DesigsList = null;
    });
  }
  resetmodal() {
    debugger;
    this.modalhead = "Add User";
    this.dnm = false;
    this.formval.UsrGroup ="0";
    this.selectedContractors=null;
    this.selectedSalesPerson=null;
    // this.selectedDealercode=null;
    this.SalesList=null;
    this.ContractorList=null;
    this.isDealerId=false;
    this.isSalesPersonId=false;
    this.isContractorId=false;
    this.isNoBussinessLine = false;
    this.isNoDepot = false;
    this.isNoTerritory = false;
    this.isEdit = true;
    this.formval.EmpId = '';
    this.formval.UsrGroup = '';
    this.formval.UsrDeptmnt = '';
    this.formval.UsrDesigForRpt = '';
    this.formval.UsrDesig = '';
    this.formval.FirstName = '';
    this.formval.LastName = '';
    this.formval.DealerCode = '';
    this.formval.selectedDealercode ='';
    this.formval.DealerName = '';
    this.formval.Email = '';
    this.formval.Mobile = '';
    this.formval.fromdate = null;
    this.formval.Password = '';
    this.formval.cpassword = '';
    this.formval.AdhaarNo='';
    this.formval.PANCardNo='';
    this.formval.AdhaarCardPath='';
    this.formval.PANCardPath='';
    this.url='';
    this.url1='';
    this.showimage='';
    this.showimage1='';
    this.formval.IsActive1 = true;
    this.formval.IsActive2 = false;
    this.formval.IsActive=true;
    this.selectedAll = false;
    this.selectedAllT = false;
    this.isUsrGroupError = false
    this.isDepartmentError = false
    this.isDesignationReportError = false
    this.isDesignationError = false
    this.isJoinDateError = false
    this.isterritoryData=false;
    this.isSalesError = false;
    this.multivalue = false;

    this.getDepots();
    this.getTerritory();
    this.getContractor(0);
    this.getSales(0)


    this.buttonText = "Save";
    //let arr = Array(3).fill({}) // [{}, {}, {}]
    //arr[0].hi = "hi"
    //this.DepotsList.fill().selected = false;
    this.modalRef.hide();
  }

  selectAll() {
    for (var i = 0; i < this.DepotsList.length; i++) {
      this.DepotsList[i].selected = this.selectedAll;
    }
  }
  checkIfAllSelected() {
    this.selectedAll = this.DepotsList.every(function (item: any) {
      return item.selected == true;
    })
  }

  selectAllT() {
    for (var i = 0; i < this.TerritoryList.length; i++) {
      this.TerritoryList[i].selected = this.selectedAllT;
    }
  }
  checkIfAllSelectedT() {
    this.selectedAllT = this.TerritoryList.every(function (item: any) {
      return item.selected == true;
    })
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  alphaOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }
  spaceNotAllowed(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;

    if (charCode == 32) {
      return false;
    }
    return true;
  }
  getDealerData(UserId)
  {
   debugger;
      this.apiService.getApi("HOUser/GetDealerData?UserId=" + UserId).subscribe((response: any) => {
        debugger;
        if (response.ResponseCode == 200) {
          this.DealerContractorSalespersonList=response.ResponseData;
          this.selectedContractors = response.ResponseData;
          this.formval.selectedSalesPerson = response.ResponseData;
          if(response.ResponseData[0].ContractorId !=0)
          {
            this.multivalue = true;
          }
          else
          {
            this.multivalue = false;
          }
        }
        else {
          this.ContractorList = null;

          this.show = false;
        }
      },
       err => {
        // this.ContractorList = null;

        // this.show = false;
      });
    }
  getTerritoryBuissnessData(userId) {
    debugger;
    this.apiService.GetApi(userId, 'HOUser/GetDtlsHoUsr?UsrId=' + userId).subscribe((resp: any) => {
      debugger
      if (resp.BussinessList.length > 0) {
        for (var i = 0; i < resp.BussinessList.length; i++) {
          if (resp.BussinessList[i].BussinessId == 1 || resp.BussinessList[i].BussinessId == 2 || resp.BussinessList[i].BussinessId == 3) {
            this.BusinessLine[resp.BussinessList[i].BussinessId - 1].selected = true;
          }
        }
      }
      if (resp.DepotList.length > 0) {
        for (var i = 0; i < this.DepotsList.length; i++) {
          for (var j = 0; j < resp.DepotList.length; j++) {
            if (this.DepotsList[i].DepotId == resp.DepotList[j].DepotId) {
              this.DepotsList[i].selected = true;
            }
          }
        }
      }
      if (resp.TrtryList.length > 0) {
        this.TerritoryList = JSON.parse(JSON.stringify(resp.TerritoryMst));
        var checkMatch = 0;
        for (var i = 0; i < this.TerritoryList.length; i++) {
          checkMatch = 0;
          for (var j = 0; j < resp.TrtryList.length; j++) {
            if (this.TerritoryList[i].TrtryId == resp.TrtryList[j].TrtryId) {
              checkMatch = 1;
              this.TerritoryList[i].selected = true;
              break;
            }
          }
          if (checkMatch == 0) {
            this.TerritoryList[i].selected = false;
          }
        }
      }
      if (resp.SalesList.length > 0) {
        for (var i = 0; i < this.SalesList.length; i++) {
          for (var j = 0; j < resp.SalesList.length; j++) {
            if (this.SalesList[i].UsrId == resp.SalesList[j].SalesId) {
              this.SalesList[i].selected = true;
            }
          }
        }
      }

      if (resp.ContractorList.length > 0) {
        for (var i = 0; i < this.ContractorList.length; i++) {
          for (var j = 0; j < resp.ContractorList.length; j++) {
            if (this.ContractorList[i].ContractorId == resp.ContractorList[j].ContractorId) {
              this.ContractorList[i].selected = true;
            }
          }
        }
      }
    })
  }
  edituser(template, user) {
    debugger
    if (user.UsrGroup == "1") {

      this.isUserSales=true;
      this.SalesList = null;
      this.isDealerId=false;
      this.isContractorId=false;
      this.isSalesPersonId=false;
      this.formval.ContratorList=null;
      this.formval.SalesList=null;
      this.formval.selectedSalesPerson=null;
      this.selectedContractors=null;
      this.isterritoryData=true
      this.isDisable=false;
      this.issalesFieldDisabled=false;
    }
    else if (user.UsrGroup == "2") {
      debugger;
      this.userType=2;
      this.isContractorId=true;
      this.isSalesPersonId=true;
      this.isDealerId=false;
      this.isterritoryData=false;
      this.issalesFieldDisabled=false;
      var SalesSelected  = this.SalesList.filter((x)=> x.SalesId == user.SalesId)
    }

   else if(user.UsrGroup == "3"){
     this.getDealerData(user.ID)
     this.getDealercode1(user.ID)
      this.userType=3;
      this.isContractorId=true;
      this.isDealerId=true;
      this.isSalesPersonId=true;
      this.isterritoryData=false;
      this.issalesFieldDisabled=true;
      // this.isDealerCodeDisabled = true
    }

    this.showTerritory = true
    for (var i = 0; i < this.DepotsList.length; i++) {
      this.DepotsList[i].selected = false;
    }
    for (var i = 0; i < this.BusinessLine.length; i++) {
      this.BusinessLine[i].selected = false;
    }
    this.modalhead = "Edit User"
    this.isEditpassword = true;
    if (user.IsActive == true) {
      this.isCheckedYes = true;
      this.isCheckedNo = false;
      this.formval.IsActive1 = true;
      this.formval.IsActive2 = false
    }
    else if (user.IsActive == false) {
      this.isCheckedNo = true;
      this.isCheckedYes = false;
      this.formval.IsActive1 = false
      this.formval.IsActive2 = true
    }
    // user.JoinDt=this.dt.transform(user.JoinDt, 'MM/dd/yyyy');
    var ut = Object.values(user)[0];
    this.formval={...user};
    debugger;
    if (Object.values(user)[0] == '2') {
      this.formval.UsrGroup = "2"
    }
    var dateFrom = this.dt.transform(user.JoinDt, 'E LLL dd y 00:00:00 zzzz');
    var dateFromSplitted = dateFrom.split('+');
    var dateConcat = dateFromSplitted[0] + '+0530 (India Standard Time)';
    var dateTransform = new Date(this.dt.transform(user.JoinDt, 'MM/dd/yyyy'));
    this.formval ={...user};
    this.isImageAvailable=true;
    this.showimage=user.AdhaarCardPath;
    this.showimage1=user.PANCardPath;
    this.formval.ID = user.ID;
    this.formval.FirstName = user.FirstName;
    this.formval.DealerCode = user.UniqueCode;
    this.formval.DealerName = user.DealerName;
    this.formval.DeptId=user.DeptId;
    this.formval.EmpId = user.EmpId
    this.formval.LastName = user.LastName;
    this.formval.UsrGroup = user.UsrGroup;
    this.formval.UsrDeptmnt = user.UsrDeptmnt;
    this.formval.UsrDesigForRpt = user.UsrDesigForRpt;
    this.formval.Email = user.Email;
    this.formval.Mobile = user.Mobile;
    this.formval.UsrDesig = user.UsrDesig;
    this.formval.fromdate = new Date(dateConcat);
    this.formval.IsActive = user.IsActive;
    this.formval.cpassword = user.Password;
    this.formval.Password = user.Password;
    this.formval.AdhaarNo=user.AdhaarNo;
    this.formval.PANCardNo=user.PANCardNo;
    this.formval.AdhaarCardPath=user.AdhaarCardPath;
    this.formval.PANCardPath=user.PANCardPath;
    this.formval.selectedSalesPerson = user.SalesId;
    this.formval.selectedDealercode = user.UniqueCode;
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    this.modalRef = this.modalService.show(template, config);
    this.getTerritoryBuissnessData(user.ID);
    // this.getSalesByContractor(user.ID)
    // this.getSales(user.ID);

  }

  openModal(template: TemplateRef<any>) {
    debugger
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    this.formval.IsActive = true;
    this.formval.UsrDeptmnt="0";
    this.formval.UsrDesig="0";
    this.formval.UsrGroup="0";
    this.formval.UsrDesigForRpt="0";
    this.formval.ID = null;
    this.isCheckedYes = true;
    this.isCheckedNo =false;
    this.formval.IsActive1 = true;
    this.formval.IsActive2 = false;
    this.formval.selectedDealercode =""
    this.isDealerCodeDisabled = false;
    for (var i = 0; i < this.BusinessLine.length; i++) {
      this.BusinessLine[i].selected = false;
    }
    this.isEditpassword = false;
    this.modalRef = this.modalService.show(template, config);
  }
}
