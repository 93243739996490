
export class FeedbackReport
{
PageNumber:number;
PageSize:number;
hoUserId:number;
Search:string;
FromDate:string;
ToDate:string;

ProductCode:string;
Name:string;
UserMobile:string;
ScanLocation:string;
FeedbackDate:string;
HologramNo:string;
TotalRows:number;
RowNum:number;
Rating:string;
Feedback:string;

}

