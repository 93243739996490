export class Product
{
    ID:number;
    ProductID:number;
    Description	:string	;
    ProductCategory:any;
    ProductSubCategory:any;
    ProductSize:any;
    IsActive:boolean=true;
    Reason:string;
    CreatedBy:number;
    CreatedDate	: string;
    Category:string;
    ProductName: string;
    TotalCount:number
    ProductCode: string;
    SubCategory: string;
   UOM: string;

}

export class ProductResponse
{
    ResponseCode: string;
    ResponseMessage: string;
    ResponseData:ProductData;
}

export class ProductData
{

    ProductData:Product[];
    TotalCount:number;

}


export class ProductMasterResponse
{
    ResponseCode: string;
    ResponseMessage: string;
    ResponseData:ProductData;
}

export class tokenStockExcel{
    StockOrganisation:string;
    Denomination:string;
    FromNumber:string;
    ToNumber:string;
    Quantity:string;
    AccurateStatus:string;
    CreatedDate:string;
}

export class tokenStockExcelReport{
    StockOrganisation:string;
    Denomination:string;
    FromNumber:string;
    ToNumber:string;
    Quantity:string;
    AccurateStatus:string
    CreatedDate:string;
}

export class AvailableStockExcel{

}

export class AvailableStockExcelReport{

}
