import { Component, OnInit, TemplateRef } from '@angular/core';
import { Mapping, MappingResponse, AvailResponse } from '../models/mapping';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { ToastrService } from 'ngx-toastr';
import { Product, ProductResponse } from '../models/product';
import { PackSize, PackSizeResponse } from '../models/packsize';
import { Denomination, DenominationResponse } from '../models/denomination';
import { StockResponse, Stock, StockFromNumberResponse } from '../models/stock';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { ScanReport, ScanReportResponse, PassbookReport, PassbookReportResponse, PassbookExcelData } from '../models/report';
import { ExcelService } from '../excel.service';
import { MyExcelData } from '../models/user';
import { d } from '@angular/core/src/render3';


@Component({
  selector: 'app-passbookreportnew',
  templateUrl: './passbookreportnew.component.html',
  styleUrls: ['./passbookreportnew.component.css']
})
export class PassbookreportnewComponent implements OnInit {

  show: boolean = false;
  formval: PassbookReport = new PassbookReport();
  modalHead: string = "Add Stock Mapping";
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  mappings: Mapping[] = [];
  avails: Stock[] = [];
  products: Product[] = [];
  reportExcelPassbook: PassbookExcelData[] = [];
  productlist: Product[] = [];
  availlist: Mapping[] = [];
  scanreportlist: PassbookReport[] = [];
  scanreportlistexcel: PassbookReport[] = [];
  totaldata: number;
  availtotaldata: number;
  producttotaldata: number;
  fromDtEmpty: boolean = false;
  toDtEmpty: boolean = false;
  hoEmpty: boolean = false;
  dealerEmpty: boolean = false;
  siteEmpty: boolean = false;
  siteList:any[]=[];
  onEdit: boolean = false;
  pagesUser: UserPage = new UserPage();
  pagesUserStock: UserPage = new UserPage();
  pagesUserProduct: UserPage = new UserPage();
  packsizelist: PackSize[] = [];
  denominationlist: Denomination[] = [];
  DenominationName: string;
  deleteId: string;
  submitForm: NgForm;
  dealerList: any[] = [];
  HoUserlist: any[] = [];

  constructor(private excelService: ExcelService, private toastr: ToastrService, private modalService: BsModalService, private apiService: ApiService) {
    this.formval.HOUserId=0;
    this.formval.AccFrmDDL="0";
    this.formval.DealerId=0;
  }

  ngOnInit() {
    this.setDates();
    // this.formval.HOUserId = 0;
    this.fromDtEmpty = false;
    this.toDtEmpty = false;
    this.hoEmpty = false;
    this.dealerEmpty = false;
    this.siteEmpty = false;
    // this.formval.DealerId = 0;
    this.formval.Page = 1;
    this.formval.PageSize = 5;
    this.formval.IsActive = '%';
    this.formval.SearchValue = '';
    this.formval.FilterBY = '';
    this.getHoUserForDDL();
    this.getDealerListDDL();
    this.onDealerChange();
    this.getscanreport();
  }
  onutypechange() {

    this.getscanreport();
  }

  setDates(): any {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var currentDay = date.getDate();
    this.formval.fromdate = moment(firstDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(currentDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
  }



  exportAsXLSX(myreport: any): void {

    this.excelService.exportAsExcelFile(this.reportExcelPassbook, 'PassbookReport_' + moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY") + '_to_' + moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY"));

    this.formval.PageSize = 5;
  }

  DownloadScanReport() {

    this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {
      this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
        timeOut: 3000
      });
    }
    else {

      this.show = true;
      this.formval.PageSize = -1;
      this.formval.Page = 1;
      this.show = true;
      this.apiService.PostApi(this.formval, "Report/PassbookForRpt").subscribe((response: PassbookReportResponse) => {

        this.scanreportlistexcel = response.ResponseData;
        this.reportExcelPassbook = this.reportExcelPassbook.slice(0, 0);
        this.scanreportlistexcel.forEach(o => {
          this.reportExcelPassbook.push(
            { HoName: o.FullName, Mobile: o.Mobile, Points: o.Points, Date: o.ScanDt, Location: o.ScanDt, Scan_Number: o.HologramNo });
        });
        this.exportAsXLSX(this.reportExcelPassbook);
        this.show = false;
      },
        err => {
          this.show = false;
          this.totaldata = 0;
        })
      this.show = false;
    }
    this.formval.Page=1;
    this.formval.PageSize=5;
  }
  getDealerListDDL() {
    let obj = {
      UsrId: this.formval.HOUserId,
    }
    this.dealerList = [];
    this.siteList = [];
    this.apiService.PostApi(obj, "HOUser/GetHoDealerForDdl").subscribe((resp: any) => {
      if (resp.ResponseCode == 200) {
        this.dealerList = resp.ResponseData;
      }
      else{
        this.dealerList=[];
      }
    })
  }

  onDealerChange()
  {
    let x=this.formval.AccFrmDDL;
    let y=x.split(':');
    let obj={
      AccountNo:y[0]
    }
    this.formval.AccountNo=y[0];
    let UsrType='C';
    this.apiService.GetApi('0',`Dealer/GetHoDealerSiteForDdl?AccountNo=${obj.AccountNo}&&UsrType=${UsrType}`).subscribe((resp: any) => {
      if (resp.ResponseCode == 200) {
        this.siteList = resp.ResponseData;
      }
    })
  }
  getHoUserForDDL() {
    let obj = {
      UsrId: 0,
      IsActive: '%'
    }
    this.apiService.PostApi(obj, "HOUser/GetHoUsrForDdl").subscribe((resp: any) => {
      if (resp.ResponseCode == 200) {
        this.HoUserlist = resp.ResponseData;
      }
    })
  }
  getscanreport() {
    // this.fromDtEmpty = false;
    // this.toDtEmpty = false;
    // this.hoEmpty = false;
    // this.dealerEmpty = false;
    // this.siteEmpty = false;
    //  (this.formval);
    // if (this.formval.fromdate == 'Invalid date') {
    //   this.fromDtEmpty = true;
    // }
    // if (this.formval.todate == 'Invalid date') {
    //   this.toDtEmpty = true;
    // }
    // if (this.formval.HOUserId == undefined || this.formval.HOUserId == null) {
    //   this.hoEmpty = true;
    // }
    // if (this.formval.AccFrmDDL == undefined || this.formval.AccFrmDDL == null) {
    //   this.dealerEmpty = true;
    // }
    // if (this.formval.DealerId == undefined || this.formval.DealerId == null) {
    //   this.siteEmpty = true;
    // }
    // if (this.fromDtEmpty == true || this.toDtEmpty == true || this.hoEmpty == true || this.siteEmpty == true || this.dealerEmpty == true) {
    //   return;
    // }
    this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {
      this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
        timeOut: 3000
      });
    }
    else {
      this.scanreportlist = this.scanreportlist.slice(0, 0);
      this.show = true;
      this.apiService.PostApi(this.formval, "Report/PassbookForRpt").subscribe((response: any) => {
        if (response.ResponseCode == 200) {
          this.scanreportlist = response.ResponseData;
          this.totaldata = response.ResponseData[0].TotalCount;
          this.show = false;
        }
        else{
          this.scanreportlist=[];
          this.totaldata=0;
        }
      },
        err => {
          this.show = false;
          this.totaldata = 0;
        })
      this.show = false;
    }
  }



  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.formval.Page = event.page;
    this.getscanreport();

  }

  ontypechange() {

    this.pagesUser.page = 1
    this.getscanreport();

  }



}
