import { Component, OnInit, TemplateRef } from '@angular/core';
import { Mapping, MappingResponse, AvailResponse } from '../models/mapping';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { ToastrService } from 'ngx-toastr';
import { Product, ProductResponse } from '../models/product';
import { PackSize, PackSizeResponse } from '../models/packsize';
import { Denomination, DenominationResponse } from '../models/denomination';
import { StockResponse, Stock, StockFromNumberResponse } from '../models/stock';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { ExcelService } from '../excel.service';
import{ FeedbackReport} from '../models/feedback'
@Component({
  selector: 'app-Feedback',
  templateUrl: './Feedback.component.html',
  styleUrls: ['./Feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  show: boolean = false;
  formval: FeedbackReport = new FeedbackReport();
  modalHead: string = "Add Stock Mapping";
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  mappings: Mapping[] = [];
  reportExcelPassbook: any[] = [];
  avails: Stock[] = [];
  fromDtEmpty: boolean = false;
  toDtEmpty: boolean = false;
  products: Product[] = [];
  productlist: Product[] = [];
  availlist: Mapping[] = [];
  hoEmpty: boolean = false;
  dealerEmpty: boolean = false;
  siteEmpty: boolean = false;
  scanreportlist: FeedbackReport[] = [];
  scanreportlistexcel: FeedbackReport[] = [];
  TotalRows: number;
  siteList: any[] = [];
  availtotaldata: number;
  producttotaldata: number;
  onEdit: boolean = false;
  pagesUser: UserPage = new UserPage();
  pagesUserStock: UserPage = new UserPage();
  pagesUserProduct: UserPage = new UserPage();
  packsizelist: PackSize[] = [];
  denominationlist: Denomination[] = [];
  DenominationName: string;
  deleteId: string;
  currentPage: number;
  submitForm: NgForm;
  HoUserlist: any[] = [];
  dealerList: any[] = [];
  constructor(private excelService: ExcelService, private toastr: ToastrService, private modalService: BsModalService, private apiService: ApiService) {
    this.formval.PageNumber=1 ;
    this.formval.PageSize = 5;
    this.formval.Search = '';
    this.formval.hoUserId=0;
    this.formval.FromDate='';
    this.formval.ToDate='';
  }
  ngOnInit() {
    this.setDates();
    // this.show = false;
    // this.fromDtEmpty = false;
    // this.toDtEmpty = false;
    // this.hoEmpty = false;
    // this.formval.hoUserId;
    // this.getscanreport();
    // this.getHoUserForDDL();
    // this.getDealerListDDL();
    // this.onDealerChange();
    this.getscanreport();
  }
  // onutypechange() {
  //   this.getscanreport();
  // }
  setDates(): any {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var currentDay = date.getDate();
    this.formval.FromDate = moment(firstDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.ToDate = moment(currentDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
  }
  exportAsXLSX(myreport: any): void {
    this.excelService.exportAsExcelFile(this.reportExcelPassbook, 'FeedbackReport_'  + moment(this.formval.FromDate, "DD-MMM-YYYY").format("DD-MMM-YYYY") + '_to_' + moment(this.formval.ToDate, "DD-MMM-YYYY").format("DD-MMM-YYYY"));
    this.formval.PageSize = 5;
  }
  DownloadScanReport() {
debugger
    this.formval.FromDate = moment(this.formval.FromDate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.ToDate = moment(this.formval.ToDate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    if (moment(this.formval.FromDate).isAfter(this.formval.ToDate)) {
      this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
        timeOut: 3000
      });
    }
    else {
      this.show = true;
      this.formval.PageSize = this.TotalRows;
      this.formval.PageNumber = this.formval.PageNumber;
      this.apiService.PostApi(this.formval, "Report/FeedbackReport").subscribe((response: any) => {
        if(response.ResponseCode==200){
        this.scanreportlistexcel = response.ResponseData;
        this.reportExcelPassbook = this.reportExcelPassbook.slice(0, 0);
        this.scanreportlistexcel.forEach(o => {
          this.reportExcelPassbook.push(
            { 
              ProductCode: o.ProductCode ,
              Name: o.Name,
              Mobile: o.UserMobile,
              ScanNumber: o.HologramNo,
              Feedback:o.Feedback,
              Rating:o.Rating,
              Location: o.ScanLocation,
              Scan_Date: o.FeedbackDate,});
        });
        this.exportAsXLSX(this.reportExcelPassbook);
      }
      else{
        this.toastr.warning('There is no data to download', 'Warning!', {
          timeOut: 1000
        });
      }},
        err => {
          this.show = false;
          this.TotalRows = 0;
        }
      )
      this.show = false;
    }
    this.formval.PageNumber = this.formval.PageNumber;
    this.formval.PageSize = this.formval.PageSize;
  }
  onpagechange() {
    this.formval.PageNumber = 1;
    this.getscanreport();
  }
  // getDealerListDDL() {
  //   let obj = {
  //     UsrId: this.formval.hoUserId
  //   }
  //   this.dealerList = [];
  //   this.siteList = [];
  //   // this.formval.AccFrmDDL = null;
  //   // this.formval.DealerId = null;
  //   this.apiService.PostApi(obj, "HOUser/GetHoDealerForDdlApi").subscribe((resp: any) => {
  //     if (resp.ResponseCode == 200) {
  //       this.dealerList = resp.ResponseData;
  //     }
  //     else {
  //       this.dealerList = [];
  //     }
  //   })
  // }
  // onDealerChange() {
  //   // this.formval.DealerId=null;
  //   let x = this.formval.AccFrmDDL;
  //   let y = x.split(':');
  //   let obj = {
  //     AccountNo: y[0]
  //   }
  //   this.formval.AccountNo = y[0];
  //   let UsrType;
  //     UsrType='C';
  //   this.apiService.GetApi('0', `ApiDealer/GetHoDealerSiteForDdl?AccountNo=${obj.AccountNo}&&UsrType=${UsrType}`).subscribe((resp: any) => {
  //     if (resp.ResponseCode == 200) {
  //       this.siteList = resp.ResponseData;
  //     }
  //   })
  // }
  // getHoUserForDDL() {
  //   let obj = {
  //     UsrId: 0,
  //     IsActive: '%'
  //   }
  //   this.apiService.PostApi(obj, "HOUser/GetHoUsrForDdlApi").subscribe((resp: any) => {
  //     if (resp.ResponseCode == 200) {
  //       this.HoUserlist = resp.ResponseData;
  //     }
  //   })
  // }
  getscanreport() {
    // this.show = false;
    // this.fromDtEmpty = false;
    // this.toDtEmpty = false;
    // this.hoEmpty = false;
    // this.dealerEmpty = false;
    // this.siteEmpty = false;
    //  (this.formval);
    // if (this.formval.fromdate == 'Invalid date') {
    //   this.fromDtEmpty = true;
    // }
    // if (this.formval.todate == 'Invalid date') {
    //   this.toDtEmpty = true;
    // }
    // if (this.formval.HOUserId == undefined || this.formval.HOUserId == null) {
    //   this.hoEmpty = true;
    // }
    // if (this.formval.AccFrmDDL == undefined || this.formval.AccFrmDDL == null) {
    //   this.dealerEmpty = true;
    // }
    // if (this.formval.DealerId == undefined || this.formval.DealerId == null) {
    //   this.siteEmpty = true;
    // }
    // if (this.fromDtEmpty == true || this.toDtEmpty == true || this.hoEmpty == true || this.siteEmpty == true || this.dealerEmpty == true) {
    //   return;
    // } 
    debugger
    let obj={
      pageNumber:this.formval.PageNumber,
      pageSize:this.formval.PageSize,
      searchValue:this.formval.Search,
      hoUserId:this.formval.hoUserId,
      fromDate:this.formval.FromDate,
      toDate:this.formval.ToDate,
    }
    this.formval.FromDate = moment(this.formval.FromDate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.ToDate = moment(this.formval.ToDate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    if (this.formval.FromDate)
      if (moment(this.formval.FromDate).isAfter(this.formval.ToDate)) {
        this.toastr.warning('From Date Cannot be greater than To Date!', 'Warning!', {
          timeOut: 3000
        });
      }
      else {
        this.scanreportlist = this.scanreportlist.slice(0, 0);
        this.show = true;
        this.apiService.PostApi(obj, "Report/FeedbackReport").subscribe((response: any) => {
          debugger;
          if (response.ResponseCode == 200) {
            this.scanreportlist = response.ResponseData;
            this.TotalRows = response.ResponseData[0].TotalRows;
          }
          else {
            this.scanreportlist = [];
            this.TotalRows = 0;
          }
        },
          err => {
            this.scanreportlist = []
            this.TotalRows = 0;
            this.show = false;
          })
        this.show = false;
      }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.formval.PageNumber = event.page;
    this.getscanreport();
    this.currentPage = event.page + 1;
  }
  ontypechange() {
    this.pagesUser.page = 1;
    this.getscanreport();
  }
}