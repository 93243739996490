import { Component, OnInit, TemplateRef } from '@angular/core';
import { Mapping, MappingResponse, AvailResponse } from '../models/mapping';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { ToastrService } from 'ngx-toastr';
import { Product, ProductResponse } from '../models/product';
import { PackSize, PackSizeResponse } from '../models/packsize';
import { Denomination, DenominationResponse } from '../models/denomination';
import { StockResponse, Stock, StockFromNumberResponse } from '../models/stock';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { ScanReport, ScanReportResponse, PassbookReport, PassbookReportResponse, PassbookExcelData } from '../models/report';
import { ExcelService } from '../excel.service';
import { MyExcelData } from '../models/user';

@Component({
  selector: 'app-passbookreport',
  templateUrl: './passbookreport.component.html',
  styleUrls: ['./passbookreport.component.css']
})

export class PassbookreportComponent implements OnInit {


  show: boolean = false;
  formval: PassbookReport = new PassbookReport();
  modalHead: string = "Add Stock Mapping";
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  mappings: Mapping[] = [];
  avails: Stock[] = [];
  products: Product[] = [];
  reportExcelPassbook: PassbookExcelData[] = [];
  productlist: Product[] = [];
  availlist: Mapping[] = [];
  scanreportlist: PassbookReport[] = [];
  scanreportlistexcel: PassbookReport[] = [];
  totaldata: number;
  availtotaldata: number;
  producttotaldata: number;
  onEdit: boolean = false;
  pagesUser: UserPage = new UserPage();
  pagesUserStock: UserPage = new UserPage();
  pagesUserProduct: UserPage = new UserPage();
  packsizelist: PackSize[] = [];
  denominationlist: Denomination[] = [];
  DenominationName: string;
  deleteId: string;
  submitForm: NgForm;


  constructor(private excelService: ExcelService, private toastr: ToastrService, private modalService: BsModalService, private apiService: ApiService) {

  }

  ngOnInit() {

  }

}

