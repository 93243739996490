import { Component, OnInit, TemplateRef } from '@angular/core';
import { Mapping, MappingResponse, AvailResponse } from '../models/mapping';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { ToastrService } from 'ngx-toastr';
import { Product, ProductResponse } from '../models/product';
import { PackSize, PackSizeResponse } from '../models/packsize';
import { Denomination, DenominationResponse } from '../models/denomination';
import { StockResponse, Stock, StockFromNumberResponse } from '../models/stock';
import { NgForm } from '@angular/forms';
import { OrganisationResponse, Organisation } from '../models/organisation';
import { StockTransfer, StockTransferResponse } from '../models/stocktransfer';
@Component({
  selector: 'app-stocktransfer',
  templateUrl: './stocktransfer.component.html',
  styleUrls: ['./stocktransfer.component.css']
})
export class StocktransferComponent implements OnInit {
  show: boolean = false;
  formval: StockTransfer = new StockTransfer();
  modalHead: string = "Add Stock Mapping";
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  mappings: Mapping[] = [];
  avails: Stock[] = [];
  products: Product[] = [];
  productlist: Product[] = [];
  permanentFromNumber: number;
  permanentToNumber: number;
  availlist: Mapping[] = [];
  minFromNo: number;
  maxToNo: number;
  mappinglist: StockTransfer[] = [];
  organisationlist: Organisation[] = [];
  totaldata: number;
  availtotaldata: number;
  producttotaldata: number;
  onEdit: boolean = false;
  usertype: string;
  pagesUser: UserPage = new UserPage();
  pagesUserStock: UserPage = new UserPage();
  pagesUserProduct: UserPage = new UserPage();
  packsizelist: PackSize[] = [];
  denominationlist: Denomination[] = [];
  DenominationName: string;
  submitForm: NgForm;
  ToOrg: number;
  url: string;
  constructor(private toastr: ToastrService, private modalService: BsModalService, private apiService: ApiService) {
    this.pagesUser.Filter = "FromNumber";
    this.formval.HologramID = 0;
    this.formval.ToOrganisation = 0;
  }
  ngOnInit() {
    this.ToOrg = 0;
    this.usertype = localStorage.getItem("usertype");
    this.pagesUser.HologramID = 0;
    this.pagesUser.Organisation = parseInt(localStorage.getItem("organisation"));
    this.formval.ToOrganisation = 0;
    this.formval.FromOrganisation = this.pagesUser.Organisation;
    this.getorganisationlst();
    this.getstocklistapi();
    this.getDenominationList();
    this.onEdit = false;
  }
  openModal(template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
    this.modalRef = this.modalService.show(template, config);
  }
  openModal2(template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
    this.modalRef2 = this.modalService.show(template, config);
  }
  onutypechange() {
    this.getstocklistapi();
  }
  calc2(val: number) {
    if (this.formval.Quantity == '' || parseInt(this.formval.Quantity) == 0) {
      this.formval.ToNumber = '';
    }
    else {
      for (var i = 0; i < this.denominationlist.length; i++) {
        if (this.formval.HologramID == this.denominationlist[i].ID) {
          this.formval.Prefix = this.denominationlist[i].Prefix;
        }
      }
      let frm=this.formval.FromNumber;
      var len = this.formval.FromNumber.length;
      frm = this.formval.FromNumber.substring(1, len);
      if (parseInt(frm) == 0) {
      }
      else if (parseInt(this.formval.Quantity) == 0) {
        frm = this.formval.Prefix + frm;
      }
      else {
        var txtfromNo = parseInt(frm);
        var txtqtyNo = parseInt(this.formval.Quantity);
        var txttoNo = txtfromNo + txtqtyNo - 1;
        this.formval.ToNumber = txttoNo.toString();
        frm = frm;
        this.formval.ToNumber = this.formval.ToNumber
      }
      //this.formval.FromNumber = this.formval.Prefix + this.mypadding(parseInt(this.formval.FromNumber), 9)
      this.formval.ToNumber = this.formval.Prefix + this.mypadding(parseInt(this.formval.ToNumber), 9)
    }
  }
  mypadding(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  calc(val: number) {
    for (var i = 0; i < this.denominationlist.length; i++) {
      if (this.formval.HologramID == this.denominationlist[i].ID) {
        this.formval.Prefix = this.denominationlist[i].Prefix;
      }
    }
    // var len=this.formval.FromNumber.length;
    // this.formval.FromNumber=this.formval.FromNumber.substring(1,len-1);
    if (parseInt(this.formval.FromNumber) == 0) {
      this.formval.FromNumber = '';
      this.formval.ToNumber = '';
    }
    else if (parseInt(this.formval.Quantity) == 0) {
      this.formval.ToNumber = '';
      this.formval.Quantity = '';
      this.formval.FromNumber = this.formval.Prefix + this.formval.FromNumber;
    }
    else {
      var txtfromNo = parseInt(this.formval.FromNumber);
      var txtqtyNo = parseInt(this.formval.Quantity);
      this.formval.FromNumber = this.formval.Prefix + this.formval.FromNumber;
    }
  }
  getFromNumberByDenomination() {
    this.formval.FromNumber = "1";
    this.apiService.PostApi(this.pagesUser, "Stock/GetFromNumber?HologramID=" + this.formval.HologramID).subscribe((response: StockFromNumberResponse) => {
      this.formval.FromNumber = response.ResponseData
      this.calc(parseInt(this.formval.FromNumber));
    })
    if (this.formval.HologramID == 0) {
      this.formval.FromNumber = "";
      this.formval.ToNumber = "";
      this.formval.Quantity = "";
    }
  }
  getorganisationlst() {
    this.apiService.PostApi(null, "Other/GetOtherOrganisation?organisation=" + this.formval.FromOrganisation).subscribe((response: OrganisationResponse) => {
      if (response.ResponseCode == "200") {
        this.organisationlist = response.ResponseData;
      }
      this.formval.ToOrganisation = 0;
    })
  }
  getstocklistapi() {
    if (this.usertype == '0') {
      this.url = "StockTransfer/GetStockTransfer"
      this.pagesUser.IsTransferred = "0";
      this.pagesUser.IsReceived = "0";
    }
    else {
      this.pagesUser.IsTransferred = "1";
      this.pagesUser.IsReceived = "1";
      this.url = "Stock/GetStockAfterReceive"
    }
    this.show = true;
    this.apiService.PostApi(this.pagesUser, "Stock/GetStockForTransfer").subscribe((response: any) => {
      if (response.ResponseData.TotalCount > 0) {
        this.mappinglist = response.ResponseData.AvailData;
          for (var i = 0; i < this.mappinglist.length; i++) {
            this.mappinglist[i].FromNumber = this.mappinglist[i].Prefix + this.mypadding(parseInt(this.mappinglist[i].FromNumber), 9);
            this.mappinglist[i].ToNumber = this.mappinglist[i].Prefix + this.mypadding(parseInt(this.mappinglist[i].ToNumber), 9);
        }
        this.totaldata = response.ResponseData.TotalCount;
        this.show = false;
      }
      else {
        this.mappinglist = [];
        this.totaldata = 0;
      }
    },err=>{
      this.mappinglist = [];
      this.totaldata = 0;
    })
    this.show = false;
  }
  onSubmit(f: NgForm) {
    if(f.value.ToOrganisation == null || f.value.ToOrganisation == '' || f.value.fromnumber == '' || f.value.fromnumber == null ||
    f.value.quantity == '' || f.value.quantity == null || f.value.tonumber=='' || f.value.tonumber == null){
      this.toastr.warning(`Please fill all the details`)
      return
    }
    this.submitForm = f;
    // this.show=true;
    var flen = this.formval.FromNumber.length;
    var tlen = this.formval.ToNumber.length;
    this.formval.FromNumber = this.formval.FromNumber.substring(1, flen);
    this.formval.ToNumber = this.formval.ToNumber.substring(1, tlen);
    this.saveStock();
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  removenumber() {
    if (this.formval.Quantity == '') {
      this.formval.ToNumber = '';
    }
  }
  saveStock() {
    this.show = true;
    this.formval.FromOrganisation = parseInt(localStorage.getItem("organisation"));
    if (parseInt(this.formval.FromNumber) < this.minFromNo || parseInt(this.formval.ToNumber) > this.maxToNo) {
      this.toastr.warning("Invalid Numbers to Transfer", 'Warning!', {
        timeOut: 3000
      });
      this.formval.FromNumber = this.formval.Prefix + this.formval.FromNumber;
      this.formval.ToNumber = this.formval.Prefix + this.formval.ToNumber;
      this.show = false;
    }
    else {
      if (this.formval.ToOrganisation == 0) {
        this.formval.FromNumber = this.formval.Prefix + this.formval.FromNumber;
        this.formval.ToNumber = this.formval.Prefix + this.formval.ToNumber;
        this.toastr.warning("Please select an Organisation", "Warning!")
        this.show = false;
      }
      else if (parseInt(this.formval.FromNumber) <= 0 || parseInt(this.formval.ToNumber) <= 0 || parseInt(this.formval.Quantity) <= 0) {
        this.formval.FromNumber = this.formval.Prefix + this.formval.FromNumber;
        this.formval.ToNumber = this.formval.Prefix + this.formval.ToNumber;
        this.toastr.warning("Negative Values are not allowed", "Warning!")
        this.show = false;
      }
      else if (this.permanentFromNumber > parseInt(this.formval.FromNumber) || this.permanentToNumber < parseInt(this.formval.ToNumber)) {
        this.formval.FromNumber = this.formval.Prefix + this.formval.FromNumber;
        this.formval.ToNumber = this.formval.Prefix + this.formval.ToNumber;
        this.toastr.warning("You can only transfer the stock within range", "Warning!")
        this.show = false;
      }
      else {
        this.apiService.PostApi(this.formval, "Stock/SaveStockTransfer").subscribe((response: StockResponse) => {
          if (response.ResponseCode == "200") {
            this.submitForm.resetForm();
            this.show = false;
            this.toastr.success(response.ResponseMessage, "Success!")
            this.getstocklistapi();
            this.resetmodal();
          }
          else {
            this.toastr.warning(response.ResponseMessage, "Warning!")
            this.show = false;
          }
        }, err => {
          this.toastr.warning(err.error.ResponseMessage, "Warning!")
          this.show = false;
        })
      }
    }
  }
  editstock(template: TemplateRef<any>, summary: StockTransfer) {
  ;
    this.formval = { ...summary };
    this.maxToNo = parseInt(this.formval.ToNumber.substring(1, 10));
    this.minFromNo = parseInt(this.formval.FromNumber.substring(1, 10));
    for (var i = 0; i < this.denominationlist.length; i++) {
      if (this.formval.HologramID == this.denominationlist[i].ID) {
        this.formval.Prefix = this.denominationlist[i].Prefix;
      }
    }
    this.permanentFromNumber = parseInt(this.formval.FromNumber);
    this.permanentToNumber = parseInt(this.formval.ToNumber);
    this.formval.ToOrganisation = 0;
    this.onEdit = true;
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
    this.modalRef = this.modalService.show(template, config);
  }
  resetmodal() {
    this.modalRef.hide();
    this.onEdit = false;
    this.formval.FromNumber = '';
    this.formval.HologramID = 0;
    this.formval.ToNumber = '';
    this.maxToNo = 0;
    this.minFromNo = 0;
    this.formval.Quantity = '';
    this.permanentFromNumber = 0;
    this.permanentToNumber = 0;
  }
  closemodal2() {
    this.modalRef2.hide();
  }
  closemodal3() {
    this.modalRef3.hide();
  }
  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.pagesUser.page = event.page;
    this.getstocklistapi();
  }
  ontypechange() {
    this.pagesUser.page = 1
    this.getstocklistapi();
  }
  getDenominationList() {
    this.apiService.GetApi(this.pagesUserStock, "Other/GetDenomiations").subscribe((response: any) => {
      if (response.ResponseCode == "200") {
        this.denominationlist = response.ResponseData;
      }
    })
  }
}
