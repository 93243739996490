import { Component, TemplateRef } from '@angular/core';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { UserMasterResponse, User, MyExcelData } from '../models/user';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/ngx-bootstrap-pagination';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from '../excel.service'
import { States, StateResponse } from '../models/State';
import { CityResponse, City } from '../models/city';
import * as XLSX from 'xlsx';
import { Dealer, DealerMasterResponse } from '../models/dealer';
import { ProductMasterResponse, Product } from '../models/product';
import { NgForm } from '@angular/forms';
type AOA = any[][];
@Component({
  selector: 'app-productmaster',
  templateUrl: './productmaster.component.html',
  styleUrls: ['./productmaster.component.css']
})
export class ProductmasterComponent {
  pagesUser: UserPage = new UserPage();
  users: Product[] = [];
  formval: Product = new Product();
  show: boolean = false;
  modalhead: string = "Upload Product Details";
  dnm: boolean = false;
  result: string = "0"
  totaldata: number = 0;
  activate: string = "done"
  numberedit: string = "";
  data1: any = [];
  data: AOA = [];
  templateProduct: MyExcelData[] = [];
  template: TemplateRef<ProductmasterComponent>;
  modalRef: BsModalRef;
  ErrorCount: string = "0";
  errorList: User[] = [];
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  modalRef4: BsModalRef;
  modalRef5: BsModalRef;
  states: States[] = [];
  mobileReg: RegExp = /^[6-9]\d{9}$/;
  emailReg: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  isEdit: boolean = true;
  cities: City[] = [];
  SuccessCount: string = "0";
  currentPage: number;
  productlist: Product[] = [];
  buttonText: string = "Save";
  DeleteId: string;
  Edit: boolean = true;
  ProductId: string;
  submitForm: NgForm;
  constructor(private excelService: ExcelService, private toastr: ToastrService, private apiService: ApiService, private modalService: BsModalService) {
    this.pagesUser.Filter = 'FirstName';
  }
  ngOnInit() {
    //    this.getUsers(this.pagesUser);
    this.getProductList();
    this.getStatesApi();
    this.formval.IsActive = true;
    if (this.submitForm.invalid )
    {debugger
      this.toastr.warning('Please fill all valid details','Warning!',{
        timeOut:2000

       })
      return 
    }
  }
  onSubmit(f: NgForm) {
    debugger;
    if ( this.formval == null || this.formval == undefined ||
      //(this.formval.ProductCategory == null || this.formval.ProductCategory == "")||
      (this.formval.ProductCode == null || this.formval.ProductCode == undefined || this.formval.ProductCode == '')||
      ( this.formval.ProductSize == null || this.formval.ProductSize == undefined || this.formval.ProductSize == '')||
      (this.formval.Description == null || this.formval.Description == undefined || this.formval.Description == "" )|| 
      (this.formval.UOM == null || this.formval.UOM == undefined|| this.formval.UOM=="") ||
      (this.formval.SubCategory == null || this.formval.SubCategory == undefined || this.formval.SubCategory=="")
    ) {
      this.toastr.warning('Please fill all required details', 'Warning!', {
        timeOut: 2000

      });
      return false
    }
      else{
      this.saveUser(this.formval, "Product/EditProduct");
      debugger
    }}
  

  getStatesApi() {
  }
  edituser(template: TemplateRef<any>, summary: Product) {
    debugger;
    this.isEdit = true;
    //alert(this.user.firstName);
    this.modalhead = "Edit Product";
    this.numberedit = "";
    this.formval = { ...summary };
    this.ProductId = { ...summary }.ID.toString();
    // this.formval.Category = { ...user }.Category;
    // this.formval.SubCategory = { ...user }.SubCategory;
    // this.formval.Description = this.formval.Description;
    // this.formval.UOM = this.formval.UOM;
    // this.formval.Size = this.formval.Size;
    // this.formval.ProductCode = this.formval.ProductCode;

    this.buttonText = "Update";
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    this.modalRef2 = this.modalService.show(template, config);

  }
  saveUser(formval: Product, url: string) {
    debugger;
    this.apiService.PostApi(formval, url).subscribe((response: ProductMasterResponse) => {
      debugger
      if (response.ResponseCode == "200") {
        
        //this.modalRef.hide();
        this.modalRef2.hide();
        this.show = false;
        this.toastr.success(response.ResponseMessage, 'Success!', {
          timeOut: 2000
        });
        this.getProductList();
        
        //this.resetmodal();
        this.show = false;
      }
      else if (response.ResponseCode == "400") {
        
        //this.modalRef.hide();
        this.modalRef2.hide();
        this.show = false;
        this.toastr.warning(response.ResponseMessage, 'Error!', {
          timeOut: 2000
        });
        this.getProductList();
        
        //this.resetmodal();
        this.show = false;
      }
      else {
        this.toastr.warning(response.ResponseMessage, 'Error!', {
          timeOut: 2000
        });
        this.show = false;
      }
    }, err => {
      this.toastr.warning(err.error.ResponseMessage, 'Error!', {
        timeOut: 2000
      });
      this.show = false;
    });
  }
  deleteProduct(template: TemplateRef<any>, summary: Product) {
    debugger;
    let config = { backdrop: true, ignoreBackdropClick: true };
    this.modalRef4 = this.modalService.show(template, config);
    this.DeleteId = { ...summary }.ID.toString();
  }
  DeleteProduct() {
    this.show = true;
    this.apiService.PostApi(null, "Product/DeleteProduct?ProductID=" + this.DeleteId).subscribe((response: UserMasterResponse) => {
      debugger;
      (response)
      if (response.ResponseCode == "200") {
        this.show = false;
        this.onpagechange();
        this.toastr.success(response.ResponseMessage, 'Success!', {
          timeOut: 2000
        });
        this.getProductList();
      }
      else {
        this.toastr.warning(response.ResponseMessage, 'Error!', {
          timeOut: 2000
        });
        this.show = false;
      }
    }, err => {
      this.toastr.warning(err.error.ResponseMessage, 'Error!', {
        timeOut: 2000
      });
      this.show = false;
    });
    this.modalRef4.hide();
  }
  getProductList() {
    debugger;
    this.apiService.PostApi(this.pagesUser, "Product/GetAllProduct").subscribe((response: ProductMasterResponse) => {
      debugger;
      (response)
      if (response.ResponseCode == "200") {
        this.productlist = response.ResponseData.ProductData;
        this.totaldata = response.ResponseData.ProductData[0].TotalCount;
      }
      else {
        this.productlist = null;
        this.totaldata = 0;
      }
    })
  }
  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.templateProduct, 'Apollo_Product_Template');
  }


  // getStatesApi() {
  //   debugger;
  //     this.apiService.PostApi(this.formval, "Product/EditProduct").subscribe((response: StateResponse) => {
  //       (response)
  //       if (response.ResponseCode == "200") {
  //         //this.edituser.resetForm();
  //         this.show = false;
  //         this.toastr.success("Product Updated Successfully", "Success!")
  //         this.getProductList();

  //         this.resetmodal();
  //       }
  //       else {
  //         this.toastr.warning(response.ResponseMessage, "Warning!")
  //         this.show = false;
  //       }
  //     }, err => {
  //       this.toastr.warning(err.error.ResponseMessage, "Warning!")
  //       this.show = false;
  //     })

  // //   this.apiService.PostApi(null, "Product/EditProduct").subscribe((response: StateResponse) => {
  // //     this.states = response.ResponseData;
  // //   })
  //  }}
  onChange(newValue: number) {
    this.getCities(newValue);
  }
  getCities(state: number) {
    this.apiService.PostApi(null, "Other/GetCity?StateID=" + state).subscribe((response: CityResponse) => {
      this.cities = response.ResponseData;
    })
  }
  UploadCSVToServer(template: TemplateRef<any>) {
    if (this.activate == "done") {
    }
    else {
      let config = { backdrop: true, ignoreBackdropClick: true };
      this.show = true;
      const postData = { data: this.data };
      (JSON.stringify(postData))
      this.apiService.PostApi(postData, "Product/UploadProduct").subscribe((response: UserMasterResponse) => {
        (response.ResponseData)
        if (response.ResponseCode == "200") {
          this.result = "1";
          this.getProductList();
         
          this.errorList = response.ResponseData.ErrorList;
          if (response.ResponseData.SuccessCount != "") {
            this.SuccessCount = response.ResponseData.SuccessCount;
            this.toastr.success('Success', 'Product Added successfully', {
              timeOut: 2000
            })
            // this.modalRef.hide();
          }
          if (response.ResponseData.ErrorCount != "") {
            this.ErrorCount = response.ResponseData.ErrorCount;
            this.toastr.warning(response.ResponseMessage, 'Product not added', {
              timeOut: 2000
            })
          }
          this.show = false;
        }
        else {
          this.toastr.warning(response.ResponseMessage, 'Error!', {
            timeOut: 2000
          });
          this.show = false;
        }
      }, err => {
        this.toastr.warning('Something Went Wrong!', 'Error!', {
          timeOut: 2000
        });
        this.show = false;
      });
    }
  }
  ShowErrorList(template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    this.modalRef3 = this.modalService.show(template, config);
  }
  myUploader(event) {
    this.activate = "done";
    if (event.files[0].name.includes('.csv')) {
      const target: DataTransfer = <DataTransfer>(event);
      //  (target);
      if (target.files.length !== 1) {
        this.toastr.warning("You can upload multiple files", "Warning!")
      }
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        /* save data */
        this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
        (this.data);
        this.toastr.success('Success', 'File uploaded successfully', {
          timeOut: 2000,
        });
        this.activate = "";
      };
      reader.readAsBinaryString(target.files[0]);
    } else {
      this.toastr.error("You can only upload CSV file", "Warning!", {
        timeOut: 2000,
      });

    }
    // event.files == files to upload
  }

  // getUsers(user: UserPage): any {
  //   this.show = true;
  //   this.pagesUser.UserType = 3;
  //   this.apiService.PostApi(this.pagesUser, "User/GetAllUser").subscribe((response: UserMasterResponse) => {
  //     if (response.ResponseCode == "200") {
  //       this.users = response.ResponseData.UserData;
  //       if (this.totaldata < parseInt(this.pagesUser.pageSize)) {
  //         this.currentPage = 1;
  //       }
  //     }
  //     else {
  //       this.users = null;
  //       this.show = false;
  //     }
  //   }, err => {
  //     this.users = null;
  //     this.show = false;
  //   });
  //   this.show = false;
  // }
  Downloadtemplate() {
    this.templateProduct.push({
      ProductCode: "",
      Category: "",
      SubCategory: "",
      Description: ""
    })
    this.exportAsXLSX();
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  alphaOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }
  openModal(template: TemplateRef<any>) {
    debugger;
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    this.modalRef = this.modalService.show(template, config);
  }

  openModal2(template: TemplateRef<any>) {
    debugger;
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    this.modalRef5 = this.modalService.show(template, config);
  }
  resetmodal() {
    this.modalRef.hide();
    this.formval.ProductCode = '';
    this.formval.ProductCategory = 0;
    this.formval.ProductID = 0;
    this.formval.SubCategory = '';
    this.activate = "done";
    this.formval.UOM = '';
    this.formval.ProductSize = '';
    this.dnm = false;
    this.modalhead = "Add Product";
    this.isEdit = true;
    this.numberedit = "";
    this.formval.Description = '';
    this.DeleteId = "0";
    this.SuccessCount = "0";
    this.ErrorCount = "0";
    this.buttonText = "Save";
    this.modalRef.hide();
    this.data = null;
    this.result = "0";
    this.modalRef2.hide();
    (this.data)
  }
  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.pagesUser.page = event.page;
    this.getProductList();
    //  (this.users)
    this.currentPage = event.page + 1;
  }
  onpagechange() {
    this.pagesUser.page = 1;
    this.getProductList();
  }

}
