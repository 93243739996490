export class demapping
{
    pageNumber:number;
    pageSize:string;
    fromdate :string;
    todate:string;
    OrganisationID:string;
    
}
export class demappingResponse
{
    ResponseCode:string;
    ResponseData:demapping[];
    ResponseMessage:string;
    TotalCount:number;
}