import { Component, OnInit, TemplateRef } from '@angular/core';
import { Mapping, MappingResponse, AvailResponse } from '../models/mapping';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { ToastrService } from 'ngx-toastr';
import { Product, ProductResponse } from '../models/product';
import { PackSize, PackSizeResponse } from '../models/packsize';
import { Denomination, DenominationResponse } from '../models/denomination';
import { StockResponse, Stock, StockFromNumberResponse } from '../models/stock';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { ScanReport, ScanReportResponse, PassbookExcelData, ScanLogExcelData } from '../models/report';
import { ExcelService } from '../excel.service';

@Component({
  selector: 'app-Dealerreport',
  templateUrl: './Dealerreport.component.html',
  styleUrls: ['./Dealerreport.component.css']
})
export class DealerreportComponent implements OnInit {

  show: boolean = false;
  formval: ScanReport = new ScanReport();
  modalHead: string = "Add Dealer Master";
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  mappings: Mapping[] = [];
  reportExcelPassbook: any[] = [];
  avails: Stock[] = [];
  fromDtEmpty: boolean = false;
  toDtEmpty: boolean = false;
  products: Product[] = [];
  productlist: Product[] = [];
  availlist: Mapping[] = [];
  hoEmpty: boolean = false;
  dealerEmpty: boolean = false;
  siteEmpty: boolean = false;
  scanreportlist: ScanReport[] = [];
  scanreportlistexcel: ScanReport[] = [];
  totaldata: number;
  siteList: any[] = [];
  availtotaldata: number;
  producttotaldata: number;
  onEdit: boolean = false;
  pagesUser: UserPage = new UserPage();
  pagesUserStock: UserPage = new UserPage();
  pagesUserProduct: UserPage = new UserPage();
  packsizelist: PackSize[] = [];
  denominationlist: Denomination[] = [];
  DenominationName: string;
  deleteId: string;
  currentPage: number;
  submitForm: NgForm;
  HoUserlist: any[] = [];
  dealerList: any[] = [];
  constructor(private excelService: ExcelService, private toastr: ToastrService, private modalService: BsModalService, private apiService: ApiService) {
    this.formval.ScanStatus = 'ALL';
    this.formval.PageSize = 5;
    this.formval.SearchValue = '';
    this.formval.AccFrmDDL="0";
    this.formval.DealerId="0";
    this.formval.Page = 1;
    this.formval.FilterBy = '';
  }

  ngOnInit() {
    this.setDates();
    this.show = false;
    this.fromDtEmpty = false;
    this.toDtEmpty = false;
    this.hoEmpty = false;
    this.dealerEmpty = false;
    this.formval.HOUserId=0;
    this.siteEmpty = false;
    this.formval.UserType ;
    this.dealerList = [];
    // this.formval.IsActive = '%';
    // this.getscanreport();
    this.getHoUserForDDL();
    this.getDealerListDDL();
    this.onDealerChange();
    this.getscanreport();
  }
  onutypechange() {

    this.getscanreport();
  }

  setDates(): any {
    var date = new Date();

    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var currentDay = date.getDate();

    this.formval.fromdate = moment(firstDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(currentDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
  }


  exportAsXLSX(myreport: any): void {



    this.excelService.exportAsExcelFile(this.reportExcelPassbook, 'DealerwiseReport_' + this.formval.ScanStatus + '_' + moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY") + '_to_' + moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY"));

    this.formval.PageSize = 5;
  }

  DownloadScanReport() {
    debugger;
let obj={
      page:this.formval.Page,
      pageSize:this.totaldata,
      FilterBy:'',
      searchValues:'',
      IsActive:'true',
      fromDt: this.formval.fromdate,
      toDt: this.formval.todate,
      dealerId:this.formval.DealerId,
      // organisation: this.formval.OrganisationID=localStorage.getItem("organisation"),
}
    
    this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");

    if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {


      this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
        timeOut: 3000
      });


    }
    else {
      this.show = true;
      this.formval.PageSize = this.totaldata[0];
      this.formval.Page = this.formval.pageNumber;
      this.apiService.PostApi(obj, "Report/DealerWiseReport").subscribe((response: any) => {
        debugger
        if(response.ResponseCode == 200){
        this.scanreportlistexcel = response.ResponseData;
        this.reportExcelPassbook = this.reportExcelPassbook.slice();
        this.scanreportlistexcel.forEach(o => {
          this.reportExcelPassbook.push(
            { 
              // ScannedBy: o.Scannedby, 
              Dealer_Name: o.DealerNm,
              Dealer_Code: o.EmpId,
               Mobile_No: o.Mobile, 
              Points: o.Points ,
              Scanned_Date:o.Date});
        });
        this.exportAsXLSX(this.reportExcelPassbook);
      }
      else{
        this.toastr.warning('There is no file to download', 'Warning!', {
          timeOut: 1000
        });
      }},
        err => {
          this.show = false;
          this.totaldata = 0;
        }
      )
      this.show = false;
    }
    this.formval.Page = 1;
    this.formval.PageSize = 5;
  }

  onpagechange() {

    this.formval.Page = 1;
    this.getscanreport();
  }
  getDealerListDDL() {
    debugger;
    let obj = {
      UsrId: this.formval.HOUserId
    }
    this.dealerList = [];
    this.siteList = [];
    // this.formval.AccFrmDDL = null;
    // this.formval.DealerId = null;
    this.apiService.PostApi(obj, "HOUser/GetHoDealerForDdl").subscribe((resp: any) => {
      if (resp.ResponseCode == 200) {
        this.dealerList = resp.ResponseData;
      }
      else {
        this.dealerList = [];
      }
    })
  }
  onDealerChange() {
    // this.formval.DealerId=null;
    let x = this.formval.AccFrmDDL;
    let y = x.split(':');
    let obj = {
      AccountNo: y[0]
    }
    this.formval.AccountNo = y[0];
    let UsrType;
      UsrType='C';
    this.apiService.GetApi('0', `Dealer/GetHoDealerSiteForDdl?AccountNo=${obj.AccountNo}&&UsrType=${UsrType}`).subscribe((resp: any) => {
      if (resp.ResponseCode == 200) {
        this.siteList = resp.ResponseData;
      }
    })
  }
  getHoUserForDDL() {
    let obj = {
      UsrId: 0,
      IsActive: '%'
    }
    this.apiService.PostApi(obj, "HOUser/GetHoUsrForDdl").subscribe((resp: any) => {
      if (resp.ResponseCode == 200) {
        this.HoUserlist = resp.ResponseData;
      }
    })
  }
  getscanreport() {
    debugger;
    this.show = false;
    debugger
    let obj={
      page:this.formval.Page,
      pageSize:this.formval.PageSize,
      // filterBY:this.formval.FilterBy,
      // searchValue:this.formval.SearchValue,
      // isActive:this.formval.IsActive,
      // scanStatus:this.formval.ScanStatus,
      // hOUserId:this.formval.HOUserId,
      // accountNo:this.formval.AccountNo,
      dealerId:this.formval.DealerId,
      fromDt:this.formval.fromdate,
      toDt:this.formval.todate,
    }
    this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");

      debugger;

    if (this.formval.fromdate)
      if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {
        this.toastr.warning('From Date Cannot be greater than To Date!', 'Warning!', {
          timeOut: 3000
        });
      }
      else {
        this.scanreportlist = this.scanreportlist.slice(0, 0);
        this.show = true;
        this.apiService.PostApi(obj, "Report/DealerWiseReport").subscribe((response: any) => {
          debugger;
          if (response.ResponseCode == 200) {
            this.scanreportlist = response.ResponseData;
            this.totaldata = response.ResponseData[0].TotalCount;
          }
          else {
            this.scanreportlist = [];
            this.totaldata = 0;
          }
        },
          err => {
            this.scanreportlist = []
            this.totaldata = 0;
            this.show = false;
          })

        this.show = false;

      }
  }



  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;




    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.formval.Page = event.page;
    this.getscanreport();
    this.currentPage = event.page + 1;
  }



  ontypechange() {
    this.pagesUser.page = 1;
    this.getscanreport();

  }


}
