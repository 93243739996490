import { Component, OnInit } from '@angular/core';
import { Login, LoginResponse } from '../models/login';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../Services/api-service';
import { SharedService } from '../Services/shared-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  show:boolean=false;
  formval:Login=new Login();
  display:boolean=false;
  modeldisplay:boolean=false;
  ForgotPwdFrom: FormGroup;
  termsConditionModal: boolean=false;
  constructor(private fb: FormBuilder,private sharedService: SharedService,private service:ApiService, private router: Router,private toastr: ToastrService) { }

  ngOnInit() {
    console.log(localStorage)
    this.ForgotPwdFrom = this.fb.group({
      Email: ['',Validators.required]
    })}

    openTermsConditionModel(){

      this.termsConditionModal = true;
  
    } 
    get u() {
      return this.ForgotPwdFrom.controls
    }
  onSubmit()
  {

  this.show=true;

  this.service.PostApiLogin(this.formval,"Login/LoginAuth").subscribe((response: any) => {
    if(response.ResponseCode=="200")
    {debugger;
      this.show=false;
      sessionStorage.setItem("log","log")
      sessionStorage.setItem("token",response.ResponseData.Tokan)
      localStorage.setItem("name",response.ResponseData.UserData.FirstName+" "+response.ResponseData.UserData.LastName)
      localStorage.setItem("userID",response.ResponseData.UserData.ID);
      localStorage.setItem("Mobile",response.ResponseData.UserData.Mobile);
      localStorage.setItem("usertype",response.ResponseData.UserData.UserType)
      localStorage.setItem("organisation",response.ResponseData.UserData.Organisation)
      localStorage.setItem("organisationname",response.ResponseData.OrganisationName)
      localStorage.setItem("UsrGroup",response.ResponseData.UserData.UsrGroup)
      this.sharedService.refreshNavBar();
    }
    else
    {

      this.show=false;
       this.toastr.warning( response.ResponseMessage,'Error!',{
         timeOut:3000

        });
    }


  },err => {

    this.show=false;
    this.toastr.warning('Please enter valid credentials','Warning!',{
      timeOut:3000

     });

  })}
  ShowforgotPassword() {
   // this.termsConditionModal = true;
    this.display=true;
    this.modeldisplay=false;
    // this.toastrService.info('', 'Please contact to admin...',
    //   {
    //     positionClass: 'toast-top-right'
    //   }
    // );
  }
  SendForgotPassword()
  {debugger;
    if (this.ForgotPwdFrom.invalid) {
      return;
    }

    // if (this.loginForm.controls.remember.value === true) {
    //   // localStorage.setItem('UserName', this.loginForm.controls.UserName.value);
    //   // localStorage.setItem('password', this.loginForm.controls.password.value);
    // }
    else {
      
      this.service.getApi("User/ForgotPasswordWeb?Email="+this.ForgotPwdFrom.controls.Email.value).subscribe((resp: any) => {
debugger;
        if (resp.ResponseCode == "200") {
          this.display=false
          this.modeldisplay=true
            this.toastr.success(resp.ResponseMessage,"Success");  // Success
            
        }
        else {
          
          this.toastr.warning(resp.ResponseMessage,"Warning"); //  Warning
          this.display=false
          this.modeldisplay=true
        }

      },
        (error) => {
          this.display=false
          this.modeldisplay=true
         this.toastr.error('Server Error !!');
        
        //  this.toastrService.error('error de servidor  !!');
        }
        )
  }

}

}
