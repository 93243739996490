import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  @Output() navbar: EventEmitter<any> = new EventEmitter();
  isSessionModalOpen = false;
  isDeactive = false;
  constructor() { }
  refreshNavBar() {

    this.navbar.emit();
  }
}
