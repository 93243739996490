import { Component, OnInit, TemplateRef } from '@angular/core';
import { Mapping, MappingResponse, AvailResponse } from '../models/mapping';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { ToastrService } from 'ngx-toastr';
import { Product, ProductResponse, tokenStockExcel, tokenStockExcelReport } from '../models/product';
import { PackSize, PackSizeResponse } from '../models/packsize';
import { Denomination, DenominationResponse } from '../models/denomination';
import * as moment from 'moment';
import { StockResponse, Stock, StockFromNumberResponse } from '../models/stock';
import { NgForm } from '@angular/forms';
import { ExcelService } from '../excel.service';
import { demapping } from '../models/demapping';
@Component({
  selector: 'app-Productdemappingreport',
  templateUrl: './Productdemappingreport.component.html',
  styleUrls: ['./Productdemappingreport.component.css']
})
export class ProductdemappingreportComponent implements OnInit {
  pagesUser: UserPage = new UserPage();
  formval: demapping = new demapping();
  cnList: any;
  cnExcelList: any[] = [];
  cnExcelListReport: any[] = [];
  show: boolean = false;
  url: string;
  availableStockExcel: any[] = [];
  availableStockExcelReport: any[] = [];
  totaldata: number=0;
  // formval: Stock = new Stock();
  submitForm: NgForm;
  checkFromNumber: string;
  checkToNumber: string;
  constructor(private excelService: ExcelService, private toastr: ToastrService, private modalService: BsModalService, private api: ApiService) {
    this.formval.pageNumber = 1;
    this.formval.pageSize = '5';
    this.pagesUser.Filter = '';
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var currentDay = date.getDate();
    this.formval.fromdate = moment(firstDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(currentDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
  }

  ngOnInit() {
    this.getDemappingreportData()
  }
  getDemappingreportData() {
    debugger;
    let obj = {
      pageNumber: this.formval.pageNumber,
      pageSize: this.formval.pageSize,
      FromDate: this.formval.fromdate,
      ToDate: this.formval.todate,
      organisation: this.formval.OrganisationID = localStorage.getItem("organisation"),
    }
    this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {
      this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
        timeOut: 3000
      });
    }
    else{
    this.api.PostApi(obj, `Report/DemappingReport`).subscribe((resp: any) => {
      debugger;
      if (resp.ResponseCode == 200) {
        this.cnList = resp.ResponseData;
        this.totaldata = resp.TotalCount;
        if (this.cnList.length > 0) {
          for (var i = 0; i < this.cnList.length; i++) {
            this.cnList[i].FromNumber = this.cnList[i].Prefix + this.mypadding(parseInt(this.cnList[i].FromNumber), 9);
            this.cnList[i].ToNumber = this.cnList[i].Prefix + this.mypadding(parseInt(this.cnList[i].ToNumber), 9);
          }
        }
      }
      else{
        this.totaldata = 0;
        this.cnList =[]
      }
    })
  }}
  mypadding(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.formval.pageNumber = event.page;
    this.getDemappingreportData();
  }
  ontypechange() {
    this.pagesUser.page = 1
    this.pagesUser.pageSize = this.formval.pageSize;
    this.getDemappingreportData();

  }
  DownloadCNReport() {
    debugger;
    this.cnExcelListReport = [];
    this.cnExcelList = [];
    let obj = {
      pageNumber: this.formval.pageNumber,
      pageSize: this.totaldata,
      FilterBy: '',
      searchValues: '',
      IsActive: 'true',
      FromDate: this.formval.fromdate,
      ToDate: this.formval.todate,
      organisation: this.formval.OrganisationID = localStorage.getItem("organisation"),
    }
    this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {
      this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
        timeOut: 3000
      });
    }
    this.api.PostApi(obj, `Report/DemappingReport`).subscribe((resp: any) => {
      debugger;
      if (resp.ResponseCode == 200) {
        this.cnExcelList = resp.ResponseData;
        this.totaldata = resp.ResponseData.TotalCount;
        if (this.cnExcelList.length > 0) {
          for (var i = 0; i < this.cnExcelList.length; i++) {
            this.cnExcelList[i].FromNumber = this.cnExcelList[i].Prefix + this.mypadding(parseInt(this.cnExcelList[i].FromNumber), 9);
            this.cnExcelList[i].ToNumber = this.cnExcelList[i].Prefix + this.mypadding(parseInt(this.cnExcelList[i].ToNumber), 9);
          }
        }
        this.cnExcelList.forEach(o => {
          this.cnExcelListReport.push({
            //S_No:o.index ,
            ProductCode: o.ProductCode,
            ProductDescription: o.Description,
            Denomination: o.DenoRem,
            FromNumber: o.FromNumber,
            ToNumber: o.ToNumber,
            Quantity: o.Quantity,
            CreatedDate: o.CreatedDate
          })
        })
        this.exportAsXLSX(this.cnExcelListReport);
        this.show = false;
      }
      else{
        this.toastr.warning('There is no data to download', 'Warning!', {
          timeOut: 1000
        });
      
   } },)
  }
  exportAsXLSX(myreport: any): void {
    this.excelService.exportAsExcelFile(this.cnExcelListReport, 'Demapping Report');
  }

}
