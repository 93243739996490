import { Component, OnInit, TemplateRef } from '@angular/core';
import { Mapping, MappingResponse, AvailResponse } from '../models/mapping';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { ToastrService } from 'ngx-toastr';
import { Product, ProductResponse,tokenStockExcel,tokenStockExcelReport} from '../models/product';
import { PackSize, PackSizeResponse } from '../models/packsize';
import { Denomination, DenominationResponse } from '../models/denomination';
import * as moment from 'moment';
import { StockResponse, Stock, StockFromNumberResponse } from '../models/stock';
import { NgForm } from '@angular/forms';
import { ExcelService } from '../excel.service';
import { ScanReport } from '../models/report';
@Component({
  selector: 'app-Loyaltyschemereport',
  templateUrl: './Loyaltyschemereport.component.html',
  styleUrls: ['./Loyaltyschemereport.component.css']
})
export class LoyaltyschemereportComponent implements OnInit {
  pagesUser: UserPage = new UserPage();
  formval: ScanReport = new ScanReport();
  cnList:any;
  cnExcelList:any[]=[];
  cnExcelListReport:any[]=[];
  show: boolean = false;
  url: string;
  availableStockExcel:any[]=[];
  availableStockExcelReport:any[]=[];
  totaldata: number = 0;
  // formval: Stock = new Stock();
  submitForm: NgForm;
  checkFromNumber: string;
  checkToNumber: string;
  mappinglist: any;
  organisation: string;
  constructor(private excelService: ExcelService,private toastr: ToastrService, private modalService: BsModalService, private api: ApiService) {
    this.formval.pageNumber=1;
    this.formval.PageSize=5;
    this.pagesUser.Filter='';
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var currentDay = date.getDate()  ;
    this.formval.fromdate = moment(firstDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(currentDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
  }
  ngOnInit() {
    this.getCNreportData()
  }
  getCNreportData()
  {debugger;
    let obj ={
      pageNumber:this.formval.pageNumber,
      pageSize:this.formval.PageSize,
      FilterBy:'',
      searchValues:'',
      IsActive:'',
      HoUserID:0,
      FromDate: this.formval.fromdate,
      ToDate: this.formval.todate,
      organisation:this.organisation = localStorage.getItem("organisation")
    }
    this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {
      this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
        timeOut: 3000
      });
    }
    else{

    
    this.api.PostApi(obj,"Report/LoyaltyReport").subscribe((response: any) => {
      //  (response)
      debugger;
       this.mappinglist=response.ResponseData;
       if(this.mappinglist.length>0)
       {
         for(var i=0;i<this.mappinglist.length;i++)
         {
           this.mappinglist[i].FromNumber=this.mappinglist[i].Prefix+this.mypadding(parseInt(this.mappinglist[i].FromNumber),9);
           this.mappinglist[i].ToNumber=this.mappinglist[i].Prefix+this.mypadding(parseInt(this.mappinglist[i].ToNumber),9);
         }
       }
       this.totaldata=response.ResponseData[0].TotalCount;
       this.show=false;
     })
     this.show=false;
    }}
  mypadding(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.formval.pageNumber = event.page;
    this.getCNreportData();
  }
  ontypechange() {
    this.pagesUser.page = 1
    //this.pagesUser.pageSize=this.formval.PageSize;
    this.getCNreportData();
  }
  DownloadCNReport()
  {debugger;
    this.cnExcelListReport=[];
    this.cnExcelList=[];
    let obj={
      pageNumber:this.formval.pageNumber,
      pageSize:this.totaldata,
      FilterBy:'',
      searchValues:'',
      IsActive:'true',
      FromDate: this.formval.fromdate,
      ToDate: this.formval.todate
    }
    //this.formval.fromdate.setHours(this.formval.fromdate.getHours() + 5);
    this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    //var fromDate=this.formval.fromdate
    //
    //alert(new Date().addHours(4));
    this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {
      this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
        timeOut: 3000
      });
    }
    this.api.PostApi(obj,`Report/LoyaltyReport`).subscribe((resp:any)=>{
      if(resp.ResponseCode==200)
      {debugger;
        this.cnExcelList=resp.ResponseData;
        this.cnExcelList.forEach(o=>{
          this.cnExcelListReport.push({
            //S_NO:o.index,
            Name:o.ContractorNm,
            Mobile:o.Mobile,
            Points:o.Points,
            Location:o.Location,
            Date:o.Date
          })
        })
        this.exportAsXLSX(this.cnExcelListReport);
        this.show = false;
      }
      else{
        this.toastr.warning('There is no data to download', 'Warning!', {
          timeOut: 1000
        });
    }},)
  }
  exportAsXLSX(myreport: any): void {
    this.excelService.exportAsExcelFile(this.cnExcelListReport, 'Loyalty Report');
  }
}