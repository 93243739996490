import { Component, OnInit, TemplateRef } from '@angular/core';
import { Mapping, MappingResponse, AvailResponse } from '../models/mapping';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { ToastrService } from 'ngx-toastr';
import { Product, ProductResponse,tokenStockExcel,tokenStockExcelReport} from '../models/product';
import { PackSize, PackSizeResponse } from '../models/packsize';
import { Denomination, DenominationResponse } from '../models/denomination';
import * as moment from 'moment';
import { StockResponse, Stock, StockFromNumberResponse } from '../models/stock';
import { NgForm } from '@angular/forms';
import { ExcelService } from '../excel.service';
@Component({
  selector: 'app-Damagereport',
  templateUrl: './Damagereport.component.html',
  styleUrls: ['./Damagereport.component.css']
})
export class DamagereportComponent implements OnInit {
  pagesUser: UserPage = new UserPage();
  formval: Stock = new Stock();
  cnList:any;
  cnExcelList:any[]=[];
  cnExcelListReport:any[]=[];
  show: boolean = false;
  url: string;
  availableStockExcel:any[]=[];
  availableStockExcelReport:any[]=[];
  totaldata: number = 0;
  // formval: Stock = new Stock();
  submitForm: NgForm;
  checkFromNumber: string;
  checkToNumber: string;
  mappinglist: any;
  organisation: string;
  constructor(private excelService: ExcelService,private toastr: ToastrService, private modalService: BsModalService, private api: ApiService) {
    this.formval.pageNumber=1;
    this.formval.pageSize='5';
    this.pagesUser.Filter='FromNumber';
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var currentDay = date.getDate();
    this.formval.fromdate = moment(firstDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(currentDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
  }

  ngOnInit() {
    this.getCNreportData()
  }
  getCNreportData()
  {debugger;
    let obj ={
      page:this.pagesUser.page,
      pageSize:this.formval.pageSize,
      FilterBy:'',
      searchValue:'',
      IsActive:'true',
      HoUserID:localStorage.getItem("userID"),
      FromDate: this.formval.fromdate,
      ToDate: this.formval.todate,
      Organisation:this.organisation = localStorage.getItem("organisation"),
    }
    this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {
      this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
        timeOut: 3000
      });
    }
    else{
    this.api.PostApi(obj,"Damage/GetDamage").subscribe((response: any) => {
      debugger;
      //  (response)
      if(response.ResponseCode==200){
       this.mappinglist=response.ResponseData;
       this.totaldata=response.ResponseData[0].TotalCount;
       if(this.mappinglist.length>0)
       {
         for(var i=0;i<this.mappinglist.length;i++)
         {
           this.mappinglist[i].FromNumber=this.mappinglist[i].Prefix+this.mypadding(parseInt(this.mappinglist[i].FromNumber),9);
           this.mappinglist[i].ToNumber=this.mappinglist[i].Prefix+this.mypadding(parseInt(this.mappinglist[i].ToNumber),9);
         }
       }
       
       this.show=false;
     }
    else{
      this.totaldata=0;
      this.mappinglist=[]
    }})
     this.show=false;
    }}
  mypadding(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.pagesUser.page = event.page;
    this.getCNreportData();
    //this.currentPage = event.page + 1;
  }
  ontypechange() {
    this.pagesUser.page = this.formval.pageNumber;
    this.pagesUser.pageSize=this.formval.pageSize;
    this.getCNreportData();
  }
  DownloadCNReport()
  {debugger;
    this.cnExcelListReport=[];
    this.cnExcelList=[];
    let obj={
      page:this.pagesUser.page,
      pageSize:this.totaldata,
      FilterBy:'',
      searchValue:'',
      IsActive:'true',
      FromDate: this.formval.fromdate,
      ToDate: this.formval.todate,
      Organisation:this.organisation = localStorage.getItem("organisation"),
    }
    this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {
      this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
        timeOut: 3000
      });
    }
    this.api.PostApi(obj,`Damage/GetDamage`).subscribe((resp:any)=>{
      if(resp.ResponseCode==200)
      {debugger;
        this.cnExcelList=resp.ResponseData;
        if(this.cnExcelList.length>0)
        {
          for(var i=0;i<this.cnExcelList.length;i++)
          {
            this.cnExcelList[i].FromNumber=this.cnExcelList[i].Prefix+this.mypadding(parseInt(this.cnExcelList[i].FromNumber),9);
            this.cnExcelList[i].ToNumber=this.cnExcelList[i].Prefix+this.mypadding(parseInt(this.cnExcelList[i].ToNumber),9);
          }
        }
        
        this.cnExcelList.forEach(o=>{
          this.cnExcelListReport.push({
            //S_No:o.RowNum,
            Denomination:o.DenoRem,
            From_Number:o.FromNumber,
            To_Number:o.ToNumber,
            Quantity:o.Quantity,
            Created_Date:moment(o.CreatedDate, "YYYY-MM-DD").format("MMM-DD-YYYY")
          })
        })
        this.exportAsXLSX(this.cnExcelListReport);
        this.show = false;
      }
      else{
        this.toastr.warning('There is no data to download', 'Warning!', {
          timeOut: 1000
        });
  }},)
  }
  exportAsXLSX(myreport: any): void {
    this.excelService.exportAsExcelFile(this.cnExcelListReport, 'Damage Report');
  }

}
