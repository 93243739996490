export class prize 
{
    Option3: string;
  TotalPoints: number;
  Points: number;
  Option1: string;
  Option2: string;
  Option4: string;
  IsActive: boolean;
  ID:any;
}
export class prizeResponse
{
    ResponseCode: string;
  ResponseMessage: string;
  ResponseData: prize[];
  TotalCount:number;
}