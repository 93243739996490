export class Login {
    UserId: string;
    Password: string;
    DeviceType: string = "2";
    FirstName: string;
    LastName: string;
    Mobile: string;
    UserType: string;
    ID: string;
    Organisation:string;


}


export class LoginResponse {

    ResponseData: UserData;
    ResponseCode: string;
    ResponseMessage: string;


}

export class UserData {

    UserData: Login;
    Tokan: string;
    OrganisationName:string;

}

export class UserPage {
    pageSize: string ="5";
    UsrGroup:string="0";
    GroupId:number=0;
    GroupCd:string="0";
    UsrDesigRpt:string="0"
    UsrDeptmnt:number=0;
    DeptId:string="0";
    Organisation: number=1;
    page: number = 1;
    Filter: string = "";
    searchValue: string = "";
    IsTransferred:string;
    IsReceived:string;
    IsActive: string = "true";
    UserType: number=4;
    HologramID: number;
    FilterBY:string;
    fromdate:string;
    todate:string;
}
