import { Component, OnInit, TemplateRef } from '@angular/core';
import { Mapping, MappingResponse, AvailResponse } from '../models/mapping';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { ToastrService } from 'ngx-toastr';
import { Product, ProductResponse,tokenStockExcel,tokenStockExcelReport} from '../models/product';
import { PackSize, PackSizeResponse } from '../models/packsize';
import { Denomination, DenominationResponse } from '../models/denomination';
import * as moment from 'moment';
import { StockResponse, Stock, StockFromNumberResponse } from '../models/stock';
import { NgForm } from '@angular/forms';
import { ExcelService } from '../excel.service';
@Component({
  selector: 'app-Productmappingreport',
  templateUrl: './Productmappingreport.component.html',
  styleUrls: ['./Productmappingreport.component.css']
})
export class ProductmappingreportComponent implements OnInit {
  pagesUser: UserPage = new UserPage();
  formval: Stock = new Stock();
  cnList:any;
  cnExcelList:any[]=[];
  cnExcelListReport:any[]=[];
  show: boolean = false;
  url: string;
  availableStockExcel:any[]=[];
  availableStockExcelReport:any[]=[];
  totaldata: number;
  // formval: Stock = new Stock();
  submitForm: NgForm;
  checkFromNumber: string;
  checkToNumber: string;
  mappinglist: Mapping[];
  organisation: string;
  constructor(private excelService: ExcelService,private toastr: ToastrService, private modalService: BsModalService, private api: ApiService) {
    this.formval.pageNumber=1;
    this.formval.pageSize='5';
    this.pagesUser.Filter='';
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var currentDay = date.getDate();
    this.formval.fromdate = moment(firstDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(currentDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
  }
  ngOnInit() {
    this.getCNreportData()
  }
  getCNreportData()
  {debugger;
    let obj ={
      pageNumber:this.formval.pageNumber,
      pageSize:this.formval.pageSize,
      // FilterBy:'',
      // searchValues:'',
      // IsActive:'true',
      // HoUserID:localStorage.getItem("userID"),
      Organisation:this.organisation = localStorage.getItem("organisation"),
      fromDate: this.formval.fromdate,
      toDate: this.formval.todate
    }
    this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {
      this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
        timeOut: 3000
      });
    }
    this.api.PostApi(obj, 'Mapping/GetMappingReport').subscribe((response: MappingResponse) => {
      debugger;
      if(response.ResponseCode == '200'){
      this.totaldata = response.ResponseData.TotalCount;
      this.mappinglist = response.ResponseData.MappingData;
      if (this.mappinglist.length > 0) {
        for (var i = 0; i < this.mappinglist.length; i++) {
          this.mappinglist[i].FromNumber = this.mappinglist[i].Prefix + this.mypadding(parseInt(this.mappinglist[i].FromNumber), 9);
          this.mappinglist[i].ToNumber = this.mappinglist[i].Prefix + this.mypadding(parseInt(this.mappinglist[i].ToNumber), 9);
        }
      }
      //  (this.totaldata)
      //this.totaldata = this.totaldata;
    }
  else{
    this.totaldata = 0;
    this.mappinglist =[]
  }
 } )
  }
  mypadding(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.formval.pageNumber = event.page;
    this.getCNreportData();
  }
  ontypechange() {
    this.pagesUser.page = 1
    this.pagesUser.pageSize=this.formval.pageSize;
    this.getCNreportData();
  }
  DownloadCNReport()
  {debugger
    this.cnExcelListReport=[];
   this.cnExcelList=[];
    let obj={
      pageNumber:this.formval.pageNumber,
      pageSize:this.totaldata,
      // FilterBy:'',
      // searchValues:'',
      // IsActive:'true',
      //organisation : 1,
      //HoUserID:localStorage.getItem("userID"),
     Organisation:this.organisation = localStorage.getItem("organisation"),
      fromDate: this.formval.fromdate,
      toDate: this.formval.todate
    }
    this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {
      this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
        timeOut: 3000
      });
    }
    this.api.PostApi(obj,'Mapping/GetMappingReport').subscribe((response:any)=>{
      debugger;
      if(response.ResponseCode==200)
      {
        this.cnExcelList=response.ResponseData.MappingData;
        if(this.cnExcelList.length>0)
        {
          for(var i=0;i<this.cnExcelList.length;i++)
          {
            this.cnExcelList[i].FromNumber=this.cnExcelList[i].Prefix+this.mypadding(parseInt(this.cnExcelList[i].FromNumber),9);
            this.cnExcelList[i].ToNumber=this.cnExcelList[i].Prefix+this.mypadding(parseInt(this.cnExcelList[i].ToNumber),9);
          }
        }
        this.cnExcelList.forEach(o=>{
          this.cnExcelListReport.push({
            //Allocation_ID:o.ID,
            Product_Code:o.ProductCode,
            Product_Description:o.Description,
            Denomination:o.DenoRem,
            From_Number:o.FromNumber,
            To_Number:o.ToNumber,
            Quantity:o.Quantity,
            Batch_No:o.BatchNo,
            Created_Date:o.CreatedDate
          })
        })
        this.exportAsXLSX(this.cnExcelListReport);}
        else{
          this.toastr.warning('There is no data to download', 'Warning!', {
            timeOut: 1000
          });
        this.show = false;
      }
    })
  }
  exportAsXLSX(myreport: any): void {
    this.excelService.exportAsExcelFile(this.cnExcelListReport, 'Product Mapping Report');
  }
}