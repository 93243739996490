import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {RadioButtonModule} from 'primeng/radiobutton';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule,} from '@angular/forms'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ApiService } from './Services/api-service';
import { UsermasterComponent } from './usermaster/usermaster.component';
import {TableModule} from 'primeng/table';
import { PaginationModule } from 'ngx-bootstrap';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { PaintermasterComponent } from './paintermaster/paintermaster.component';
import { MappingComponent } from './mapping/mapping.component';
import { RetailermasterComponent } from './retailermaster/retailermaster.component';
import { SalesMasterComponent } from './salesmaster/salesmaster.component';
import {FileUploadModule} from 'primeng/fileupload';
import {AccordionModule} from 'primeng/accordion';     //accordion and accordion tab
import {MenuItem} from 'primeng/api';                 //api
import { ExcelService } from './excel.service';
import { ProductmasterComponent } from './productmaster/productmaster.component';
import { StockComponent } from './stock/stock.component';
import { DemappingComponent } from './demapping/demapping.component';
import { DamageComponent } from './damage/damage.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { ScanlogreportComponent } from './scanlogreport/scanlogreport.component';
import {CalendarModule} from 'primeng/calendar';
import { PassbookreportComponent } from './passbookreport/passbookreport.component';
import { OrganisationComponent } from './organisation/organisation.component';
import { DenominationsettingComponent } from './denominationsetting/denominationsetting.component';
import { StocktransferComponent } from './stocktransfer/stocktransfer.component';
import { StockreceiveComponent } from './stockreceive/stockreceive.component';
import { TransferreportComponent } from './transferreport/transferreport.component';
import { ReceiveReportComponent } from './receive-report/receive-report.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SblTypeReportComponent } from './sbl-type-report/sbl-type-report.component'
import { HoUserComponent } from './ho-user/ho-user.component';
import {DatePipe} from '@angular/common';
import { PassbookreportnewComponent } from './passbookreportnew/passbookreportnew.component';
import { ScanlogreportnewComponent } from './scanlogreportnew/scanlogreportnew.component';
import { DealemasternewComponent } from './dealemasternew/dealemasternew.component';
import { TokenStockReportComponent } from './token-stock-report/token-stock-report.component';
import { AvailableStockReportComponent } from './available-stock-report/available-stock-report.component';
import { CNReportComponent } from './cn-report/cn-report.component';
import { DUMPReportComponent } from './dump-report/dump-report.component';
import { CouponconfigurationComponent } from './CouponConfiguration/CouponConfiguration.component';
import { RewardfulfillmentComponent } from './Rewardfulfillment/Rewardfulfillment.component';
import { ProductmappingreportComponent } from './Productmappingreport/Productmappingreport.component';
import { ProductdemappingreportComponent } from './Productdemappingreport/Productdemappingreport.component';
import { DamagereportComponent } from './damagereport/damagereport.component';
import { DistributermasterComponent } from './DistributerMaster/DistributerMaster.component';
import { PrizeComponent} from './Prize/Prize.component';
import { FeedbackComponent} from './Feedback/Feedback.component';
import { LoyaltyschemereportComponent} from './Loyaltyschemereport/Loyaltyschemereport.component';
// import { DashboardComponent}from './dashboard/dashboard.component';
import { DealerreportComponent} from './Dealerreport/Dealerreport.component';
import {ProductwisereportComponent} from './Productwisereport/Productwisereport.component'
import { DialogModule } from "primeng/dialog";
import {DropdownModule} from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipsModule } from 'primeng/chips';

@NgModule({
  declarations: [
   
   
    AppComponent,
    LoginComponent,
    NavbarComponent,
    HomeComponent,
    UsermasterComponent,
    PaintermasterComponent,
    SblTypeReportComponent,
    MappingComponent,
    RetailermasterComponent,
    SalesMasterComponent,
    ProductmasterComponent,
    StockComponent,
    DemappingComponent,
    DamageComponent,
    ChangepasswordComponent,
    ScanlogreportComponent,
    PassbookreportComponent,
    OrganisationComponent,
    DenominationsettingComponent,
    StocktransferComponent,
    StockreceiveComponent,
    TransferreportComponent,
    ReceiveReportComponent,
    PrivacyPolicyComponent,
    SblTypeReportComponent,
    HoUserComponent,
    PassbookreportnewComponent,
    ScanlogreportnewComponent,
    DealemasternewComponent,
    SblTypeReportComponent,
    TokenStockReportComponent,
    AvailableStockReportComponent,
    CNReportComponent,
    DUMPReportComponent,
    CouponconfigurationComponent,
    RewardfulfillmentComponent,
    ProductmappingreportComponent,
    PrizeComponent,
    ProductdemappingreportComponent,
    DamagereportComponent,
    DistributermasterComponent,
    FeedbackComponent,
    LoyaltyschemereportComponent,
    // DashboardComponent,
    DealerreportComponent,
    ProductwisereportComponent,
  ],
  imports: [
    ChipsModule,
    BrowserModule,
    AppRoutingModule,
    MultiSelectModule,
    DropdownModule,
    DialogModule,
    ReactiveFormsModule,
    TableModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    RadioButtonModule,
    FileUploadModule,
    CalendarModule,
    AccordionModule,
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot()
  ],
  providers: [ExcelService,DatePipe],
  bootstrap: [AppComponent],

})
export class AppModule { }
