export class Stock
{
    fromdate:string;
    pageSize:string;
    pageNumber :number;
    todate:string;
    FromNumber:string;
    ToNumber:string;
    Quantity:string;
    TotalCount:number
    isEdit:true;
    Denomination:string;
    UserType: number =0;
;
    ID:string;
    HologramID:number;
    Organisation:number;
    OrganisationID:string;
    IsActive:boolean=true;
    Prefix:string;
    CreatedBy:string;
    Status:string;
    isThirdPartyUser: boolean = false;
    IsEditable:boolean;
    Remarks:string;
    ProductCode:string;
    Description:string;
    fromNumber:number;
    toNumber:number;
    CreatedDate:string;

}
export class StockResponse
{
    ResponseCode:string;
    ResponseData:Stock[];
    ResponseMessage:string;
    TotalCount:number;
}
export class StockFromNumberResponse
{
    ResponseCode:string;
    ResponseData:string;
    ResponseMessage:string;
}