import { Component, OnInit, TemplateRef } from '@angular/core';
import { Mapping, MappingResponse, AvailResponse } from '../models/mapping';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { ToastrService } from 'ngx-toastr';
import { Product, ProductResponse } from '../models/product';
import { PackSize, PackSizeResponse } from '../models/packsize';
import { Denomination, DenominationResponse } from '../models/denomination';
import { StockResponse, Stock, StockFromNumberResponse } from '../models/stock';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
@Component({
  selector: 'app-damage',
  templateUrl: './damage.component.html',
  styleUrls: ['./damage.component.css']
})
export class DamageComponent implements OnInit {
  show: boolean = false;
  formval: Stock = new Stock();
  modalHead: string = "Add Stock Mapping";
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  mappings: Mapping[] = [];
  avails: Stock[] = [];
  products: Product[] = [];
  productlist: Product[] = [];
  availlist: Mapping[] = [];
  mappinglist: Stock[] = [];
  totaldata: number;
  availtotaldata: number;
  producttotaldata: number;
  onEdit: boolean = false;
  pagesUser: UserPage = new UserPage();
  pagesUserStock: UserPage = new UserPage();
  pagesUserProduct: UserPage = new UserPage();
  packsizelist: PackSize[] = [];
  denominationlist: Denomination[] = [];
  DenominationName: string;
  deleteId: string;
  submitForm: NgForm;
  minFromNo: number;
  maxToNo: number;
  from: string;
  to: string;
  isAvailableDisabled: boolean;
  constructor(private toastr: ToastrService, private modalService: BsModalService, private apiService: ApiService) {
    this.pagesUser.Filter = "FromNumber";
    this.formval.HologramID = 0;
  }
  ngOnInit() {
    this.formval.fromdate = "";
    this.formval.todate = "";
    this.formval.OrganisationID = localStorage.getItem("organisation");
    this.pagesUser.HologramID = 0;
    this.getstocklistapi();
    this.getDenominationList();
    this.deleteId = "0";
    this.onEdit = false;
    this.formval.CreatedBy = localStorage.getItem("usertype");
    this.onChangeDenomination(this.formval.HologramID);
    this.isAvailableDisabled = true;
  }
  deletedamage(template: TemplateRef<any>, summary: Stock) {
    this.deleteId = { ...summary }.ID;
    let config = { backdrop: true, ignoreBackdropClick: true };
    // this.modalHead="Add Coupon Stock";
    this.modalRef2 = this.modalService.show(template, config);
  }
  openModal(template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
    this.modalRef = this.modalService.show(template, config);
  }
  openModal2(template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
    this.modalRef2 = this.modalService.show(template, config);
  }
  onutypechange() {
    this.getstocklistapi();
  }
  calc(val: number) {
    if (this.formval.Quantity == '') {
      this.formval.ToNumber = '';
    }
    else {
      for (var i = 0; i < this.denominationlist.length; i++) {
        if (this.formval.HologramID == this.denominationlist[i].ID) {
          this.formval.Prefix = this.denominationlist[i].Prefix;
        }
      }
      var len = this.formval.FromNumber.length;
      if (len == 15) {
        this.formval.FromNumber = this.formval.FromNumber.substring(7, len);
      }
      else if (len == 16) {
        this.formval.FromNumber = this.formval.FromNumber.substring(8, len);
      }
      //alert(this.formval.FromNumber)
      if (parseInt(this.formval.FromNumber) == 0 || this.formval.FromNumber == '' || this.formval.FromNumber == 'NaN' || this.formval.FromNumber == 'undefined') {
        this.formval.FromNumber = '';
        this.formval.ToNumber = '';
        this.formval.Quantity = '';
      }
      else if (parseInt(this.formval.Quantity) == 0) {
        this.formval.ToNumber = '';
        this.formval.Quantity = '';
        this.formval.FromNumber = this.formval.Prefix + this.formval.FromNumber;
      }
      else {
        var txtfromNo = parseInt(this.formval.FromNumber);
        var txtqtyNo = parseInt(this.formval.Quantity);
        var txttoNo = txtfromNo + txtqtyNo - 1;
        this.formval.ToNumber = txttoNo.toString();
        this.formval.FromNumber = this.formval.Prefix + this.mypadding(parseInt(this.formval.FromNumber), 9);
        this.formval.ToNumber = this.formval.Prefix + this.mypadding(parseInt(this.formval.ToNumber), 9);
      }
    }
    // if(parseInt(this.formval.FromNumber)==0||parseInt(this.formval.FromNumber)==0|| this.formval.Quantity=='' || this.formval.FromNumber==''|| this.formval.Quantity==undefined||this.formval.FromNumber==undefined)
    // {
    //   this.formval.ToNumber='';
    // }
    // else
    // {
    //   if(parseInt(this.formval.FromNumber)==0)
    //   {
    //     this.formval.FromNumber='';
    //     this.formval.ToNumber='';
    //   }
    //   else if(parseInt(this.formval.Quantity)==0)
    //   {
    //     this.formval.ToNumber='';
    //     this.formval.Quantity='';
    //   }
    //   else
    //   {
    //     var txtfromNo = parseInt(this.formval.FromNumber);
    //     var txtqtyNo=parseInt(this.formval.Quantity);
    //     var txttoNo=txtfromNo+txtqtyNo-1;
    //     this.formval.ToNumber=txttoNo.toString();
    //   }
  }
  getFromNumberByDenomination() {
    this.formval.FromNumber = "1";
    this.apiService.PostApi(this.pagesUser, "Stock/GetFromNumber?HologramID=" + this.formval.HologramID).subscribe((response: StockFromNumberResponse) => {
      this.formval.FromNumber = response.ResponseData
      this.calc(parseInt(this.formval.FromNumber));
    })
    if (this.formval.HologramID == 0) {
      this.formval.FromNumber = "";
      this.formval.ToNumber = "";
      this.formval.Quantity = "";
    }
  }
  calc2(val: number) {
    debugger;
    if (this.formval.Quantity == '') {
      this.formval.ToNumber = '';
    }
    else {
      for (var i = 0; i < this.denominationlist.length; i++) {
        if (this.formval.HologramID == this.denominationlist[i].ID) {
          this.formval.Prefix = this.denominationlist[i].Prefix;
        }
      }
      var len = this.formval.FromNumber.length;
      if (len == 15) {
        this.formval.FromNumber = this.formval.FromNumber.substring(7, len);
      }
      else if (len == 16) {
        this.formval.FromNumber = this.formval.FromNumber.substring(8, len);
      }
      //alert(this.formval.FromNumber)
      if (parseInt(this.formval.FromNumber) == 0 || this.formval.FromNumber == '' || this.formval.FromNumber == 'NaN' || this.formval.FromNumber == 'undefined') {
        this.formval.FromNumber = '';
        this.formval.ToNumber = '';
        this.formval.Quantity = '';
      }
      else if (parseInt(this.formval.Quantity) == 0) {
        this.formval.ToNumber = '';
        this.formval.Quantity = '';
        this.formval.FromNumber = this.formval.Prefix + this.formval.FromNumber;
      }
      else {
        var txtfromNo = parseInt(this.formval.FromNumber);
        var txtqtyNo = parseInt(this.formval.Quantity);
        var txttoNo = txtfromNo + txtqtyNo - 1;
        this.formval.ToNumber = txttoNo.toString();
        this.formval.FromNumber = this.formval.Prefix + this.mypadding(parseInt(this.formval.FromNumber), 9);
        this.formval.ToNumber = this.formval.Prefix + this.mypadding(parseInt(this.formval.ToNumber), 9);
      }
    }
  }
  DeleteDamage() {
    this.apiService.PostApi(null, "Damage/DeleteDamage?Id=" + this.deleteId).subscribe((response: StockResponse) => {
      if (response.ResponseCode == "200") {
        this.toastr.success("Damage Stock Successfully Saved", "Success!");
      }
      else {
        this.toastr.warning(response.ResponseMessage, "Warning!")
      }
    })
    this.modalRef2.hide();
    this.getstocklistapi();
  }
  mypadding(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  getstocklistapi() {
    this.show = true;
    this.mappinglist = this.mappinglist.slice(0, 0);
    this.pagesUser.fromdate = "";
    this.pagesUser.todate = "";
    debugger
    this.apiService.PostApi(this.pagesUser, "Damage/GetDamage").subscribe((response: any) => {
      debugger;
      //  (response)
      this.mappinglist = response.ResponseData;
      if (this.mappinglist.length > 0) {
        for (var i = 0; i < this.mappinglist.length; i++) {
          this.mappinglist[i].FromNumber = this.mappinglist[i].Prefix + this.mypadding(parseInt(this.mappinglist[i].FromNumber), 9);
          this.mappinglist[i].ToNumber = this.mappinglist[i].Prefix + this.mypadding(parseInt(this.mappinglist[i].ToNumber), 9);
        }
      }
      this.totaldata = response.ResponseData[0].TotalCount;
      this.show = false;
    })
    this.show = false;
  }
  onSubmit(f: NgForm) {
    debugger;
    if ((this.formval.FromNumber == null || this.formval.FromNumber == undefined || this.formval.FromNumber == '')||
    (this.formval.ToNumber == null || this.formval.ToNumber == undefined || this.formval.ToNumber == '')||
    ( this.formval.Quantity == null || this.formval.Quantity == undefined || this.formval.Quantity == '')
    || 
    (this.formval.HologramID == null || this.formval.HologramID==0 || this.formval.HologramID==undefined) 
  )
    {
      this.toastr.warning('Please fill all required details','Warning!',{
        timeOut:1000

       });
       this.formval.FromNumber='';
       this.formval.ToNumber ='';
       this.formval.Quantity='';
      return
    }
    else{
    this.submitForm = f;
    this.show = true;
    var flen = this.formval.FromNumber.length;
    var tlen = this.formval.ToNumber.length;
    if (flen == 15) {
      this.formval.FromNumber = this.formval.FromNumber.substring(7, flen);
      this.formval.ToNumber = this.formval.ToNumber.substring(7, tlen);
    }
    else if (flen == 16) {
      this.formval.FromNumber = this.formval.FromNumber.substring(8, flen);
      this.formval.ToNumber = this.formval.ToNumber.substring(8, tlen);
    }
    this.saveStock();
  }}
  numberOnly(event): boolean {
    debugger;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  saveStock() {
    debugger;
    if ((this.formval.FromNumber == null || this.formval.FromNumber == undefined || this.formval.FromNumber == '')||
    (this.formval.ToNumber == null || this.formval.ToNumber == undefined || this.formval.ToNumber == '')||
    ( this.formval.Quantity == null || this.formval.Quantity == undefined || this.formval.Quantity == '')
    || 
    (this.formval.HologramID == null || this.formval.HologramID==0 || this.formval.HologramID==undefined) 
  )
    {
      this.toastr.warning('Please fill all required details','Warning!',{
        timeOut:1000

       });
      return
    }
    var flen = this.formval.FromNumber.length;
    var tlen = this.formval.ToNumber.length;
    if(flen==15){
    // this.formval.FromNumber = this.formval.FromNumber.substring(7, flen);
    // this.formval.ToNumber = this.formval.ToNumber.substring(7, tlen);
    this.maxToNo = parseInt(this.formval.ToNumber.substring(7, tlen));
    this.minFromNo = parseInt(this.formval.FromNumber.substring(7, flen));
    }
    else if(flen==16){
      // this.formval.FromNumber = this.formval.FromNumber.substring(8, flen);
      // this.formval.ToNumber = this.formval.ToNumber.substring(8, tlen);
      this.maxToNo = parseInt(this.formval.ToNumber.substring(8, tlen));
      this.minFromNo = parseInt(this.formval.FromNumber.substring(8, flen));
      }
    let usertype = localStorage.getItem("usertype");
    let isThirdPartyUser
    if (usertype == '11') {
      this.formval.isThirdPartyUser = true
    } else {
      this.formval.isThirdPartyUser = false
    }
    if (parseInt(this.formval.FromNumber) <= 0 || parseInt(this.formval.ToNumber) <= 0 || parseInt(this.formval.Quantity) <= 0) {
      this.toastr.warning("Please fill correct details", "Warning!")
      this.show = false;
      return false
    }
    else if (parseInt(this.formval.FromNumber) < this.minFromNo || parseInt(this.formval.ToNumber) > this.maxToNo) {
      this.toastr.warning("Numbers out of Range", 'Warning!', {
        timeOut: 3000
      });
     
      this.show = false;
      return false
    }
    else {
      this.apiService.PostApi(this.formval, "Damage/SaveDamage").subscribe((response: StockResponse) => {
        (response)
        if (response.ResponseCode == "200") {
          this.submitForm.resetForm();
          this.show = false;
          this.toastr.success("Damage Stock Successfully Saved", "Success!")
          this.getstocklistapi();
          this.resetmodal();
        }
        else {
          this.toastr.warning(response.ResponseMessage, "Warning!")
          this.show = false;
        }
      }, err => {
        this.toastr.warning(err.error.ResponseMessage, "Warning!")
        this.show = false;
      })
    }
  }
  editstock(template: TemplateRef<any>, summary: Stock) {
    this.formval = { ...summary };
    this.onEdit = true;
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
    this.modalRef = this.modalService.show(template, config);
  }
  resetmodal() {
    this.modalRef.hide();
    this.onEdit = false;
    this.formval.FromNumber = '';
    this.formval.HologramID = 0;
    this.formval.ToNumber = '';
    this.formval.Quantity = '';
    this.formval.Remarks='';
    this.isAvailableDisabled = false;
    this.availlist=null;
    this.from=null;
    this.to=null
  }
  closemodal2() {
    this.modalRef2.hide();
  }
  closemodal3() {
    this.modalRef3.hide();
  }
  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.pagesUser.page = event.page;
    this.getstocklistapi();
  }
  ontypechange() {
    this.pagesUser.page = 1
    this.getstocklistapi();
  }
  getDenominationList() {
    this.apiService.PostApi(this.pagesUserStock, "Other/GetLabelDenomination").subscribe((response: DenominationResponse) => {
      if (response.ResponseCode == "200") {
        this.denominationlist = response.ResponseData;
      }
    })
  }
  selectstock(formval: Mapping) {
    debugger;
      ;
    this.modalRef2.hide();
    this.formval.FromNumber = formval.FromNumber;
    this.formval.ToNumber = formval.ToNumber;
    this.formval.Quantity = formval.Quantity;
    this.formval.Prefix = formval.Prefix;
    var flen = this.formval.FromNumber.length;
    var tlen = this.formval.ToNumber.length;
    if(flen==15){
    // this.formval.FromNumber = this.formval.FromNumber.substring(7, flen);
    // this.formval.ToNumber = this.formval.ToNumber.substring(7, tlen);
    this.maxToNo = parseInt(this.formval.ToNumber.substring(7, tlen));
    this.minFromNo = parseInt(this.formval.FromNumber.substring(7, flen));
    }
    else if(flen==16){
      // this.formval.FromNumber = this.formval.FromNumber.substring(8, flen);
      // this.formval.ToNumber = this.formval.ToNumber.substring(8, tlen);
      this.maxToNo = parseInt(this.formval.ToNumber.substring(8, tlen));
      this.minFromNo = parseInt(this.formval.FromNumber.substring(8, flen));
      }
   
    this.from = this.formval.FromNumber;
    this.to = this.formval.ToNumber;
  }
  getAvailableStockApi(HologramID: number) {
    debugger;
    // this.pagesUserStock.UserType = parseInt(localStorage.getItem("usertype"))
    // this.pagesUserStock.HologramID = this.formval.HologramID;
    // if (this.pagesUserStock.UserType == 0) {
    //   this.pagesUserStock.IsReceived = "0"
    //   this.pagesUserStock.IsReceived = "0"
    // }
    // else {
    //   this.pagesUserStock.IsReceived = "1";
    //   this.pagesUserStock.IsTransferred = "1";
    // }
    this.apiService.getApi("Damage/MappingForDamage?HologramID="+HologramID).subscribe((response: any) => {
        debugger;
      if (response.ResponseCode == "200") {
        this.availlist = response.ResponseData;
        if (this.availlist.length > 0) {
            
          this.availtotaldata = response.ResponseData.TotalCount;
          for (var i = 0; i < this.availlist.length; i++) {

            this.availlist[i].FromNumber = this.availlist[i].Prefix + this.mypadding(parseInt(this.availlist[i].FromNumber), 9);
            this.availlist[i].ToNumber = this.availlist[i].Prefix + this.mypadding(parseInt(this.availlist[i].ToNumber), 9);
          }
        }
      }
    })
  }
  onChangeDenomination(value: any) {
    debugger;
    this.isAvailableDisabled = false;
    this.getAvailableStockApi(value);
    this.formval.FromNumber = '';
    this.formval.ToNumber = '';
    this.formval.Quantity = '';
    this.formval.Remarks = '';
    this.from=null;
    this.to=null;
  }
}


