import { Component, OnInit, TemplateRef } from '@angular/core';
import { Mapping, MappingResponse, AvailResponse } from '../models/mapping';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { ToastrService } from 'ngx-toastr';
import { Product, ProductResponse,tokenStockExcel,tokenStockExcelReport} from '../models/product';
import { PackSize, PackSizeResponse } from '../models/packsize';
import { Denomination, DenominationResponse } from '../models/denomination';
import * as moment from 'moment';
import { StockResponse, Stock, StockFromNumberResponse } from '../models/stock';
import { NgForm } from '@angular/forms';
import { ExcelService } from '../excel.service';
import { Organisation } from '../models/organisation';
@Component({
  selector: 'app-token-stock-report',
  templateUrl: './token-stock-report.component.html',
  styleUrls: ['./token-stock-report.component.css']
})
export class TokenStockReportComponent implements OnInit {

  show: boolean = false;
  formval: Stock = new Stock();
  formvalForSave: Stock = new Stock();
  modalHead: string = "Add Stock Mapping";
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  url: string;
  mappings: Mapping[] = [];
  avails: Stock[] = [];
  products: Product[] = [];
  tokenExcelReport:any[]=[];
  tokenscanreportlistexcel:any[]=[];
  productlist: Product[] = [];
  availlist: Mapping[] = [];
  mappinglist: Stock[] = [];
  totaldata: number;
  availtotaldata: number;
  producttotaldata: number;
  onEdit: boolean = false;
  usertype: string;
  pagesUser: UserPage = new UserPage();
  pagesUserStock: UserPage = new UserPage();
  pagesUserProduct: UserPage = new UserPage();
  packsizelist: PackSize[] = [];
  denominationlist: Denomination[] = [];
  DenominationName: string;
  submitForm: NgForm;
  checkFromNumber: string;
  checkToNumber: string;
  constructor(private excelService: ExcelService,private toastr: ToastrService, private modalService: BsModalService, private apiService: ApiService) {
    this.pagesUser.Filter = "FromNumber";
    this.formval.HologramID = 0;
    this.pagesUser.pageSize='5'
  }
  ngOnInit() {
    this.pagesUser.page=1;
    this.setDates();
    this.usertype = localStorage.getItem("usertype");
    this.formval.pageSize='5';
    this.pagesUser.HologramID = 0;
    this.pagesUser.Organisation = parseInt(localStorage.getItem("organisation"));
    this.getstocklistapi();
    this.getDenominationList();
    this.onEdit = false;
  }
  setDates(): any {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var currentDay = date.getDate();
    this.formval.fromdate = moment(firstDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(currentDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
  }
  openModal(template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
    this.modalRef = this.modalService.show(template, config);
  }
  openModal2(template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
    this.modalRef2 = this.modalService.show(template, config);
  }
  removeQty() {
    this.formval.Quantity = '';
  }
  onutypechange() {
    this.getstocklistapi();
  }
  calc2(val: number) {
    if (this.formval.Quantity == '' || parseInt(this.formval.Quantity) == 0) {
      this.formval.ToNumber = '';
    }
    else {
      for (var i = 0; i < this.denominationlist.length; i++) {
        if (this.formval.HologramID == this.denominationlist[i].ID) {
          this.formval.Prefix = this.denominationlist[i].Prefix;
        }
      }
      var len = this.formval.FromNumber.length;
      this.formval.FromNumber = this.formval.FromNumber.substring(1, len);
      if (parseInt(this.formval.FromNumber) == 0) {
        this.formval.FromNumber = '';
        this.formval.ToNumber = '';
      }
      else if (parseInt(this.formval.Quantity) == 0) {
        this.formval.ToNumber = '';
        this.formval.Quantity = '';
        this.formval.FromNumber = this.formval.Prefix + this.formval.FromNumber;
      }
      else {
        var txtfromNo = parseInt(this.formval.FromNumber);
        var txtqtyNo = parseInt(this.formval.Quantity);
        var txttoNo = txtfromNo + txtqtyNo - 1;
        this.formval.ToNumber = txttoNo.toString();
        this.formval.FromNumber = this.formval.Prefix + this.mypadding(parseInt(this.formval.FromNumber), 9);
        this.formval.ToNumber = this.formval.Prefix + this.mypadding(parseInt(this.formval.ToNumber), 9);
      }
    }
  }
  calc(val: number) {
    for (var i = 0; i < this.denominationlist.length; i++) {
      if (this.formval.HologramID == this.denominationlist[i].ID) {
        this.formval.Prefix = this.denominationlist[i].Prefix;
      }
    }
    // var len=this.formval.FromNumber.length;
    // this.formval.FromNumber=this.formval.FromNumber.substring(1,len-1);
    if (parseInt(this.formval.FromNumber) == 0) {
      this.formval.FromNumber = '';
      this.formval.ToNumber = '';
    }
    else if (parseInt(this.formval.Quantity) == 0) {
      this.formval.ToNumber = '';
      this.formval.Quantity = '';
      this.formval.FromNumber = this.formval.Prefix + this.mypadding(parseInt(this.formval.FromNumber), 9);
    }
    else {
      var txtfromNo = parseInt(this.formval.FromNumber);
      var txtqtyNo = parseInt(this.formval.Quantity);
      this.formval.FromNumber = this.formval.Prefix + this.mypadding(parseInt(this.formval.FromNumber), 9);
    }
  }
  getFromNumberByDenomination() {
    if (this.formval.HologramID == 0) {
      this.formval.Quantity = '';
      this.formval.ToNumber = '';
      this.formval.FromNumber = '';
      this.formval.HologramID = 0;
    }
    else {
      for (var i = 0; i < this.denominationlist.length; i++) {
        if (this.formval.HologramID == this.denominationlist[i].ID) {
          this.formval.Prefix = this.denominationlist[i].Prefix;
        }
      }
      this.formval.FromNumber = "1";
      this.apiService.PostApi(this.pagesUser, "Stock/GetFromNumber?HologramID=" + this.formval.HologramID).subscribe((response: StockFromNumberResponse) => {
        this.formval.FromNumber = response.ResponseData
        this.calc(parseInt(this.formval.FromNumber));
      }, err => {
        this.formval.FromNumber = this.formval.Prefix + '000000001';
      })
      if (this.formval.HologramID == 0) {
        this.formval.FromNumber = "";
        this.formval.ToNumber = "";
        this.formval.Quantity = "";
      }
    }
  }
  mypadding(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  getstocklistapi() {

    this.formval.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");

    if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {
      this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
        timeOut: 3000
      });

    }
    else {
       (this.pagesUser)
      this.url = "Report/GetTokenStock"
      if (this.usertype == '0') {
        this.pagesUser.IsReceived = "0";
        this.pagesUser.IsTransferred = "0";
      }
      else {
        this.pagesUser.IsReceived = "1";
        this.pagesUser.IsTransferred = "1";
      }
      let obj={...this.pagesUser};
      obj.fromdate=this.formval.fromdate;
      obj.todate=this.formval.todate;
      this.show = true;
      this.mappinglist = this.mappinglist.slice(0, 0);
      this.apiService.PostApi(obj, this.url).subscribe((response: StockResponse) => {
        // (response)
        this.mappinglist = response.ResponseData;
        if (this.mappinglist.length > 0) {
          for (var i = 0; i < this.mappinglist.length; i++) {
            this.mappinglist[i].FromNumber = this.mappinglist[i].Prefix + this.mypadding(parseInt(this.mappinglist[i].FromNumber), 9);
            this.mappinglist[i].ToNumber = this.mappinglist[i].Prefix + this.mypadding(parseInt(this.mappinglist[i].ToNumber), 9);
          }
        }
         (this.mappinglist)
        this.totaldata = response.ResponseData[0].TotalCount;
         (this.totaldata);
        this.show = false;
      })
      this.show = false;
    }

  }
  onSubmit(f: NgForm) {
    this.submitForm = f;
    this.show = true;
    this.formvalForSave = this.formval;
    var flen = this.formvalForSave.FromNumber.length;
    var tlen = this.formvalForSave.ToNumber.length;
    this.formvalForSave.FromNumber = this.formval.FromNumber.substring(1, flen);
    this.formvalForSave.ToNumber = this.formval.ToNumber.substring(1, tlen);
    this.saveStock();
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  removenumber() {
    if (this.formval.Quantity == '') {
      this.formval.ToNumber = '';
    }
  }

  DownloadTokenStockReport() {
    this.pagesUser.fromdate = moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.pagesUser.todate = moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    if (moment(this.formval.fromdate).isAfter(this.formval.todate)) {
      this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
        timeOut: 3000
      });
    }
    else {

      this.show = true;
      this.pagesUser.pageSize = '100000';
      this.formval.pageNumber = 1;
      this.show = true;
      this.apiService.PostApi(this.pagesUser, "Report/GetTokenStock").subscribe((response: any) => {
        this.tokenscanreportlistexcel = response.ResponseData;
        if (this.tokenscanreportlistexcel.length > 0) {
          for (var i = 0; i < this.tokenscanreportlistexcel.length; i++) {
            this.tokenscanreportlistexcel[i].FromNumber = this.tokenscanreportlistexcel[i].Prefix + this.mypadding(parseInt(this.tokenscanreportlistexcel[i].FromNumber), 9);
            this.tokenscanreportlistexcel[i].ToNumber = this.tokenscanreportlistexcel[i].Prefix + this.mypadding(parseInt(this.tokenscanreportlistexcel[i].ToNumber), 9);
          }
        }
        this.tokenExcelReport = this.tokenExcelReport.slice(0, 0);
        this.tokenscanreportlistexcel.forEach(o => {
          this.tokenExcelReport.push(
            { Organisation: o.StockOrganisation, Denomination: o.Denomination, FromNumber: o.FromNumber, ToNumber: o.ToNumber, StockIn: o.StockIn,StockOut: o.StockOut,From_To_Organisation:o.OrganisationName,CreatedDate:o.CreatedDate});
        });

        this.exportAsXLSX(this.tokenExcelReport);
        this.show = false;
      },
        err => {

          this.show = false;

          this.totaldata = 0;
        })

      this.show = false;
    }
  }
  exportAsXLSX(myreport: any): void {

    this.excelService.exportAsExcelFile(this.tokenExcelReport, 'Ledger Report' + moment(this.formval.fromdate, "DD-MMM-YYYY").format("DD-MMM-YYYY") + '_to_' + moment(this.formval.todate, "DD-MMM-YYYY").format("DD-MMM-YYYY"));
  }
  saveStock() {
      ;
    this.formvalForSave.Organisation = parseInt(localStorage.getItem("organisation"));
    this.formvalForSave.CreatedBy = localStorage.getItem("userID");
    if (localStorage.getItem("usertype") == "0") {
      this.formvalForSave.Organisation = 1;
    }
    if (parseInt(this.formvalForSave.FromNumber) <= 0 || parseInt(this.formvalForSave.ToNumber) <= 0 || parseInt(this.formvalForSave.Quantity) <= 0) {
      this.toastr.warning("Negative Values are not allowed", "Warning!")
      this.show = false;
    }
    else {
      if (this.onEdit == true) {
        this.apiService.PostApi(this.formvalForSave, "Stock/EditStock").subscribe((response: StockResponse) => {
           (response)
          if (response.ResponseCode == "200") {
            this.submitForm.resetForm();
            this.show = false;
            this.toastr.success("Stock Updated Successfully", "Success!")
            this.getstocklistapi();
            this.resetmodal();
          }
          else {
            this.toastr.warning(response.ResponseMessage, "Warning!")
            this.show = false;
          }
        }, err => {
          this.toastr.warning(err.error.ResponseMessage, "Warning!")
          this.show = false;
        })
      }
      else {
        this.apiService.PostApi(this.formval, "Stock/SaveStock").subscribe((response: StockResponse) => {
           (response)
          if (response.ResponseCode == "200") {
            this.submitForm.resetForm();
            this.show = false;
            this.toastr.success("Stock Added Successfully", "Success!")
            this.getstocklistapi();
            this.resetmodal();
          }
          else {
            this.toastr.warning(response.ResponseMessage, "Warning!")
            this.formval.FromNumber = this.formval.Prefix + this.formval.FromNumber;
            this.formval.ToNumber = this.formval.Prefix + this.formval.ToNumber;
            this.show = false;
          }
        }, err => {
          this.toastr.warning(err.error.ResponseMessage, "Warning!")
          this.formval.FromNumber = this.formval.Prefix + this.formval.FromNumber;
          this.formval.ToNumber = this.formval.Prefix + this.formval.ToNumber;
          this.show = false;
        })
      }
    }
  }
  editstock(template: TemplateRef<any>, summary: Stock) {
    this.formval = { ...summary };
    var flen = this.formval.FromNumber.length;
    var tlen = this.formval.ToNumber.length;
    this.checkFromNumber = this.formval.FromNumber.substring(1, flen);
    this.checkToNumber = this.formval.ToNumber.substring(1, tlen);
    for (var i = 0; i < this.denominationlist.length; i++) {
      if (this.formval.HologramID == this.denominationlist[i].ID) {
        this.formval.Prefix = this.denominationlist[i].Prefix;
      }
    }
    this.onEdit = true;
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
    this.modalRef = this.modalService.show(template, config);
  }
  resetmodal() {
    this.modalRef.hide();
    this.onEdit = false;
    this.formval.FromNumber = '';
    this.formval.HologramID = 0;
    this.formval.ToNumber = '';
    this.formval.Quantity = '';
    this.formvalForSave = this.formval;
  }
  closemodal2() {
    this.modalRef2.hide();
  }
  closemodal3() {
    this.modalRef3.hide();
  }
  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.pagesUser.page = event.page;
    this.getstocklistapi();
  }
  ontypechange() {
      ;
    this.pagesUser.page = 1
    this.pagesUser.pageSize=this.formval.pageSize;
    this.getstocklistapi();
  }
  getDenominationList() {
    this.apiService.PostApi(this.pagesUserStock, "Other/GetLabelDenomination").subscribe((response: DenominationResponse) => {
      if (response.ResponseCode == "200") {
        this.denominationlist = response.ResponseData;
        this.formval.Prefix = response.ResponseData[0].Prefix;
      }
    })
  }

}
