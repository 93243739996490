import { Component,  TemplateRef } from '@angular/core';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { UserMasterResponse, User } from '../models/user';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/ngx-bootstrap-pagination';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { OrganisationResponse, Organisation } from '../models/organisation';
@Component({
  selector: 'app-Rewardfulfillment',
  templateUrl: './Rewardfulfillment.component.html',
  styleUrls: ['./Rewardfulfillment.component.css']
})
export class RewardfulfillmentComponent  {
  sales: any[] = [];
  cols: any[] = [];
  cols2:any[]=[]
  FromDate: Date;
  minDate = new Date();
  ToDate:Date;
  EditDenomination:boolean=false;
  selectedData: any[] = [];
  selectedColumns2:any[]=[];
  displayProductsList:boolean=false;
  selectedColumns: any[] = [];
  totalRecords: number
  categoryList: any[] = []
  SchemeHeader: string
  imageToShow: string = ''
  isImageAvailable: boolean = false
  typeList: any[] = []
 cancelInput:boolean = false;
 aprroveInput:boolean = false;
  pagedetails: any
  submitted: boolean = false;
  display: boolean = false;
  ApprovalForm: FormGroup;
  prmGrid2:any;
  constructor( private fb: FormBuilder, private api: ApiService, private tstr: ToastrService) {
  }
  get u() {
    return this.ApprovalForm.controls;
  }
  ApproveForm(){
    this.ApprovalForm = this.fb.group({ 
      Approve:[0,Validators.required],
     PendingPoint:[0,Validators.required],
     Remark:["",Validators.required],
     RequestID:[0,Validators.required]
    })
  }
  CancelForm(){
    this.ApprovalForm = this.fb.group({ 
      CancelPoint:[0,Validators.required],
     PendingPoint:[0,Validators.required],
     Remark:["",Validators.required],
     RequestID:[0,Validators.required]
    })
  }
  openProductdialog() {
  this.displayProductsList=true;
const obj = this.defaultvalue();
this.getProductList(obj);
  }
  defaultvalue(){
    const obj = {
      page: 1,
      searchValue: '',
      pageSize: 5,
      filter: "",
      isActive:true
    };
    return obj;
  }
  Approvedialog(event:any){
    this.display=true;
    this.aprroveInput=true;
    this.cancelInput=false;
    this.ApproveForm();
    this.ApprovalForm.patchValue({
      PendingPoint:event.PendingPoint,
      RequestID:event.RequestID
    })
    this.SchemeHeader="Approve Point";
  }
CancelDialog(event:any){
  this.display=true;
  this.cancelInput=true;
  this.aprroveInput=false;
  this.CancelForm();
  this.ApprovalForm.patchValue({
    PendingPoint:event.PendingPoint,
    RequestID:event.RequestID
  })
  this.SchemeHeader="Cancel Points";
}
ApprovePoint(){
 debugger;
  let obj={
    UserID:parseInt(localStorage.getItem("userID")),
    RemarkbyAdmin:this.ApprovalForm.value.Remark,
    Points:this.ApprovalForm.value.Approve,
    RequestID:this.ApprovalForm.value.RequestID,
    ApprovalType:1,
  }
  if(obj.Points == 0 || obj.Points==null||obj.Points==undefined ||
    obj.RemarkbyAdmin =="" || obj.RemarkbyAdmin ==undefined || obj.RemarkbyAdmin == null  ){
      this.tstr.warning('Please fill required details', 'Warning', {
        timeOut: 1000,
      });
      return;
  }
  else{
    this.api.PostApi( obj,'ReedeemRequest/RewardConfirmation').subscribe((res: any) => {
      debugger
      if (res.ResponseCode == 200) {
        this.tstr.success(res.ResponseMessage);
        this.display=false;
        const obj = this.getPageInfo(this.pagedetails);
        this.getSchemeList(obj);
      } else {
        this.tstr.warning(res.ResponseMessage);
      }
    },error=>{
      this.sales = []
      this.totalRecords = 0
    });
  }
  }
  
CancelPoint(){
  debugger;
let obj={
  UserID:parseInt(localStorage.getItem("userID")),
    RemarkbyAdmin:this.ApprovalForm.value.Remark,
    Points:this.ApprovalForm.value.CancelPoint,
    RequestID:this.ApprovalForm.value.RequestID,
    ApprovalType:2,
}
if(obj.Points == 0 || obj.Points==null||obj.Points==undefined ||
  obj.RemarkbyAdmin =="" || obj.RemarkbyAdmin ==undefined || obj.RemarkbyAdmin == null  ){
    this.tstr.warning('Please fill required details', 'Warning', {
      timeOut: 1000,
    });
    return;
}
else{
this.api.PostApi( obj,'ReedeemRequest/RewardConfirmation').subscribe((res: any) => {
  debugger
  if (res.ResponseCode == 200) {
    this.tstr.success(res.ResponseMessage);
    this.ApprovalForm.reset();
    this.display=false;
    const obj = this.getPageInfo(this.pagedetails);
    this.getSchemeList(obj);
  } else {
    this.tstr.warning(res.ResponseMessage);
  }
},error=>{
  this.sales = []
  this.totalRecords = 0
});
}}
submitPoints(){
    debugger
    if( this.SchemeHeader=="Approve Point"){
      this.ApprovePoint();
    }
    else{
      this.CancelPoint();
    }
  }
  public getSchemeList(event: any): any {
  debugger
    this.api.PostApi( event,'ReedeemRequest/GetAllRedeemRequest').subscribe((res: any) => {
      debugger
      if (res.ResponseCode == 200) {
        this.totalRecords = res.ResponseData[0].TotalCount
        this.sales = res.ResponseData 
      } else {
        this.sales = []
        this.totalRecords = 0
      }
    },error=>{
      this.sales = []
      this.totalRecords = 0
    });
  }
  public lazyLoad(event: any) {
debugger
    //this method is called on page load to fetch data on the page
    this.pagedetails = event;
    const obj = this.getPageInfo(this.pagedetails);
     this.getSchemeList(obj);
  }
  getPageInfo(event: any) {
    //this method is used to get page information
    const requestFilter = {
      page: event.first == 0 ? 1 : (event.first / event.rows) + 1,
      pageSize: event.rows,
      searchValue: event.globalFilter ? event.globalFilter : '',
      sortBy: event.sortField == undefined ? '' : event.sortField,
      sortOrder: event.sortOrder === 1 ? 'ASC' : 'DESC',
      isActive:true
    }
    return requestFilter;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  getPageInfoforProduct(event: any) {
    //this method is used to get page information
    const requestFilter = {
      page: event.first == 0 ? 1 : (event.first / event.rows) + 1,
      pageSize: event.rows,
      searchValue: event.globalFilter ? event.globalFilter : '',
      filter: event.sortField == undefined ? '' : event.sortField,
      isActive:true
    }
    return requestFilter;
  }
  ngOnInit() {
    this.cols = [
      { field: 'Name', header: 'Dealer Name' },
      { field: 'TotalPoint', header: 'Total Point' },
      { field: 'RequestedPoint', header: 'Requested Point' },
      { field: 'PendingPoint', header: 'Pending Point' },
      { field: 'ApprovedPoint', header: 'Approved Point' },
      { field: 'CancelledPoint', header: 'Cancelled Point' },
      { field: 'RemarkbyAdmin', header: 'Remarks' },
      { field: 'CreatedDate', header: 'Created Date' }
    ];
    this.selectedColumns = this.cols;
    this.sales = [];
   this.ApproveForm();
   this.CancelForm();
  }
  loadLazy2(event: any) {
    debugger;
    this.prmGrid2 = event;
    const obj=this.getPageInfoforProduct(event);
    this.getProductList(obj);
  }
  getProductList(event:any) {
  }
  close(){}
  //////////////////////////////// Export to Excel Code Start
  //////// Get Product List for Excel
}