import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../Services/shared-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  activeClass:string="";
  inClass:string="";
  id:number=0;
  AutoOpen:boolean=false;
  Name:string;
  showAuditer:number=0
  OrganisationName:string;
  usertype:string;
  userGroup:any;
  HoAudtior: any
  constructor(private toastr: ToastrService,private router:Router, private sharedService: SharedService)
  {
    this.sharedService.navbar.subscribe(() => {
      this.changeName();
    });

   }

   private changeName(): void {


    this.Name = localStorage.getItem("name");
    this.router.navigateByUrl('/navbar', {skipLocationChange: true}).then(()=>
      this.router.navigate(["Home"]));
  }

  ngOnInit() {
    this.Name=localStorage.getItem("name");
    this.OrganisationName=localStorage.getItem("organisationname");
    this.usertype = localStorage.getItem("usertype");
    this.userGroup = localStorage.getItem("UsrGroup")
    // live db data
    if(this.usertype == '10' && this.userGroup == '7')
    {
      this.showAuditer=1
    }// live db data
    if(this.usertype == '10' && this.userGroup == '4')
    {
      this.HoAudtior=1
    }

  }


  changeAutoOpen()
  {
    this.AutoOpen=!this.AutoOpen;
  }

  addClass(num:number)
  {
    if(this.id!=0 && this.id==num)
    {
      this.id=0;
      this.inClass="";
      this.activeClass="";

    }
    else if(this.id!=0)
    {
      this.id=num;
      this.inClass="";
      this.activeClass="";

    }
    else
    {
      this.id=num;
      this.activeClass="active";
      this.inClass="in";

    }

  }


  signOut()
  {
    this.toastr.clear();
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }

  changepassword()
  {
   this.router.navigateByUrl('/changepassword');
  }

  // receive()
  // {
  //   this.router.navigateByUrl('/receive');
  // }

  // transfer()
  // {
  //   this.router.navigateByUrl('/transfer');
  // }

  setting()
  {
    this.router.navigateByUrl('/setting');

  }
  scanlogreport()
  {
    this.router.navigateByUrl('/scanlogreport');
  }

  passbookreport()
  {
    this.router.navigateByUrl('/passbookreport');
  }

  usermaster() {
    this.router.navigateByUrl('/usermaster');
  }

  housermaster() {
    this.router.navigateByUrl('/hoUsermaster');
  }
  paintermaster()
  {
   this.router.navigateByUrl('/paintermaster');
  }

  ratailermaster()
  {
    this.router.navigateByUrl('/dealermaster');
  }

  organisation()
  {
    this.router.navigateByUrl('/organisation');
  }

  productmaster()
  {
    this.router.navigateByUrl('/productmaster');
  }
  availabeStockRpt()
  {
    this.router.navigateByUrl('/availableStock');
  }
  stock()
  {
    this.router.navigateByUrl('/stock');
  }
  tokenstockreport()
  {
    this.router.navigateByUrl('/ledgerreport');
  }
  passbookreportnew()
  {
    this.router.navigateByUrl('/passbookreportnew');
  }
  scanlogreportnew()
  {
    this.router.navigateByUrl('/scanlogreportnew');
  }
  delearmaster()
  {
    this.router.navigateByUrl('/dealermaster')
  }
  sblTypeReport()
  {
    this.router.navigateByUrl('/tokenReport')
  }
  salesmaster()
  {
    this.router.navigateByUrl('/salesmaster');
  }
  mapping()
  {
    this.router.navigateByUrl('/tokenallocation');
  }
  damage()
  {
    this.router.navigateByUrl('/tokendamage');
  }

  demapping()
  {

    this.router.navigateByUrl('/tokendeallocation');
  }

  transferreport()
  {
    this.router.navigateByUrl('/transferreport');
  }
  CNReport()
  {
    this.router.navigateByUrl('/CnReport');
  }
  receivereport()
  {
    this.router.navigateByUrl('/receivereport');
  }
  DumpReport()
  {
    this.router.navigateByUrl('/dump-report');
  }
  home()
  {
    this.router.navigateByUrl('/home');
  }
  Couponconfiguration()
  {
    this.router.navigateByUrl('/Couponconfiguration');
  }
  RewardfulfillmentComponent()
  {
    this.router.navigateByUrl('/RewardfulfillmentComponent');
  }
  Prize()
  {
    this.router.navigateByUrl('/Prize');
  }
  Productmappingreport()
  {
    this.router.navigateByUrl('/Productmappingreport')
  }
  Productdemappingreport()
  {
    this.router.navigateByUrl('/Productdemappingreport')
  }
  Damagereport()
  {
    this.router.navigateByUrl('/Damagereport')
  }
  // Contractormaster()
  // {
  //   this.router.navigateByUrl('/Contractormaster')
  // }
  FeedbackComponent()
  {
    this.router.navigateByUrl('/FeedbackComponent')
  }
  LoyaltyschemereportComponent()
  {
    this.router.navigateByUrl('/LoyaltyschemereportComponent')
  }
  DealerreportComponent()
  {
    this.router.navigateByUrl('/DealerreportComponent')
  }
  ProductwisereportComponent()
  {
    this.router.navigateByUrl('/ProductwisereportComponent')
  }
}
