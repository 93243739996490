import { Component, OnInit} from '@angular/core';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { ScanReport, ScanReportResponse, ScanLogExcelData, sblReportResponse, sblReport, SblExcelData } from '../models/report';
import { ExcelService } from '../excel.service';

@Component({
  selector: 'app-sbl-type-report',
  templateUrl: './sbl-type-report.component.html',
  styleUrls: ['./sbl-type-report.component.css']
})
export class SblTypeReportComponent implements OnInit {

  show: boolean = false;
  formval: sblReport = new sblReport();
  modalRef: BsModalRef;
  sblList: any[] = [];
  StaticData:any[]=[];
  HoUserlist:any[]=[];
  reportExcelPassbook: SblExcelData[] = [];
  Depotlist:any[]=[];
  sblreportlist: sblReportResponse[] = [];
  sblreportlistexcel: sblReportResponse[] = [];
  totaldata: number;
  pagesUser: UserPage = new UserPage();
  currentPage: number;
  submitForm: NgForm;
  sblreportDetailsList: any[] = [];

  constructor(private excelService: ExcelService, private toastr: ToastrService, private modalService: BsModalService, private apiService: ApiService) {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var currentDay = date.getDate();
    this.formval.FromDate = moment(firstDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.ToDate = moment(currentDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.PageSize = 5;
    this.formval.SearchValue = '';
    this.formval.Page = 1;
    this.formval.FilterBY = '';
    this.formval.SblType="0";
    this.formval.IsActive = '%';
    this.formval.DepotId="0";
    this.formval.HOUserId=0;
  }

  ngOnInit() {
    this.setDates();
    this.show = false;
    this.getsblgriddetails();
    this.onDepotChange()
  }

  // onutypechange() {
  //   this.getscanreport();
  // }

  onDepotChange()
  {
    let obj = {
      DepotId:this.formval.DepotId
    }
    this.apiService.GetApi('0',`HOUser/HoUserByDepot?DepotId=${obj.DepotId}`).subscribe((resp:any)=>{
      if(resp.ResponseCode==200)
      {
        this.HoUserlist=resp.ResponseData;
      }
    })
  }
  getsblgriddetails() {
    this.apiService.PostApi(this.formval, "Report/GetSBLDealerRpt").subscribe((response: any) => {
      if (response.ResponseCode == 200) {
        this.sblreportlist = response.ResponseData;
        this.totaldata = response.ResponseData[0].TotalCount;
      }
      else {
        this.sblreportlist = [];
        this.totaldata = 0;
      }
    },
      err => {
        this.sblreportlist = []
        this.totaldata = 0;
        this.show = false;
      })
    this.apiService.GetApi('0', `Dealer/GetSBLType`).subscribe((resp: any) => {
      if (resp.ResponseCode == 200) {
        this.sblList = resp.ResponseData;
      }
    })
    this.apiService.GetApi('0', `HOUser/GetAllDepot`).subscribe((resp: any) => {
      if (resp.ResponseCode == 200) {
        this.Depotlist = resp.ResponseData;
      }
    })
  }
  setDates(): any {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var currentDay = date.getDate();
    this.formval.FromDate = moment(firstDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.ToDate = moment(currentDay, "DD-MMM-YYYY").format("DD-MMM-YYYY");
  }

  exportAsXLSX(myreport: any): void {
    this.excelService.exportAsExcelFile(this.reportExcelPassbook, 'SblReport' + '_' + moment(this.formval.FromDate, "DD-MMM-YYYY").format("DD-MMM-YYYY") + '_to_' + moment(this.formval.ToDate, "DD-MMM-YYYY").format("DD-MMM-YYYY"));
    this.formval.PageSize = 5;
  }

  DownloadScanReport() {
    this.formval.FromDate = moment(this.formval.FromDate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.ToDate = moment(this.formval.ToDate, "DD-MMM-YYYY").format("DD-MMM-YYYY");

    if (moment(this.formval.FromDate).isAfter(this.formval.ToDate)) {


      this.toastr.warning('From Date should not be greater than To Date!', 'Warning!', {
        timeOut: 3000
      });
    }
    else {
      this.show = true;
      this.formval.PageSize = -1;
      this.formval.Page = 1;
      this.apiService.PostApi(this.formval, "Report/GetSBLDealerRptForExcel").subscribe((response: any) => {
        this.sblreportlistexcel = response.ResponseData;
        this.reportExcelPassbook = this.reportExcelPassbook.slice(0, 0);
        this.sblreportlistexcel.forEach(o => {
          this.reportExcelPassbook.push(
            {SblType:o.SblType, ScannedBy: o.FirstName,EmployeeCode: o.EmpId, Mobile: o.Mobile,DealerNo: o.AccountNo,Dealer_Bill_To_Code:o.SiteDesc,DealerName: o.FirmNm,TokenNo:o.HologramNo,ScanDate:o.ScanDt,TokenValue:o.Points,AssociatedProduct:o.ProdDesc,ProducedFrom:o.OrganisationName,BatchNumber:o.BatchNo});
        });
        this.exportAsXLSX(this.sblreportlistexcel);
      },
        err => {
          this.show = false;
          this.totaldata = 0;
        }
      )
      this.show = false;
    }
    this.formval.Page = 1;
    this.formval.PageSize = 5;
  }

  onpagechange() {
    this.formval.Page = 1;
    this.getscanreport();
  }

  getsblreportdetails(template, data: any) {
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    this.modalRef = this.modalService.show(template, config);
    let obj = {
      HOUserId: data.HOUserId,
      AccountNo: data.DealerId
    }
    this.StaticData[0]=data;
    this.apiService.PostApi(obj, "Report/GetSBLDealerDtlRpt").subscribe((resp: any) => {
      if (resp.ResponseCode == 200) {
        this.sblreportDetailsList = resp.ResponseData;
      }
    })

  }

  resetmodal() {
    this.modalRef.hide();
  }

  getscanreport() {
    this.show = false;
    this.formval.FromDate = moment(this.formval.FromDate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
    this.formval.ToDate = moment(this.formval.ToDate, "DD-MMM-YYYY").format("DD-MMM-YYYY");

      if (moment(this.formval.FromDate).isAfter(this.formval.ToDate)) {
        this.toastr.warning('From Date Cannot be greater than To Date!', 'Warning!', {
          timeOut: 3000
        });
      }
        else if (this.formval.FromDate == 'Invalid date'||this.formval.ToDate=='Invalid date') {
          this.toastr.warning('Date is required!', 'Warning!', {
            timeOut: 3000
          });
        }
      else {
        this.sblreportlist = this.sblreportlist.slice(0, 0);
        this.show = true;
        this.apiService.PostApi(this.formval, "Report/GetSBLDealerRpt").subscribe((response: any) => {
          if (response.ResponseCode == 200) {
            this.sblreportlist = response.ResponseData;
            this.totaldata = response.ResponseData[0].TotalCount;
          }
          else {
            this.sblreportlist = [];
            this.totaldata = 0;
          }
        },
          err => {
            this.sblreportlist = []
            this.totaldata = 0;
            this.show = false;
          })
        this.show = false;
      }
  }
  // numberOnly(event): boolean {
  //   const charCode = (event.which) ? event.which : event.keyCode;
  //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   return true;
  // }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.formval.Page = event.page;
    this.getscanreport();
    this.currentPage = event.page + 1;
  }

  ontypechange() {
    this.pagesUser.page = 1;
    this.getscanreport();

  }
}
