import {NgModule}  from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './auth-guard';

import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { UsermasterComponent } from './usermaster/usermaster.component';
import { PaintermasterComponent } from './paintermaster/paintermaster.component';
import { MappingComponent } from './mapping/mapping.component';
import { RetailermasterComponent } from './retailermaster/retailermaster.component';
import { SalesMasterComponent } from './salesmaster/salesmaster.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ProductmasterComponent } from './productmaster/productmaster.component';
import { StockComponent } from './stock/stock.component';
import { DemappingComponent } from './demapping/demapping.component';
import { DamageComponent } from './damage/damage.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { ScanlogreportComponent } from './scanlogreport/scanlogreport.component';
import { PassbookreportComponent } from './passbookreport/passbookreport.component';
import { OrganisationComponent } from './organisation/organisation.component';
import { DenominationsettingComponent } from './denominationsetting/denominationsetting.component';
import { StocktransferComponent } from './stocktransfer/stocktransfer.component';
import { StockreceiveComponent } from './stockreceive/stockreceive.component';
import { TransferreportComponent } from './transferreport/transferreport.component';
import { ReceiveReportComponent } from './receive-report/receive-report.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { HoUserComponent } from './ho-user/ho-user.component';
import { ScanlogreportnewComponent } from './scanlogreportnew/scanlogreportnew.component';
import { PassbookreportnewComponent } from './passbookreportnew/passbookreportnew.component';
import { DealemasternewComponent } from './dealemasternew/dealemasternew.component';
import { SblTypeReportComponent } from './sbl-type-report/sbl-type-report.component';
import {TokenStockReportComponent} from './token-stock-report/token-stock-report.component';
import { AvailableStockReportComponent } from './available-stock-report/available-stock-report.component'
import { CNReportComponent } from './cn-report/cn-report.component';
import { DUMPReportComponent } from './dump-report/dump-report.component';
import { CouponconfigurationComponent } from './CouponConfiguration/CouponConfiguration.component';
import { RewardfulfillmentComponent} from './Rewardfulfillment/Rewardfulfillment.component'
import { ProductmappingreportComponent } from './Productmappingreport/Productmappingreport.component';
import { ProductdemappingreportComponent } from './Productdemappingreport/Productdemappingreport.component';
import { DamagereportComponent } from './damagereport/damagereport.component';
import { DistributermasterComponent } from './DistributerMaster/DistributerMaster.component';
import { PrizeComponent } from './Prize/Prize.component';
import {FeedbackComponent} from './Feedback/Feedback.component';
import {LoyaltyschemereportComponent} from './Loyaltyschemereport/Loyaltyschemereport.component';
import {ProductwisereportComponent} from './Productwisereport/Productwisereport.component'
// import {DashboardComponent} from './dashboard/dashboard.component';
import { DealerreportComponent} from './Dealerreport/Dealerreport.component';

const routes: Routes = [
    { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuardService]},
  { path: 'usermaster', component: UsermasterComponent, canActivate: [AuthGuardService] },
  { path: 'hoUsermaster', component: HoUserComponent, canActivate: [AuthGuardService] },
  //{ path: 'paintermaster', component: PaintermasterComponent, canActivate: [AuthGuardService] },
  { path: 'paintermaster', component: PaintermasterComponent, canActivate: [AuthGuardService] },
  { path: 'productmaster', component: ProductmasterComponent, canActivate: [AuthGuardService]},
  // { path: 'dealermaster', component: RetailermasterComponent, canActivate: [AuthGuardService]},
  { path: 'tokendamage', component: DamageComponent, canActivate: [AuthGuardService]},
  { path: 'stock', component: StockComponent, canActivate: [AuthGuardService]},
  { path: 'tokenallocation', component: MappingComponent, canActivate: [AuthGuardService]},
  { path: 'scanlogreport', component: ScanlogreportComponent, canActivate: [AuthGuardService]},
  { path: 'passbookreport', component: PassbookreportComponent, canActivate: [AuthGuardService]},
  { path: 'organisation', component: OrganisationComponent, canActivate: [AuthGuardService]},
  { path: 'receive', component: StockreceiveComponent, canActivate: [AuthGuardService]},
  { path: 'setting', component: DenominationsettingComponent, canActivate: [AuthGuardService]},
  { path: 'sblReport', component: SblTypeReportComponent, canActivate: [AuthGuardService]},
  { path: 'scanlogreportnew', component: ScanlogreportnewComponent, canActivate: [AuthGuardService]},
  { path: 'tokenReport', component: SblTypeReportComponent, canActivate: [AuthGuardService]},
  { path: 'passbookreportnew', component: PassbookreportnewComponent, canActivate: [AuthGuardService]},
  { path: 'dealermaster', component: DealemasternewComponent, canActivate: [AuthGuardService]},
  { path: 'transfer', component: StocktransferComponent, canActivate: [AuthGuardService]},
  { path: 'tokendeallocation', component: DemappingComponent, canActivate: [AuthGuardService]},
  // { path: 'receivereport', component: ReceiveReportComponent, canActivate: [AuthGuardService]},
  { path: 'salesmaster', component: SalesMasterComponent, canActivate: [AuthGuardService]},
  { path: 'transferreport', component: TransferreportComponent, canActivate: [AuthGuardService]},
  { path: 'changepassword', component: ChangepasswordComponent, canActivate: [AuthGuardService]},
  { path: 'privacypolicy', component: PrivacyPolicyComponent},
  { path: 'ledgerreport', component: TokenStockReportComponent},
  { path: 'CnReport', component: CNReportComponent},
  { path: 'availableStock', component: AvailableStockReportComponent},
  { path: 'dump-report', component: DUMPReportComponent},
  { path: 'navbar', component: NavbarComponent},
  {path: 'Couponconfiguration', component: CouponconfigurationComponent,canActivate: [AuthGuardService]},
  {path: 'RewardfulfillmentComponent',component: RewardfulfillmentComponent,canActivate: [AuthGuardService]},
  {path: 'Prize', component: PrizeComponent,canActivate: [AuthGuardService]},
  {path: 'Productdemappingreport', component:ProductdemappingreportComponent},
  {path: 'Productmappingreport',component: ProductmappingreportComponent},
  {path: 'Damagereport',component:DamagereportComponent},
  {path: 'Distributermaster',component:DistributermasterComponent},
  {path: 'FeedbackComponent',component:FeedbackComponent},
  {path: 'LoyaltyschemereportComponent',component:LoyaltyschemereportComponent},
  // {path: 'DashboardComponent',component:DashboardComponent},
  {path: 'DealerreportComponent',component:DealerreportComponent},
  {path: 'ProductwisereportComponent',component:ProductwisereportComponent},
  {path: '', redirectTo: '/home', pathMatch: 'full'  },
  {path: '**', redirectTo: '/home'},
  

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
      // Optional Step: Do some stuff with the url if needed.

      // If you lower it in the optional step
      // you don't need to use "toLowerCase"
      // when you pass it down to the next function
      return super.parse(url.toLowerCase());
  }
}
