import { Component, OnInit, TemplateRef } from '@angular/core';
import { Mapping, MappingResponse, AvailResponse } from '../models/mapping';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { ToastrService } from 'ngx-toastr';
import { Product, ProductResponse } from '../models/product';
import { PackSize, PackSizeResponse } from '../models/packsize';
import { Denomination, DenominationResponse } from '../models/denomination';
import { NgForm } from '@angular/forms';
import { DenominationSetting, DenominationSettingResponse } from '../models/setting';
import { Point, PointResponse } from '../models/Point';
@Component({
  selector: 'app-mapping',
  templateUrl: './mapping.component.html',
  styleUrls: ['./mapping.component.css']
})
export class MappingComponent implements OnInit {
  show: boolean = false;
  formval: Mapping = new Mapping();
  formvalforSave: Mapping = new Mapping();
  modalHead: string = "Add Stock Mapping";
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  mappings: Mapping[] = [];
  avails: Mapping[] = [];
  producttotaldata: number;
  from: any;
  to: any;
  isAvailableDisabled: boolean;
  products: Product[] = [];
  productlist: DenominationSetting[] = [];
  currentPage: number;
  settingslist: DenominationSetting[] = [];
  availlist: Mapping[] = [];
  organisation: string;
  mappinglist: Mapping[] = [];
  totaldata: number;
  availtotaldata: number;
  minFromNo: number;
  maxToNo: number;
  pagesUser: UserPage = new UserPage();
  pagesUserStock: UserPage = new UserPage();
  pagesUserProduct: UserPage = new UserPage();
  packsizelist: PackSize[] = [];
  denominationlist: Denomination[] = [];
  DenominationName: string = '';
  submitForm: NgForm;
  DenominationId: boolean = true;
  PointId: boolean;
  IsUserTypePainter: boolean;
  IsUserTypeContractor: boolean;
  Point: Point[] = [];
  fromno: number;
  tono: number;
  qty: number;
  fromOutOfRange: boolean = false;
  toOutOfRange: boolean = false;
  fromNoGreater: boolean = false;
  toNoSmaller: boolean = false;
  qtyZero: boolean = false;
  required: boolean = false;
  toNoZero: boolean = false;
  fromNoZero: boolean = false;
  isDisabled: boolean = false;
  isDisable: boolean = false;
  fromPrefixError: boolean = false;
  toPrefixError: boolean = false;
  invalidF: boolean = false;
  invalidT: boolean = false;
  flen: number;
  tlen: number;


  constructor(private toastr: ToastrService, private modalService: BsModalService, private apiService: ApiService) {
    this.pagesUser.Filter = "FromNumber";
  }
  ngOnInit() {
    this.pagesUserProduct.Organisation = parseInt(localStorage.getItem("organisation"));
    this.organisation = localStorage.getItem("organisation");
    this.getmappinglistapi();
    this.getOrganisation(this.pagesUserProduct);
    this.getPoints(this.pagesUserProduct);
    this.getPackSizeList();
    this.formval.OrganisationID = localStorage.getItem("organisation");
    this.pagesUserStock.Organisation = parseInt(this.formval.OrganisationID);
    this.getDenominationList();
    this.formval.HologramID = 0;
    this.isAvailableDisabled = true;
    // this.getAvailableStockApi(1);
    this.formval.PackSize = 0;
    this.mappinglist = this.mappinglist.slice();
    this.formvalforSave = this.formval;
    this.resetmodal();

  }
  onUserTypeSelect() {
    debugger;
    if (this.formval.UserType == 1) {
      this.isDisable = false;
      this.DenominationId = true;
      this.PointId = false;
      this.formval.PackSize = null;
      this.formval.ProductCode = null;
      this.formval.DenoRem = null;
      this.formval.FromNumber = null;
      this.formval.ToNumber = null;
      this.formval.Quantity = null;
      this.availlist = null;
      this.from = null;
      this.to = null;
      this.fromOutOfRange = false;
      this.toOutOfRange = false;
      this.fromNoGreater = false;
      this.toNoSmaller = false;
      this.qtyZero = false;
      this.required = false;
      this.toNoZero = false;
      this.fromNoZero = false;
      this.isDisabled = false;
      this.isDisable = false;
      this.fromPrefixError = false;
      this.toPrefixError = false;
      this.invalidF = false;
      this.invalidT = false;
    }
    else if (this.formval.UserType == 2) {
      this.isDisable = false;
      this.DenominationId = false;
      this.PointId = true;
      this.formval.PackSize = null;
      this.formval.ProductCode = null;
      this.formval.Point = null;
      this.formval.DenoRem = null;
      this.formval.FromNumber = null;
      this.formval.ToNumber = null;
      this.formval.Quantity = null;
      this.availlist = null;
      this.from = null;
      this.to = null;
      this.fromOutOfRange = false;
      this.toOutOfRange = false;
      this.fromNoGreater = false;
      this.toNoSmaller = false;
      this.qtyZero = false;
      this.required = false;
      this.toNoZero = false;
      this.fromNoZero = false;
      this.isDisabled = false;
      this.isDisable = false;
      this.fromPrefixError = false;
      this.toPrefixError = false;
      this.invalidF = false;
      this.invalidT = false;
      //this.getAvailableStockApi(value);
      //this.mappinglist=null;
    }
  }
  onChangepoints(value: any) {
    this.getAvailableStockApi(value);
  }
  onChangeDenomination(value: any) {
    debugger;
    this.getAvailableStockApi(value);
  }
  openModal(template: TemplateRef<any>) {
    debugger;
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
    //this.from.disable();
    this.from = null;
    this.to = null;
    this.modalRef = this.modalService.show(template, config);
    this.resetmodal();
  }
  openModal2(template: TemplateRef<any>) {
    debugger;
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
    this.modalRef2 = this.modalService.show(template, config);
  }
  removenumber() {
    if (this.formval.Quantity == '') {
      this.formval.ToNumber = '';
    }
  }
  removeQty() {
    this.formval.Quantity = '';
    this.formval.ToNumber = '';
  }
  calc2(val: number) {
    debugger;
    if (this.formval.ToNumber == '') {
      this.formval.Quantity = '';
    }
    else {
      for (var i = 0; i < this.denominationlist.length; i++) {
        if (this.formval.HologramID == this.denominationlist[i].ID) {
          this.formval.Prefix = this.denominationlist[i].Prefix;
        }
      }
      var len = this.formval.FromNumber.length;
      if (len == 15) {
        this.formval.FromNumber = this.formval.FromNumber.substring(6, len);
      }
      else if (len == 16) {
        this.formval.FromNumber = this.formval.FromNumber.substring(7, len);
      }
      //alert(this.formval.FromNumber)
      if (parseInt(this.formval.FromNumber) == 0 || this.formval.FromNumber == '' || this.formval.FromNumber == 'NaN' || this.formval.FromNumber == 'undefined') {
        this.formval.FromNumber = '';
        this.formval.ToNumber = '';
        this.formval.Quantity = '';
      }
      else if (parseInt(this.formval.Quantity) == 0) {
        this.formval.ToNumber = '';
        this.formval.Quantity = '';
        this.formval.FromNumber = this.formval.Prefix + this.formval.FromNumber;
      }
      else {
        var txtfromNo = parseInt(this.formval.FromNumber);
        var txtqtyNo = parseInt(this.formval.Quantity);
        var txttoNo = txtfromNo + txtqtyNo - 1;
        this.formval.ToNumber = txttoNo.toString();
        this.formval.FromNumber = this.formval.Prefix + this.mypadding(parseInt(this.formval.FromNumber), 9);
        this.formval.ToNumber = this.formval.Prefix + this.mypadding(parseInt(this.formval.ToNumber), 9);
      }
    }
  }
  onutypechange() {
    this.getmappinglistapi();
  }
  calc(val: number) {
    if (parseInt(this.formval.FromNumber) == 0 || this.formval.FromNumber == '') {
      this.formval.FromNumber = '';
      this.formval.ToNumber = '';
    }
    else if (parseInt(this.formval.Quantity) == 0 || this.formval.Quantity == '') {
      this.formval.ToNumber = '';
      this.formval.Quantity = '';
    }
    else {
      var txtfromNo = parseInt(this.formval.FromNumber);
      var txtqtyNo = parseInt(this.formval.Quantity);
      var txttoNo = txtfromNo + txtqtyNo - 1;
      this.formval.ToNumber = txttoNo.toString();
    }
  }
  openModal3(template: TemplateRef<any>) {
    debugger;
    let config = { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' };
    // this.modalHead="Add Coupon Stock";
    this.modalRef3 = this.modalService.show(template, config);
    if (this.formval.UserType == 1) {
      this.getOrganisation(this.pagesUserProduct);
      this.IsUserTypePainter = true
      this.IsUserTypeContractor = false

    }
    else if (this.formval.UserType == 2) {
      debugger;
      this.getPoints(this.pagesUserProduct);
      this.IsUserTypeContractor = true
      this.IsUserTypePainter = false
      this.getAvailableStockApi(this.formval.HologramID);
    }
  }
  getmappinglistapi() {
    debugger
    this.pagesUser.Organisation = parseInt(localStorage.getItem("organisation"));
    this.apiService.PostApi(this.pagesUser, "Mapping/AllMapping").subscribe((response: MappingResponse) => {
      this.totaldata = response.ResponseData.TotalCount;
      this.mappinglist = response.ResponseData.MappingData;
      if (this.mappinglist.length > 0) {
        for (var i = 0; i < this.mappinglist.length; i++) {
          this.mappinglist[i].FromNumber = this.mappinglist[i].Prefix + this.mypadding(parseInt(this.mappinglist[i].FromNumber), 9);
          this.mappinglist[i].ToNumber = this.mappinglist[i].Prefix + this.mypadding(parseInt(this.mappinglist[i].ToNumber), 9);
        }
      }
      //  (this.totaldata)
      this.totaldata = this.totaldata;
    })
  }
  onSubmit(f: NgForm) {
    debugger;
    debugger;
    if ((this.formval.FromNumber == null || this.formval.FromNumber == undefined || this.formval.FromNumber == '') ||
      (this.formval.ToNumber == null || this.formval.ToNumber == undefined || this.formval.ToNumber == '') ||
      (this.formval.Quantity == null || this.formval.Quantity == undefined || this.formval.Quantity == '') ||
      (this.formval.UserType == null || this.formval.UserType == 0) ||
      (this.formval.HologramID == null || this.formval.HologramID == 0) ||
      (this.formval.BatchNo == null || this.formval.BatchNo == '') ||
      (this.fromOutOfRange == true || this.toOutOfRange == true || this.fromNoGreater == true || this.toNoSmaller == true ||
        this.qtyZero == true || this.required == true || this.toNoZero == true || this.fromNoZero == true || this.toPrefixError == true ||
        this.fromPrefixError == true || this.invalidF == true || this.invalidT == true)
    ) {
      this.toastr.warning('Please fill all valid required details', 'Warning!', {
        timeOut: 3000

      });
      return
    }

    this.submitForm = f;
    this.show = true;
    this.formvalforSave = this.formval;
    // (this.formvalforSave)
    var flen = this.formvalforSave.FromNumber.length;
    var tlen = this.formvalforSave.ToNumber.length;
    if (flen == 15) {
      this.formvalforSave.FromNumber = this.formvalforSave.FromNumber.substring(6, flen);
      this.formvalforSave.ToNumber = this.formvalforSave.ToNumber.substring(6, tlen);
    }
    else if (flen == 16) {
      this.formvalforSave.FromNumber = this.formvalforSave.FromNumber.substring(7, flen);
      this.formvalforSave.ToNumber = this.formvalforSave.ToNumber.substring(7, tlen);
    }
    this.formvalforSave.CreatedBy = parseInt(localStorage.getItem("userID"));
    this.savemapping();
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  savemapping() {
    debugger;
    var textvaluepair = this.formvalforSave.HologramID.toString().split(",");
    var x = parseInt(textvaluepair[0]);
    if (this.formvalforSave.PackSize == 0 || x == 0) {
      this.show = false;
      return false;
    }
    else if (parseInt(this.formvalforSave.FromNumber) <= 0 || parseInt(this.formvalforSave.ToNumber) <= 0 || parseInt(this.formvalforSave.Quantity) <= 0) {
      this.toastr.warning("Negative Values are not allowed", "Warning!")
      this.show = false;
      return false
    }
    else if (parseInt(this.formvalforSave.FromNumber) < this.minFromNo || parseInt(this.formvalforSave.ToNumber) > this.maxToNo) {
      this.toastr.warning("Numbers out of Range", 'Warning!', {
        timeOut: 3000
      });

      this.show = false;
      return false
    }
    else {
      var tempHologramId = this.formvalforSave.HologramID;
      var textvaluepair = this.formvalforSave.HologramID.toString().split(",");
      this.formvalforSave.HologramID = parseInt(textvaluepair[0]);
      this.apiService.PostApi(this.formvalforSave, "Mapping/SaveMapping").subscribe((response: MappingResponse) => {
        debugger;
        if (response.ResponseCode == "200") {
          this.show = false;
          this.toastr.success("Mapped Successfully", "Success!", {
            timeOut: 3000
          })
          this.getmappinglistapi();
          this.submitForm.resetForm();
          this.resetmodal();
          this.availlist = this.availlist.slice(0, 0);
        }
        else {
          this.toastr.warning(response.ResponseMessage, "Warning!", {
            timeOut: 3000
          })
          this.formvalforSave.HologramID = tempHologramId;
          this.formval.FromNumber = this.formval.Prefix + this.formval.FromNumber;
          this.formval.ToNumber = this.formval.Prefix + this.formval.ToNumber;
          this.show = false;
        }
      }, err => {
        this.toastr.warning(err.error.ResponseMessage, "Warning!", {
          timeOut: 3000
        })
        this.formval.FromNumber = this.formval.Prefix + this.formval.FromNumber;
        this.formval.ToNumber = this.formval.Prefix + this.formval.ToNumber;
        this.formval.HologramID = tempHologramId;
        this.show = false;
      })
    }
  }
  resetmodal() {
    this.isAvailableDisabled = true;
    this.modalRef.hide();
    this.formval.HologramID = 0;
    this.maxToNo = 0;
    this.minFromNo = 0;
    this.DenominationId = true;
    this.PointId = false;
    this.formval.PackSize = 0;
    this.formval.UserType = 0;
    this.formval.ProductCode = '';
    this.formval.ProductID = 0;
    this.formval.FromNumber = "";
    this.formval.ToNumber = "";
    this.formval.Quantity = "";
    this.formval.Prefix = "";
    this.availlist = this.availlist.slice(0, 0);
    this.formvalforSave = this.formval;
    this.formval.UserType = 0;
    this.fromOutOfRange = false;
    this.toOutOfRange = false;
    this.fromNoGreater = false;
    this.toNoSmaller = false;
    this.qtyZero = false;
    this.required = false;
    this.toNoZero = false;
    this.fromNoZero = false;
    this.isDisabled = false;
    this.isDisable = false;
    this.fromPrefixError = false;
    this.toPrefixError = false;
    this.invalidF = false;
    this.invalidT = false;
  }
  closemodal2() {
    this.modalRef2.hide();
  }
  closemodal3() {
    this.modalRef3.hide();
  }
  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.pagesUser.page = event.page;
    this.getmappinglistapi();
  }
  pageChanged2(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.pagesUserStock.page = event.page;
    this.getAvailableStockApi(this.formval.HologramID);
  }
  pageChanged3(event: PageChangedEvent): void {
    //  alert(event.page)
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.pagesUserProduct.page = event.page;
    if (this.formval.UserType == 1) {
      debugger
      this.getOrganisation(this.pagesUserProduct);
    }
    else if (this.formval.UserType == 2) {
      this.getPoints(this.pagesUserProduct);
    }
  }
  getOrganisation(user: UserPage): any {
    this.show = true;
    this.pagesUser.UserType = 1;
    if (this.productlist != null) {
      this.productlist = this.productlist.slice(0, 0);
    }
    this.apiService.PostApi(this.pagesUserProduct, "Setting/GetAllSettings").subscribe((response: DenominationSettingResponse) => {
      if (response.ResponseCode == "200") {
        this.productlist = response.ResponseData;
        this.producttotaldata = response.TotalCount;
        if (this.totaldata < parseInt(this.pagesUser.pageSize)) {
          this.currentPage = 1;
        }
      }
      else {
        this.settingslist = null;
        this.show = false;
      }
    }, err => {
      this.settingslist = null;
      this.show = false;
    });
    this.show = false;
  }
  getPoints(user: UserPage): any {
    debugger
    debugger;
    this.show = true;
    this.pagesUser.UserType = 1;
    if (this.productlist != null) {
      this.productlist = this.productlist.slice(0, 0);
    }
    this.apiService.PostApi(this.pagesUserProduct, "Setting/GetPoints").subscribe((response: DenominationSettingResponse) => {
      debugger;
      if (response.ResponseCode == "200") {
        debugger
        this.productlist = response.ResponseData;
        this.producttotaldata = response.TotalCount;
        if (this.totaldata < parseInt(this.pagesUser.pageSize)) {
          this.currentPage = 1;
        }
      }
      else {
        this.settingslist = null;
        this.show = false;
      }
    }, err => {
      this.settingslist = null;
      this.show = false;
    });
    this.show = false;
  }
  getDenominationList() {
    this.apiService.GetApi(this.pagesUserStock, "Other/GetDenomiations").subscribe((response: DenominationResponse) => {
      if (response.ResponseCode == "200") {
        (response.ResponseData)
        this.denominationlist = response.ResponseData;
      }
    })
  }
  getPackSizeList() {
    debugger;
    this.apiService.PostApi(this.pagesUserStock, "Other/GetPackSize").subscribe((response: PackSizeResponse) => {
      if (response.ResponseCode == "200") {
        this.packsizelist = response.ResponseData;
      }
    })
  }
  ontypechange() {
    debugger
    this.pagesUser.page = 1
    this.getmappinglistapi();
  }
  ontypechangeProduct() {
    this.pagesUserProduct.page = 1;
    if (this.formval.UserType == 1) {
      this.getOrganisation(this.pagesUserProduct);
    }
    else if (this.formval.UserType == 2) {
      this.getPoints(this.pagesUserProduct);
    }
  }
  ontypechangeStock() {
    this.pagesUserStock.page = 1;
    this.getAvailableStockApi(this.formval.HologramID);
  }
  selectstock(formval: Mapping) {
    debugger;
    
    this.modalRef2.hide();
    this.formval.FromNumber = formval.FromNumber;
    this.formval.ToNumber = formval.ToNumber;
    this.formval.Quantity = formval.Quantity;
    this.formval.Prefix = formval.Prefix;
    this.flen = this.formval.FromNumber.length;
    this.tlen = this.formval.ToNumber.length;
    if (this.flen == 15) {
      // this.formval.FromNumber = this.formval.FromNumber.substring(7, flen);
      // this.formval.ToNumber = this.formval.ToNumber.substring(7, tlen);
      this.maxToNo = parseInt(this.formval.ToNumber.substring(6, this.tlen));
      this.minFromNo = parseInt(this.formval.FromNumber.substring(6, this.flen));
    }
    else if (this.flen == 16) {
      // this.formval.FromNumber = this.formval.FromNumber.substring(8, flen);
      // this.formval.ToNumber = this.formval.ToNumber.substring(8, tlen);
      this.maxToNo = parseInt(this.formval.ToNumber.substring(7, this.tlen));
      this.minFromNo = parseInt(this.formval.FromNumber.substring(7, this.flen));
    }

    this.from = this.formval.FromNumber;
    this.to = this.formval.ToNumber;
    this.isDisable = true;
  }
  selectproduct(formval: DenominationSetting) {

    (formval);
    this.modalRef3.hide();
    this.formval.ProductCode = formval.ProductCode;
    this.formval.ProductID = formval.ProductID;
    this.formval.PackSize = formval.PackSize;
    this.formval.HologramID = formval.Denomination;
    this.isAvailableDisabled = false;
    this.DenominationName = formval.DenoRem;
    this.onChangeDenomination(this.formval.HologramID);

  }
  selectproduct1(formval: DenominationSetting) {

    (formval);
    this.modalRef3.hide();
    this.formval.ProductCode = formval.ProductCode;
    this.formval.ProductID = formval.ProductID;
    this.formval.PackSize = formval.PackSize;
    this.formval.Point = formval.Point;
    this.formval.FromDate = formval.FromDate;
    this.formval.ToDate = formval.ToDate;
    this.isAvailableDisabled = false;
    this.DenominationName = formval.DenoRem;
    this.onChangepoints(this.formval.HologramID = 8);
  }
  mypadding(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  getAvailableStockApi(value: number) {
    debugger;
    this.pagesUserStock.UserType = parseInt(localStorage.getItem("usertype"))
    this.pagesUserStock.HologramID = this.formval.HologramID;
    if (this.pagesUserStock.UserType == 0) {
      this.pagesUserStock.IsReceived = "0"
      this.pagesUserStock.IsReceived = "0"
    }
    else {
      this.pagesUserStock.IsReceived = "1";
      this.pagesUserStock.IsTransferred = "1";
    }
    this.apiService.PostApi(this.pagesUserStock, "Mapping/AvailableHologram").subscribe((response: any) => {
      debugger;
      if (response.ResponseCode == "200") {
        debugger

        this.availlist = response.ResponseData.AvailData;
        if (this.availlist.length > 0) {
          ;
          this.availtotaldata = response.ResponseData.TotalCount;
          for (var i = 0; i < this.availlist.length; i++) {

            this.availlist[i].FromNumber = this.availlist[i].Prefix + this.mypadding(parseInt(this.availlist[i].FromNumber), 9);
            this.availlist[i].ToNumber = this.availlist[i].Prefix + this.mypadding(parseInt(this.availlist[i].ToNumber), 9);
          }
        }
      }
      else{
        this.availlist=[];
      }
    })
  }
  checkValue(num: number) {
    debugger
    this.fromOutOfRange = false;
    this.toOutOfRange = false;
    this.fromNoGreater = false;
    this.toNoSmaller = false;
    this.qtyZero = false;
    this.required = false;
    this.toNoZero = false;
    this.fromNoZero = false;
    this.toPrefixError = false;
    this.fromPrefixError = false;
    this.invalidF = false;
    this.invalidT = false;
    console.log(this.formval)
    var flength = this.formval.FromNumber.length;
    var tlength = this.formval.ToNumber.length;
    var len = this.formval.Prefix.length;
    if (len == 6) {
      this.fromno = Number(this.formval.FromNumber.substring(len,));
      this.tono = Number(this.formval.ToNumber.substring(len));
      const fromPrefix = this.formval.FromNumber.substring(0, len);
      const toPrefix = this.formval.ToNumber.substring(0, len);
      if (fromPrefix != this.formval.Prefix) {
        this.fromPrefixError = true;
        this.toPrefixError = false;
        this.formval.Quantity = '';
      }
      else if (toPrefix != this.formval.Prefix) {
        this.toPrefixError = true;
        this.fromPrefixError = false;
      }
      else {
        this.toPrefixError = false;
        this.fromPrefixError = false;
      }

    }
    else if (len == 7) {
      this.fromno = Number(this.formval.FromNumber.substring(len,));
      this.tono = Number(this.formval.ToNumber.substring(len,));
      const fromPrefix = this.formval.FromNumber.substring(0, len);
      const toPrefix = this.formval.ToNumber.substring(0, len);
      if (fromPrefix != this.formval.Prefix) {
        this.fromPrefixError = true;
        this.toPrefixError = false;
        this.formval.Quantity = '';
      }
      else if (toPrefix != this.formval.Prefix) {
        this.toPrefixError = true;
        this.fromPrefixError = false;
      }
      else {
        this.toPrefixError = false;
        this.fromPrefixError = false;
      }

    }
    this.qty = this.tono - this.fromno + 1;
    if (this.qty > 0 && this.fromno != 0 && this.tono != 0) {
      this.formval.Quantity = this.qty.toString()
    }
    else {
      this.formval.Quantity = '';
    }
    if (flength == this.flen && tlength == this.tlen) {

      if (this.fromno > 0 && this.tono > 0 && this.tono > this.fromno) {
        if (this.fromno < this.minFromNo || this.fromno > this.maxToNo ||
          this.tono > this.maxToNo || this.tono < this.minFromNo) {
          if (this.fromno < this.minFromNo || this.fromno > this.maxToNo) {
            this.fromOutOfRange = true;
          }
          if (this.tono < this.minFromNo || this.tono > this.maxToNo) {
            this.toOutOfRange = true;
          }
          if (flength != this.flen) {
            this.invalidF = true;
            this.invalidT = false;

          }
          else if (tlength != this.tlen) {
            this.invalidT = true;
          }
        }
        else {
          if (this.tono == this.fromno) {
            if (num == 2) {
              this.fromOutOfRange = false;
              this.toOutOfRange = false;
              this.qtyZero = false;
              if (tlength != this.tlen) {
                this.invalidT = true;
              }
              //this.stockForm.controls['fromNumber'].setErrors(null);
            }
            else {
              this.fromOutOfRange = false;
              this.toOutOfRange = false;
              this.qtyZero = false;
              if (flength != this.flen) {
                this.invalidF = true;
                this.invalidT = false;

              }
            }
          }
        }
      }
      else {
        if (!this.fromno || this.fromno == 0) {
          this.fromNoZero = true;
          this.qtyZero = true;
          if (flength != this.flen) {
            this.invalidF = true;
            this.invalidT = false;

          }

        }
        if (!this.tono || this.tono == 0) {
          this.toNoZero = true;
          //this.required = true;
          this.qtyZero = true;
          this.qtyZero = false;
          if (tlength != this.tlen) {
            this.invalidT = true;
          }
        }
        if (this.tono < this.fromno) {
          if (num == 1) {
            this.fromNoGreater = true;
            this.toNoSmaller = false;
          }
          else {
            this.toNoSmaller = true;
            this.fromNoGreater = false;
          }
        }
        if (this.tono == this.maxToNo || this.fromno == this.minFromNo || this.fromno == this.tono) {
          if (num = 1) {
            if (flength != this.flen) {
              this.invalidF = true;
              this.invalidT = false;

            }
            if (tlength != this.tlen) {
              this.invalidT = true;
            }
          }
        }
      }
    }
    else {
      if (num = 1) {
        if (flength != this.flen) {
          this.invalidF = true;
          this.invalidT = false;

        }
        if (tlength != this.tlen) {
          this.invalidT = true;
        }
      }
    }

  }
}
