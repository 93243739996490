import { Component,  TemplateRef } from '@angular/core';
import { UserPage } from '../models/login';
import { ApiService } from '../Services/api-service';
import { UserMasterResponse, User } from '../models/user';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/ngx-bootstrap-pagination';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { OrganisationResponse, Organisation } from '../models/organisation';

@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.css']
})
export class OrganisationComponent  {

  pagesUser:UserPage=new UserPage();
  organisationslist:Organisation[]=[];
  formval:Organisation=new Organisation();
  show:boolean=false;
  isOrganisationDisabled:boolean = false
  modalhead:string="Add Organisation";
  dnm:boolean=false;
  showOrganisationList :any[] = []
  totaldata:number;
  isOrganisationEmpty: boolean = false;
  numberedit:string="";
  template:TemplateRef<OrganisationComponent>;
  modalRef: BsModalRef;
  mobileReg:RegExp=/^[6-9]\d{9}$/;
  emailReg:RegExp=/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  isEdit:boolean=true;
  currentPage:number;
  buttonText:string="Save";
  submitForm: NgForm;

  constructor(private toastr: ToastrService,private apiService:ApiService,private modalService: BsModalService) {
    this.pagesUser.Filter='FirstName';
  }

  ngOnInit() {
    this.getOrganisation(this.pagesUser);
    this.formval.Active="true";
    this.formval.OrganisationType=0
  }

  onSubmit(f:NgForm)
  {
    if(this.formval.OrganisationType == 0){
      this.isOrganisationEmpty = true
      return
    }
    this.submitForm = f;
    this.show=true;
    this.formval.LastUpdatedBy=localStorage.getItem("userID");

    if(this.buttonText=="Save")
    {
      this.saveUser(this.formval,"Organisation/SaveOrganisation");
    }
    else
    {
      debugger;
      this.saveUser(this.formval,"Organisation/EditOrganisation");
    }
  }

  saveUser(formval:Organisation,url:string)
  {
    this.apiService.PostApi(formval,url).subscribe((response: OrganisationResponse) => {
      if (response.ResponseCode == "200") {
        this.submitForm.resetForm();
        this.getOrganisation(this.pagesUser);
        this.resetmodal();
        this.show=false;

        this.toastr.success(response.ResponseMessage,'Success!',{
          timeOut:3000
         });
      }
      else
      {
        this.toastr.warning(response.ResponseMessage,'Warning!',{
          timeOut:3000

         });

    this.show=false;
      }
    }, err => {
      this.toastr.warning( err.error.ResponseMessage,'Warning!',{
        timeOut:3000

       });

    this.show=false;
    });


  }

  getOrganisation(user:UserPage): any {

    this.show=true;

    this.pagesUser.UserType=1;
    if(this.organisationslist!=null)
    {

      this.organisationslist=this.organisationslist.slice(0,0);
    }
    this.apiService.PostApi(this.pagesUser,"Organisation/GetAllOrganisation").subscribe((response: OrganisationResponse) => {
      if (response.ResponseCode == "200") {
        this.organisationslist = response.ResponseData;
         this.totaldata=response.TotalCount;
         if(this.totaldata<parseInt(this.pagesUser.pageSize))
         {
           this.currentPage=1;
         }
      }
      else {
    this.organisationslist=null;
    this.show=false;
      }
    }, err => {

    this.organisationslist=null;

    this.show=false;
    });

    this.show=false;

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;




    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }





  alphaOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;




    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;

  }


  openModal(template: TemplateRef<any>) {
    let config = { backdrop: true, ignoreBackdropClick: true,class: 'modal-lg' };
    this.isOrganisationDisabled = false
    this.modalRef = this.modalService.show(template,config);

  }


  resetmodal()
  {
    this.modalRef.hide();
    this.formval.OrganisationName='';
    this.formval.Address='';
    this.formval.State='';
    this.formval.OrganisationType = 0
    this.formval.City='';
    this.buttonText="Save";
    this.formval.Pincode='';
    this.formval.Country='';
    this.modalhead="Add Organisation";

    this.modalRef.hide();
  }
  pageChanged(event: PageChangedEvent): void {
    //  alert(event.page)
      const startItem = (event.page - 1) * event.itemsPerPage;
      const endItem = event.page * event.itemsPerPage;
      this.pagesUser.page=event.page;
      this.getOrganisation(this.pagesUser);
     //  (this.users)
     this.currentPage=event.page+1;
    }

    onpagechange()
    {
      this.pagesUser.page=1;
      this.getOrganisation(this.pagesUser);
    }


    editorganisation(template: TemplateRef<any>,user:Organisation)
    {
      debugger;
      this.isEdit=false;
      //alert(this.user.firstName);
      this.modalhead="Edit Organisation"

      this.numberedit="readonly";

      this.formval={...user};

      this.buttonText="Update";

      let config = { backdrop: true, ignoreBackdropClick: true,class: 'modal-lg' };


      this.modalRef = this.modalService.show(template, config);
      this.isOrganisationDisabled = true
    }

}
